import { Button } from "@mui/material";
import React from "react";
import appStyle from "../../AppMeta/appStyle";

/**
 * @author : Janarthanan
 * @Date : 6/11/24
 * @description Reusable button component.
 * @param { size, variant, btnName, handleOnClick, btnStyle }
 * @return The rendered button component.
 */
function CustomButton({
  endIcon,
  startIcon,
  size,
  variant,
  btnName,
  handleOnClick,
  handleClick,
  disabled = false,
  color,
  label,
}) {
  return (
    <Button
      size={size}
      variant={variant}
      onClick={handleOnClick ? handleOnClick : handleClick}
      disabled={disabled}
      sx={
        color === "error"
          ? appStyle.general.button.redButton
          : appStyle.general.button.blueButton
      }
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {btnName ? btnName : label}
    </Button>
  );
}

export default CustomButton;
