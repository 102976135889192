import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import customConsole from "../../config/customConsole";
const BASE_URL = process.env.REACT_APP_BASE_URL;

function ApiDocumentsMain() {
  console.log("BASE_URL", BASE_URL);
  const jwtToken =
    localStorage.getItem("token") && localStorage.getItem("token").length
      ? localStorage.getItem("token")
      : sessionStorage.getItem("token") &&
        sessionStorage.getItem("token").length
      ? sessionStorage.getItem("token")
      : "";
  //Variable
  const microServices = [
    {
      name: "POPM",
      description: "User Configuration Management",
      url: `${BASE_URL}/POPM/docs/json`,
    },
    {
      name: "PSCM",
      description: "Project Configuration Management",
      url: `${BASE_URL}/PSCM/docs/json`,
    },
    {
      name: "PQSM",
      description: "Approval Configuration Management",
      url: `${BASE_URL}/PQSM/docs/json`,
    },
  ];
  const [tabValue, setTabValue] = useState("POPM");
  const [selectedMiscroservice, setSelectedMiscroservice] = useState(
    microServices[0]
  );
  //Function to update on change of the tabvalue
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    customConsole(microServices.filter((data) => data.name === newValue));
    const data = microServices.filter((data) => data.name === newValue);
    if (data && data.length) {
      setSelectedMiscroservice(data[0]);
    }
  };

  return (
    <Box sx={styles.mainBox}>
      <Box sx={styles.tabHaeder}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor="secondary"
          elevation={12}
        >
          {microServices.map((data, index) => {
            return (
              <Tab
                key={index}
                value={data.name}
                label={data.name}
                sx={styles.tabStyle}
              />
            );
          })}
        </Tabs>
      </Box>
      {/* Swagger Data to be shown here */}
      <Box sx={styles.swaggerData}>
        {jwtToken &&
        jwtToken.length &&
        selectedMiscroservice.url &&
        selectedMiscroservice.url.length ? (
          <SwaggerUI
            url={selectedMiscroservice.url}
            requestInterceptor={(req) => {
              req.headers.Authorization = `Bearer ${jwtToken}`;
              return req;
            }}
          />
        ) : (
          <Typography sx={styles.dataNotFound}>Data not found!</Typography>
        )}
      </Box>
    </Box>
  );
}

export default ApiDocumentsMain;
const styles = {
  dataNotFound: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "red",
    marginTop: "20px",
  },
  tabStyle: {
    color: "black",
    fontWeight: "bold",
  },
  tabHaeder: {
    display: "flex",
    justifyContent: "center",
  },

  swaggerData: {
    minHeight: "20vH",
    minWidth: "20vW",
    margin: "10px",
    border: "1px solid black",
    borderRadius: "10px",
    paddingBottom: "10px",
    height: "80vH",
    overflow: "auto",
  },
  mainBox: {
    width: "100%",
    m: "30px",
    // display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
};
