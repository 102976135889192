// import { toast } from "react-toastify";
import customConsole from "../../config/customConsole";
import useAxios from "../../Hooks/useAxios";
// const baseURL = process.env.REACT_APP_BASE_URL;
/**

/**
 * @author Janarthanan
 * @date 5/11/24
 * @description Handle different type of actions with the useAxios custom Hook
 * @param {api, https_method, params, show_toast, action_type, is_file}
 * @returns {dispatch} dipatch the action to the reducer
 */
export function HandleApiActions(props) {
  const api = useAxios();
  customConsole("-- HandleApiActions --");
  customConsole(props);
  let resp = { error: true, msg: "", data: { error: true, msg: "", data: {} } };
  return async (dispatch) => {
    try {
      //   let apiActionPerformed = true;
      //Based on type of API perform the action
      switch (props.https_method) {
        case "GET":
          if (props.is_file) {
            resp = await api.get(props.api_name, {
              params: { filename: props.file_name },
              responseType: "blob", // Important for handling binary data
            });
          } else {
            resp = await api.get(props.api_name, {
              params: props.params,
            });
          }

          break;
        case "PUT":
          resp = await api.put(props.api_name, {
            ...props.params,
          });
          break;
        case "POST":
          if (props.is_file) {
            let file = new FormData();
            file.append("name", "filename");
            file.append("filename", props.file);
            resp = await api.post(props.api_name, file);
          } else {
            resp = await api.post(props.api_name, {
              ...props.params,
            });
            console.log("resp", resp);
          }
          break;
        default:
          //   apiActionPerformed = false;
          break;
      }
      if (props.action_type) {
        if (props.is_file && props.https_method === "GET") {
          // Create a URL for the image blob data
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          if (props.action_type) {
            dispatch({
              type: props.action_type,
              payload: { error: false, msg: "File downloaded", file_path: url },
            });
          } else {
            return resp.data;
          }
        } else {
          if (props.action_type) {
            dispatch({
              type: props.action_type,
              payload: resp.data,
            });
          } else {
            return resp.data;
          }
        }
      }
      //   if (apiActionPerformed) {
      //     if (props.show_toast && resp.data.msg) {
      //       showToastMessage({
      //         type: resp.data.error ? "error" : "success",
      //         message: resp.data.msg,
      //       });
      //     }
      //   }
      // if (!props.action_type) {
      return resp.data;
      // }
    } catch (error) {
      customConsole("-- API catch block --");
      customConsole(error);
      //   if (
      //     props.show_toast &&
      //     error.response &&
      //     error.response.data &&
      //     error.response.data.msg
      //   ) {
      //     showToastMessage({ type: "error", message: error.response.data.msg });
      //   }
      return resp.data;
    }
    // return resp.data;
  };
}
