import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Box,
} from "@mui/material";
import { appColors } from "../../AppMeta/appColors";
import { useDispatch } from "react-redux";
import {
  CheckProcessingPartnerPO,
  PlaceOrderProcessingPartner,
} from "../../Redux/Actions/purchaseProductsAction";

const data = {
  mft_id: 30,
  mft_available: true,
  processing_fee: 20,
  quantity_outcome: 70,
  mft_name: "Bookwater",
  mft_details: {
    name: "Bookwater",
    address: {
      door_number: "405",
      building_name: "Raheja towers",
      area_name: "Anna salai",
      pincode: "600018",
      state: "Tamil nadu",
      city: "Chennai",
      district: "Chennai",
    },
    tax_details: {
      GSTIN: "",
      PAN: "",
    },
    user_details: {
      owner_name: "Bookwater",
      owner_phone: "9988912731",
    },
    files: {
      GST: "",
    },
  },
  price_details: {
    total_price: 3850,
    total_gst: 308,
    total_processing_fee: 1400,
    final_price: 5558,
  },
  product_details: [
    {
      product_id: "38",
      type: "GLASS BOTTLE PREMIUM",
      name: "5 LITER GLASS BOTTLE",
      price: "55.00",
      life_span: "0",
      details: {
        tax_details: {
          gst: {
            CGST: 4,
            SGST: 4,
          },
        },
        material: "GLASS",
      },
      is_available: true,
      updated_by: "Lkgx_lbEM",
      created_at: "2024-01-18T10:50:13.000Z",
      updated_at: "2024-01-18T10:50:13.000Z",
      is_deleted: false,
      components: [
        {
          id: "5",
          name: "Can",
          quantity: 1,
        },
        {
          id: "7",
          name: "Cap",
          quantity: 1,
        },
        {
          id: "8",
          name: "Sticker",
          quantity: 2,
        },
      ],
      id: "38",
    },
  ],
  rawMaterials: [
    {
      id: 5,
      quantity: 1,
      stock_details: [
        {
          user_id: "Lkgx_lbEM",
          product_id: "5",
          product_type: "100",
          available_quantity: "135",
          created_at: "2024-01-23T09:49:03.002Z",
          updated_at: "2024-01-29T14:30:19.000Z",
        },
      ],
      raw_materials: [
        {
          id: 5,
          name: "STICKER MATERIAL",
          details: {
            description: "Material for printing stickers",
            units: "Sheets",
            tax_details: {
              gst: {
                CGST: 9,
                SGST: 9,
              },
            },
          },
          units: "SHEETS",
          is_available: true,
          is_deleted: false,
          created_at: "2024-01-08T10:01:37.000Z",
          created_by: "Lkgx_lbEM",
          updated_at: "2024-01-08T10:01:37.000Z",
          updated_by: "Lkgx_lbEM",
        },
      ],
    },
    {
      id: 6,
      quantity: 4,
      stock_details: [
        {
          user_id: "Lkgx_lbEM",
          product_id: "6",
          product_type: "100",
          available_quantity: "10",
          created_at: "2024-01-23T09:49:03.002Z",
          updated_at: "2024-01-29T14:30:19.000Z",
        },
      ],
      raw_materials: [
        {
          id: 6,
          name: "INK FOR PRINTING",
          details: {
            description: "Ink for printing the labelss",
            units: "LITERS",
            tax_details: {
              gst: {
                CGST: 8,
                SGST: 8,
              },
            },
          },
          units: "LITERS",
          is_available: true,
          is_deleted: false,
          created_at: "2024-01-08T10:03:27.000Z",
          created_by: "Lkgx_lbEM",
          updated_at: "2024-01-09T08:23:25.000Z",
          updated_by: "Lkgx_lbEM",
        },
      ],
    },
  ],
};

const ProcessingPartnerOrderView = ({
  selectedData,
  processingPartnerOrderValidation,
}) => {
  const dispatch = useDispatch();

  const handleGenerateDraftPo = () => {
    const reqObj = {
      order_type: 400,
      mft_id: data.mft_id,
      order: {
        final_product:
          processingPartnerOrderValidation.product_details[0].product_id,
        quantity_outcome: processingPartnerOrderValidation.quantity_outcome,
        processing_fee: processingPartnerOrderValidation.processing_fee,
        raw_materials: selectedData,
      },
    };
    console.log(reqObj);
    const req = {
      order_type: 400,
      mft_id: 30,
      order: {
        final_product: 38,
        quantity_outcome: 70,
        processing_fee: 20,
        raw_materials: [
          {
            id: 5,
            quantity: 100,
          },
          {
            id: 6,
            quantity: 3,
          },
        ],
      },
    };
    dispatch(CheckProcessingPartnerPO(reqObj));
  };

  const handlePlaceOrder = () => {
    const reqObj = {
      order_type: 400,
      mft_id: data.mft_id,
      order: {
        final_product:
          processingPartnerOrderValidation.product_details[0].product_id,
        quantity_outcome: processingPartnerOrderValidation.quantity_outcome,
        processing_fee: processingPartnerOrderValidation.processing_fee,
        raw_materials: selectedData,
      },
    };
    console.log(reqObj);
    dispatch(PlaceOrderProcessingPartner(reqObj));
  };
  return (
    <Paper sx={{ margin: 2, padding: 2 }}>
      <Typography variant="h6" gutterBottom component="div">
        Manufacturer: {data.mft_name}
      </Typography>

      <Typography variant="h6" gutterBottom component="div">
        Product Details
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: appColors.secondary }}>
            <TableRow>
              <TableCell>Product Name</TableCell>
              <TableCell align="center">Product Price</TableCell>
              <TableCell align="right">Required Outcome Quantity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {processingPartnerOrderValidation.product_details.map((product) => (
              <TableRow
                key={product.product_id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {product.name}
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  {product.price}
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  {data.quantity_outcome}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Raw Materials Section */}
      <Typography
        variant="h6"
        gutterBottom
        component="div"
        sx={{ padding: "10px" }}
      >
        Raw Materials
      </Typography>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: appColors.secondary }}>
            <TableRow>
              <TableCell>Raw Material Name</TableCell>
              <TableCell align="center">Ordered Quantity</TableCell>
              <TableCell align="right">Available Quantity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {processingPartnerOrderValidation.rawMaterials.map(
              (rawMaterial) => (
                <TableRow
                  key={rawMaterial.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {rawMaterial.raw_materials[0].name}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {rawMaterial.quantity}
                  </TableCell>
                  <TableCell align="right">
                    {rawMaterial.stock_details[0].available_quantity}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Price Details Section */}
      <Typography
        variant="h6"
        gutterBottom
        component="div"
        sx={{ padding: "10px" }}
      >
        Price Details
      </Typography>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650, backgroundColor: appColors.secondary }}
          aria-label="simple table"
        >
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Processing Fee
              </TableCell>
              <TableCell align="right">
                {processingPartnerOrderValidation.processing_fee}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Total Processing Fee
              </TableCell>
              <TableCell align="right">
                {
                  processingPartnerOrderValidation.price_details
                    .total_processing_fee
                }
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Total Price
              </TableCell>
              <TableCell align="right">
                {processingPartnerOrderValidation.price_details.total_price}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Total CGST + SGST{" "}
                {`(${
                  processingPartnerOrderValidation.product_details[0].details
                    .tax_details.gst.CGST * 2
                } %)`}
              </TableCell>
              <TableCell align="right">
                {processingPartnerOrderValidation.price_details.total_gst}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Final Price
              </TableCell>
              <TableCell align="right">
                {processingPartnerOrderValidation.price_details.final_price}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
        }}
      >
        <Button
          variant="contained"
          size="small"
          onClick={handleGenerateDraftPo}
        >
          Generate Draft PO
        </Button>
        <Button variant="contained" size="small" onClick={handlePlaceOrder}>
          Place Order
        </Button>
      </Box>
    </Paper>
  );
};

export default ProcessingPartnerOrderView;
