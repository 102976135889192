/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Add users main component to show user details and edit details.
 * @date 26/07/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  Box,
  Dialog,
  Menu,
  Chip,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";

import { useDispatch, useSelector } from "react-redux";
import {
  AddUserRolesDomain,
  GetUserRolesDomainDetails,
} from "../../../Redux/Actions/addUsersDomainRolesAction";
import UserDetailsTable from "./UserDetailsTable";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { GetOrganizationList } from "../../../Redux/Actions/organizationAction";
import appStyle from "../../../AppMeta/appStyle";
import PersonAddRoundedIcon from "@mui/icons-material/PersonAddRounded";
import CustomTable from "../../../Components/CustomComp/CustomTable";
import EditUserDetailsDialog from "./EditUserDetailsDialog";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    // flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "30px",
    gap: "10px",
    // gap: theme.spacing(2),
    maxWidth: "400px",
    margin: "0 auto",
  },
  // button: {
  //   alignSelf: "flex-end",
  // },
}));

const AddUsers = ({ drawOpenProp, setDrawOpenProp }) => {
  const dispatch = useDispatch();
  const rolesData = useSelector((state) => state.rolesData);

  const addUserRolesDomainDetails = useSelector(
    (state) => state.addUserRolesDomainDetails
  );
  const organisationDetails = useSelector((state) => state.organizationData);
  console.log("organisationDetails", organisationDetails);
  const classes = useStyles();
  const [formValues, setFormValues] = useState({
    roleName: "",
    organizationName: "",
    fullName: "",
    email: "",
    phone: "",
  });

  // Required variabled declared here
  const [addUserDialog, setAddUserDialog] = React.useState(drawOpenProp);
  const [userDetailsList, setUserDetailsList] = React.useState([]);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(25);
  const [ascOrDesc, setAscOrDesc] = React.useState("ASC");
  const [isActive, setIsActive] = React.useState(true);
  const [lastPageNumber, setLastPageNumber] = React.useState(null);
  const [password, setPassword] = React.useState("");
  const [allFacility, setAllFacility] = React.useState([]);
  const [organizationName, setOrganizationName] = React.useState({});
  const [organisationName, setOrganisationName] = React.useState("");
  const [organisationId, setOrganisationId] = React.useState("");

  // For checking the validation for textfield variables
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [invalidPhone, setInvalidPhone] = React.useState(false);

  const [rolesList, setRolesList] = React.useState([]);
  const [lastMore, setLastMore] = React.useState(null);
  const [selectedRole, setSelectedRole] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hasMoreData, setHasMoreData] = React.useState(true);
  const [getMoreRoles, setGetMoreRoles] = React.useState(1);
  const [showPassword, setShowPassword] = React.useState(false);

  //states for edit Dialoug
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    console.log(rolesData);
    if (rolesData.error === false) {
      if (rolesData?.rolesList?.data?.length > 0) {
        console.log(rolesData.rolesList.data);
        // setRolesList(rolesData.rolesList.data);
        setLastMore(rolesData.rolesList.last_page_number);
        setRolesList((prevRolesList) =>
          prevRolesList
            ? [...prevRolesList, ...rolesData.rolesList.data]
            : rolesData.rolesList.data
        );
      }
    }
  }, [rolesData]);

  // Hitting the GET Roles API to show all the role for the user to select
  React.useEffect(() => {
    dispatch(
      GetUserRolesDomainDetails(pageNumber, pageSize, ascOrDesc, isActive)
    );
  }, [pageNumber, pageSize, ascOrDesc, isActive]);

  // To access the roles reducer store data
  React.useEffect(() => {
    console.log("addUserRolesDomainDetails", addUserRolesDomainDetails);
    if (addUserRolesDomainDetails.error === false) {
      setLastPageNumber(addUserRolesDomainDetails.userDetails.last_page_number);
      if (addUserRolesDomainDetails?.userDetails?.data?.length > 0) {
        setUserDetailsList(addUserRolesDomainDetails.userDetails.data);
      }
    }
  }, [addUserRolesDomainDetails]);

  React.useEffect(() => {
    console.log("Orgs Details");
    console.log(organisationDetails);
    setAllFacility(organisationDetails.organizationList);
  }, [organisationDetails]);

  React.useEffect(() => {
    dispatch(GetOrganizationList());
  }, []);

  React.useEffect(() => {
    setAllFacility(organisationDetails.organizationList);
  }, [organisationDetails]);

  React.useEffect(() => {
    console.log("allFacility");
    console.log(allFacility);
    allFacility.map((data) => {
      setOrganisationName(data.org_name);
      setOrganisationId(data.id);
    });
  }, [organisationName, organisationId]);

  React.useEffect(() => {
    console.log("----Drawer opened----");
    console.log(drawOpenProp);
    setAddUserDialog(drawOpenProp);
  }, [drawOpenProp]);

  // Checking the validation for email and phone number
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPhoneNumber = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  // Common handle change function for textfields for adding users
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name);
    // Email validation check
    if (name === "email") {
      if (!isValidEmail(value)) {
        console.log("Invalid email");
        setInvalidEmail(true);
      } else {
        setInvalidEmail(false);
      }
    }
    // Phone number validation check
    if (name === "phone") {
      if (!isValidPhoneNumber(value)) {
        console.log("Invalid phone Number");
        setInvalidPhone(true);
      } else {
        setInvalidPhone(false);
      }
    }
    // Setting the formvalues
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // This function will hit the Add User API with Request object required
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formValues);
    const reqObj = {
      role_name: selectedRole,
      // org_name: formValues.organizationName,
      org_name: organizationName?.org_name,
      org_id: parseInt(organizationName?.id),
      fullname: formValues.fullName,
      email: formValues.email,
      phone: formValues.phone,
      password: password,
      // organisation_id: organisationId,
      // organisation_name: facilityName,
    };
    const req2 = {
      role_name: "BW-SUPERADMIN",
      org_name: "SONY MUSIC",
      email: "abdul@sony.com",
      phone: "8943247239",
      fullname: "abdul123",
      password: "Sony@123456",
      org_id: 30,
    };
    const reqObj1 = {
      role_name: "BW-SUPERADMIN",
      org_name: "DLF",
      email: "abdul@dlf.com",
      phone: "8943247237",
      fullname: "abdul",
      password: "Dlf@123456",
    };
    dispatch(AddUserRolesDomain(reqObj));
    setAddUserDialog(false);
    setTimeout(() => {
      dispatch(GetUserRolesDomainDetails());
    }, 1000);
  };

  const handleOpenDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };
  const handleLoadMore = () => {
    setGetMoreRoles(getMoreRoles + 1);
    // fetchMoreData(nextPage);
  };

  const handleRowClick = (index) => {
    setOpenEditDialog(true);
    setSelectedRow(addUserRolesDomainDetails.userDetails[index]);
    // setDrawOpenProp(true);
    // console.log(index);
  };

  const handleButtonFun = () => {
    setAddUserDialog(true);
  };

  const handleAddUserFilterClose = (filterData) => {
    console.log(filterData);
  };

  const handleAddUserSortClose = (sortData) => {
    console.log(sortData);
  };

  //  Jsx starts here..
  return (
    <Box
    // sx={{
    //   display: "flex",
    //   flexDirection: "column",
    //   alignItems: "center",
    //   justifyContent: "center",
    //   gap: "20px",
    // }}
    >
      {/* <Box sx={appStyle.general.button.addButtonBox}>
        <Button
          variant="contained"
          color="secondary"
          label="Add new user"
          sx={appStyle.general.button.addButton}
          onClick={() => setAddUserDialog(true)}
          endIcon={<PersonAddRoundedIcon />}
        >
          Add new user
        </Button>
      </Box> */}
      <Dialog
        open={addUserDialog}
        // onClose={() => {
        //   setAddUserDialog(false);
        //   setDrawOpenProp(false);
        // }}
        sx={{ borderRadius: "30px" }}
      >
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyItems: "center",
            gap: "10px",
            padding: "40px",
          }}
          onSubmit={handleSubmit}
        >
          <Box
            sx={{
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <Box sx={{ minWidth: 120 }}>
              <Button
                onClick={handleOpenDropdown}
                variant="outlined"
                sx={{ width: "11rem" }}
              >
                Select Role Name
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseDropdown}
              >
                {rolesList?.map((list) => (
                  <MenuItem
                    key={list.role_name}
                    onClick={() => {
                      handleCloseDropdown();
                      setSelectedRole(list.role_name);
                    }}
                  >
                    {list.role_name}
                  </MenuItem>
                ))}
                {hasMoreData && (
                  <MenuItem
                    onClick={handleLoadMore}
                    sx={{ fontWeight: "bold" }}
                    disabled={getMoreRoles < lastMore ? false : true}
                  >
                    More <ExpandMoreRoundedIcon />
                  </MenuItem>
                )}
              </Menu>
            </Box>
            {selectedRole !== "" && (
              <Box>
                <Chip
                  label={`Selected Role Name: ${selectedRole}`}
                  variant="filled"
                  color="success"
                  sx={{ fontWeight: "bold" }}
                />
              </Box>
            )}
          </Box>
          <FormControl fullWidth size="small" sx={{ width: "11rem" }}>
            <InputLabel id="demo-simple-select-label">
              Organisation Name
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Facility Name"
              value={organizationName}
              onChange={(e) => setOrganizationName(e.target.value)}
            >
              {organisationDetails?.organizationList?.map((data) => {
                return <MenuItem value={data}>{data.org_name}</MenuItem>;
              })}
            </Select>
          </FormControl>

          <TextField
            label="Full Name"
            name="fullName"
            size="small"
            value={formValues.fullName}
            onChange={handleChange}
            required
          />
          <TextField
            label="Email"
            name="email"
            type="email"
            size="small"
            helperText={invalidEmail === true ? "Enter Valid Email" : ""}
            error={invalidEmail}
            value={formValues.email}
            onChange={handleChange}
            required
          />

          <TextField
            label="Phone"
            name="phone"
            size="small"
            helperText={invalidPhone ? "Enter Valid Phone Number" : ""}
            error={invalidPhone}
            value={formValues.phone}
            onChange={handleChange}
            required
          />
          <TextField
            margin="normal"
            required
            size="small"
            sx={{ width: "11rem" }}
            name="Password"
            label="Password"
            type={showPassword ? "text" : "password"}
            id="password"
            onChange={(event) => setPassword(event.target.value)}
            value={password}
            autoComplete="current-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={() => setShowPassword(!showPassword)}
                    sx={{ width: "20px" }}
                  >
                    {showPassword ? (
                      <Visibility sx={{ width: "15px", height: "15px" }} />
                    ) : (
                      <VisibilityOff sx={{ width: "15px", height: "15px" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Box sx={{ display: "flex" }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.button}
              disabled={invalidEmail || invalidPhone}
              sx={appStyle.general.button.blueButton}
            >
              Submit
            </Button>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CustomButton
                label="Cancel"
                handleOnClick={() => setAddUserDialog(false)}
                variant="text"
                color="error"
                btnName="Cancel"
              />
            </Box>
          </Box>
        </form>
      </Dialog>
      {/* User list table component called here */}
      {/* <UserDetailsTable
        userDetailsList={userDetailsList}
        pageNumber={pageNumber}
        pageSize={pageSize}
        ascOrDesc={ascOrDesc}
        isActive={isActive}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setAscOrDesc={setAscOrDesc}
        setIsActive={setIsActive}
        setAddUserDialog={setAddUserDialog}
        lastPageNumber={lastPageNumber}
      /> */}
      <CustomTable
        tableData={addUserRolesDomainDetails.userDetails}
        tableHead={["Full Name", "Role Name", "Phone", "Department"]}
        tableDataKey={[
          "fullname",
          "role_details.role_name",
          "phone",
          "role_details.description",
        ]}
        filter={true}
        filterSelectOption={[
          { label: "Roll Name", value: "role_name" },
          { label: "Full Name", value: "fullname" },
        ]}
        handleFilterClose={handleAddUserFilterClose}
        sort={true}
        sortSelectOption={[{ label: "Roll Name", value: "role_name" }]}
        handleSortClose={handleAddUserSortClose}
        addButton={true}
        handleOnClickAddButton={handleButtonFun}
        tableRowClickFunction={handleRowClick}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
      />
      <EditUserDetailsDialog
        openEditDialog={openEditDialog}
        setOpenEditDialog={setOpenEditDialog}
        selectedRow={selectedRow}
        pageNumber={pageNumber}
        pageSize={pageSize}
        ascOrDesc={ascOrDesc}
        // isActiveGet={isActiveGet}
      />
    </Box>
  );
};

export default AddUsers;
