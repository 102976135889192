import {
  Box,
  Button,
  Divider,
  Drawer,
  Icon,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  AppBar,
  Toolbar,
} from "@mui/material";
import React, { useContext } from "react";
import { sideBarData } from "./sideBarData";
import { useDispatch, useSelector } from "react-redux";
import actionTypes from "../../Redux/actionTypes";
import { toast } from "react-toastify";
import { Link, useHistory, Redirect } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useTheme } from "@emotion/react";
import { ColorModeContext, tokens } from "../../AppMeta/theme";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import bwLogo from "../../Assests/ImagesAndIcons/bwLogo.png";
import { GetProfile } from "../../Redux/Actions/profile";
import appStyle from "../../AppMeta/appStyle";
import SunnyIcon from "@mui/icons-material/WbSunnyOutlined";
import DarkIcon from "@mui/icons-material/DarkModeOutlined";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import DisplayTitle from "../../Components/CustomComp/DisplayTitle";
import ApiDocumentsMain from "../ApiDocuments/ApiDocumentsMain";

const Item = ({ title, to, icon, selected, setSelected, toggleDrawer }) => {
  const theme = useTheme();
  const history = useHistory();
  const colors = tokens(theme.palette.mode);
  const handleNavigator = () => {
    setSelected(title);
    history.push(to);
    toggleDrawer(false);
  };
  return (
    <ListItem
      disablePadding
      sx={{
        backgroundColor:
          selected === title &&
          appStyle.general.colors(colors).sideMenuBarActive,
      }}
      // onClick={() => {
      //   setSelected(title);
      // }}
    >
      <ListItemButton onClick={handleNavigator}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={title} />
      </ListItemButton>
    </ListItem>
  );
};

const settings = [
  { title: "Profile", path: "/profile" },
  { title: "Account", path: "/account" },
  { title: "Dashboard", path: "/dashboard" },
  { title: "Logout", path: "/authentication" },
];

const topBarTitleName = {
  home: "Home",
  products: "Stock Keeping Unit",
  usermanagement: "User Manager",
  waterstation: "Water Station",
  facilityordermanagement: "Facility Order Manager",
  organisationordermanagement: "Organisation Order Manager",
  organization_orders_List: "All Orders",
  bookwaterordermanagement: "Bookwater Order Manager",
  codesManagement: "QR/Barcode Manager",
  // organization: "Rewards and Offer",
  settings: "Settings",
  recycleOrders: "Recycle Orders",
  ApiDocumentsMain: "API Documentation",
};

const pathnameToTitleModifier = (text = "") => {
  let word = "";
  if (text === "") {
    return;
  }
  for (let i = 0; i < text.length; i++) {
    if (text[i] !== "-") {
      word = word + text[i];
    }
    if (text[i] === "/") {
      word = "";
    }
  }
  return word;
};

function Topbar() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profileData);
  const location = useLocation();

  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [selected, setSelected] = React.useState("DashboardMain");
  const [dashboard, setDashboard] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  // const isFacility = false;
  const [isLogout, setIsLogout] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [domain, setDomain] = React.useState("");
  const [isFacility, setIsFacility] = React.useState(false);
  const [orgFacilityName, setOrgFacilityName] = React.useState("");
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  React.useEffect(() => {
    dispatch(GetProfile());
  }, []);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  React.useEffect(() => {
    console.log("profileData");
    console.log(profileData);
    setIsFacility(profileData?.profile?.is_facility);
    setDomain(profileData?.profile?.domain);
    setOrgFacilityName(profileData?.profile?.org_name);
  }, [profileData]);

  // React.useEffect(() => {
  //   console.log("isFacility", isFacility);
  //   console.log("domain", domain);
  //   console.log("orgFacilityName", orgFacilityName);
  //   if (domain === "BOOKWATER.COM") {
  //     setDashboard(sideBarData.bookWaterDashboard);
  //   } else if (isFacility === true) {
  //     setDashboard(sideBarData.facilityDashBoard);
  //   } else {
  //     setDashboard(sideBarData.organisationDashboard);
  //   }
  // }, [isFacility, domain, orgFacilityName]);
  React.useEffect(() => {
    // console.log("isFacility", isFacility);
    // console.log("domain", domain);
    // console.log("orgFacilityName", orgFacilityName);
    // if (domain === "BOOKWATER.COM") {
    // } else if (isFacility === true) {
    //   setDashboard(sideBarData.facilityDashBoard);
    // } else {
    //   setDashboard(sideBarData.organisationDashboard);
    // }

    setDashboard(sideBarData.bookWaterDashboard);
  }, [isFacility, domain, orgFacilityName]);

  const handleProfile = (title) => {
    if (title === "Logout") {
      setIsLogout(true);
      dispatch({
        type: actionTypes.SIGN_OUT,
      });
    } else {
      toast.error("Implementation in progress", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  if (isLogout) {
    return <Redirect to="authentication" />;
  }

  return (
    <>
      <Box display="flex" justifyContent="space-between" sx={{ width: "100%" }}>
        {isMobile && (
          <Box
            display="flex"
            backgroundColor={colors.primary[500]}
            borderRadius="3px"
          >
            <IconButton
              onClick={toggleDrawer(true)}
              type="button"
              sx={{ p: 1 }}
            >
              <MenuIcon />
            </IconButton>

            <Drawer
              anchor="left"
              open={open}
              onClose={toggleDrawer(false)}
              PaperProps={{
                sx: { backgroundColor: colors.primary[500] },
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                p={2}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                  // type="button"
                  // onClick={toggleDrawer(false)}
                >
                  <IconButton
                    onClick={toggleDrawer(false)}
                    type="button"
                    sx={{ p: 1 }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
                {domain === "BOOKWATER.COM" ? (
                  <>
                    <img
                      alt=""
                      src={bwLogo}
                      style={{
                        cursor: "pointer",
                        height: "3rem",
                        width: "3rem",
                        marginTop: "-1rem",
                      }}
                    />
                    <Typography
                      variant="h2"
                      color={
                        appStyle.general.colors(colors).bookWaterTitleColor
                      }
                      fontWeight="bold"
                    >
                      {orgFacilityName}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography
                      variant="h2"
                      color={colors.grey[100]}
                      fontWeight="bold"
                    >
                      {orgFacilityName}
                    </Typography>
                    <Box>
                      <Button>Powered By BookWater</Button>
                    </Box>
                  </>
                )}

                <Divider />
                <Box
                  sx={{ width: 250 }}
                  role="presentation"
                  onClick={toggleDrawer(false)}
                >
                  {dashboard.map((item, index) => (
                    <Item
                      key={index}
                      title={item.title}
                      to={item.path}
                      icon={item.icon}
                      selected={selected}
                      setSelected={setSelected}
                      toggleDrawer={toggleDrawer}
                    />
                  ))}
                </Box>
              </Box>
            </Drawer>
          </Box>
        )}

        <Box sx={{ flexGrow: 1 }}>
          <AppBar
            position="static"
            sx={{
              backgroundColor: colors.primary[500],
              boxShadow: "none",
            }}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                {/* <MenuIcon /> */}
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                <DisplayTitle>
                  {topBarTitleName[pathnameToTitleModifier(location.pathname)]}
                </DisplayTitle>
                {/* {topBarTitleName[pathnameToTitleModifier(location.pathname)]} */}
              </Typography>
              <Box sx={{ right: 0, display: "flex" }}>
                <IconButton
                  sx={{ width: "24px" }}
                  onClick={colorMode.toggleColorMode}
                >
                  {theme.palette.mode === "dark" ? <DarkIcon /> : <SunnyIcon />}
                </IconButton>
                <IconButton>
                  <NotificationsIcon />
                </IconButton>

                <Box>
                  <IconButton onClick={handleOpenUserMenu}>
                    <SettingsIcon />
                  </IconButton>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {settings.map((setting) => (
                      <>
                        <MenuItem
                          key={setting.title}
                          onClick={() => handleProfile(setting.title)}
                        >
                          <Typography textAlign="center">
                            {setting.title}
                          </Typography>
                        </MenuItem>
                      </>
                    ))}
                    <Typography textAlign="center" sx={{ fontWeight: "bold" }}>
                      Version: 0.0.13
                    </Typography>
                  </Menu>
                </Box>

                <IconButton>
                  <AccountCircleIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
        </Box>

        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          {!isMobile && <Box></Box>}
          
        </Box> */}
      </Box>

      <Divider />
    </>
  );
}

export default Topbar;
