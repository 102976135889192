import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  TextField,
  DialogActions,
} from "@mui/material";
import { UpdateCreateBatch } from "../../Redux/Actions/adddCreateBatchAction";
import { useDispatch } from "react-redux";
import appStyle from "../../AppMeta/appStyle";

export function EditDialogBatch({ open, onClose, item }) {
  const dispatch = useDispatch();

  //1.useState
  const [quantity, setQuantity] = React.useState("");
  const [batchId, setBatchId] = React.useState("");

  //2.useEffect
  React.useEffect(() => {
    console.log("item");
    console.log(item);
    setQuantity(item.quantity);
    setBatchId(item.batch_id);
  }, []);

  const handleUpdate = () => {
    const reqObj = {
      BatchID: batchId,
      Quantity: quantity,
    };
    console.log("ReqObj");
    console.log(reqObj);
    dispatch(UpdateCreateBatch(reqObj));
  };
  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit Item</DialogTitle>

        <>
          <DialogContent>
            <Typography sx={{ mt: 2, mb: 1 }}>Batch ID:{batchId}</Typography>

            <TextField
              margin="dense"
              label="Quantity"
              fullWidth
              variant="standard"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
            />
          </DialogContent>

          <DialogActions>
            <Button
              color="primary"
              onClick={onClose}
              sx={appStyle.general.button.redButton}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={handleUpdate}
              sx={appStyle.general.button.blueButton}
            >
              Update
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </div>
  );
}
