import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Switch } from "react-router-dom/cjs/react-router-dom";
import React, { useRef, useContext } from "react";
import { ColorModeContext, tokens } from "../../AppMeta/theme";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { useHistory } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import QrCodeScanner from "@mui/icons-material/QrCodeScannerOutlined";
import WidgetsOutlined from "@mui/icons-material/WidgetsOutlined";
import ConsumerScanner from "../Orders/OrganizationBooking/OrganisationOrderManagement/ConsumerScanner";
import Home from "./Home";
import Menu from "./Menu";
import SunnyIcon from "@mui/icons-material/WbSunnyOutlined";
import DarkIcon from "@mui/icons-material/DarkModeOutlined";
import MobileTopBar from "./MobileTopBar";

function MobileNew() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  const [openDialog, setOpenDialog] = React.useState(false);
  const bottomNavRef = useRef(null);
  const colorMode = useContext(ColorModeContext);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleBottomNavChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      history.push("/mobile/home");
    } else if (newValue === 1) {
      handleOpenDialog(); // Example function for dialog
    } else if (newValue === 2) {
      history.push("/mobile/menu");
    }
  };

  return (
    <>
      <div className="app">
        <MobileTopBar />
        <main className="content">
          <Box
            sx={{
              overflowY: "hidden",
              marginTop: "60px",
              overscrollBehaviorY: "contain",
            }}
          >
            <Switch>
              <Route path="/mobile" exact component={Home} />
              <Route path="/mobile/home" exact component={Home} />
              <Route path="/mobile/scan" exact component={""} />
              <Route path="/mobile/menu" exact component={Menu} />
            </Switch>
          </Box>
        </main>
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
          }}
          elevation={3}
          ref={bottomNavRef}
        >
          <BottomNavigation
            showLabels
            value={value}
            onChange={handleBottomNavChange}
            sx={{
              height: "4rem",
            }}
          >
            <BottomNavigationAction
              label="Home"
              icon={<HomeOutlinedIcon />}
              sx={{
                width: "30px",
              }}
            />
            <BottomNavigationAction
              onClick={handleOpenDialog}
              label="Scan"
              icon={<QrCodeScanner />}
            />
            <BottomNavigationAction label="Menu" icon={<WidgetsOutlined />} />
          </BottomNavigation>
        </Box>
      </div>

      <Button
        sx={{
          backgroundColor: "var(--bg-color-inverted)",
          position: "fixed",
          right: 0,
          top: 70,
          width: 48,
          height: 40,
          borderTopLeftRadius: 30,
          borderBottomLeftRadius: 30,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "white",
          outline: 0,
          zIndex: 999,
          cursor: "pointer",
          boxShadow: 3,
        }}
      >
        <IconButton sx={{ width: "24px" }} onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? <DarkIcon /> : <SunnyIcon />}
        </IconButton>
      </Button>

      <ConsumerScanner open={openDialog} close={handleCloseDialog} />
    </>
  );
}

export default MobileNew;
