/**
 * @author Janarthanan
 * @date 5/11/24
 * @description This is the custom console -> Where if the environment is Production the logs won't be printed in the console
 * @param {any} data
 * @returns {string}
 */
export default function customConsole(data) {
  console.log(data);
}
