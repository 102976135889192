import {
  Box,
  Button,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext } from "react";
import { ColorModeContext, tokens } from "../../AppMeta/theme";
import SunnyIcon from "@mui/icons-material/WbSunnyOutlined";
import DarkIcon from "@mui/icons-material/DarkModeOutlined";
import { useHistory } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Topbar from "./Topbar";
import SideNavigation from "./SideNavigation";
import { Switch } from "react-router-dom/cjs/react-router-dom";
import { FacilityOrderManagement } from "../Orders/OrganizationBooking/FacilityOrderManagement/FacilityOrderManagement";
import Sample from "../Sample";
import { OrganisationOrderManagement } from "../Orders/OrganizationBooking/OrganisationOrderManagement/OrganisationOrderManagement";
import Sku from "../StockKeepingUnit/Sku";
import Home from "../Home/Home";
import CodesManagement from "../CodesManagement/CodesManagement";
import StockManagement from "../StockManagement/StockManagement";
import CustomPriceForm from "../Settings/Settings";
import PurchaseProduct from "../Orders/PurchaseProduct/PurchaseProduct";
import AccessManagementTabs1 from "../AccessManagement/AccessManagementTabs1";
import AuthenticationPage from "../Authentication/Authentication";
import FillingStation from "../FillingStation/FillingStation";
import BookingToManufacturer from "../Orders/BookwaterBooking/BookingToManufacturer";
import RecycleOrder from "../RecycleOrders/RecycleOrders";
import MobileNew from "../Mobile/MobileNew";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import Menu from "../Mobile/Menu";
import CheckOutMain from "../Orders/OrganizationBooking/OrganisationOrderManagement/CheckOutSku/CheckOutMain";
import OrganizationOrderList from "../OrganizationOrders/OrganizationOrderList";
import ApiDocumentsMain from "../ApiDocuments/ApiDocumentsMain";

function Dashboard() {
  const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const history = useHistory();

  return (
    <div className="app" style={{ height: "100vh", overflow: "hidden" }}>
      <Box sx={{ height: "100vh" }}>
        <SideNavigation />
      </Box>

      <main
        className="content"
        style={{
          height: "100vh",
          overflowY: "scroll",
          position: "relative",
          overflowX: "hidden",
        }}
      >
        <Box sx={{ position: "sticky", top: "0", zIndex: "1000" }}>
          <Topbar />
        </Box>
        <Switch>
          <Route path="/authentication" exact component={AuthenticationPage} />
          <Route path="/dashboard" exact component={Home} />
          <Route path="/dashboard/home" exact component={Home} />
          {/* <Route path="/dashboard/products" exact component={Products} /> */}
          <Route path="/dashboard/products" exact component={Sku} />
          {/* <Route path="/dashboard/products" exact component={PurchaseProduct} /> */}
          <Route
            path="/dashboard/user-management"
            exact
            component={AccessManagementTabs1}
          />
          <Route
            path="/dashboard/water-station"
            exact
            component={FillingStation}
          />
          <Route
            path="/dashboard/bookwater-order-management"
            exact
            component={BookingToManufacturer}
          />
          <Route
            path="/dashboard/facility-order-management"
            exact
            component={FacilityOrderManagement}
          />
          <Route
            path="/dashboard/organisation-order-management"
            exact
            component={OrganisationOrderManagement}
          />
          <Route
            path="/dashboard/organization_orders_List"
            exact
            component={OrganizationOrderList}
          />
          <Route path="/dashboard/checkout" exact component={CheckOutMain} />
          <Route
            path="/dashboard/codesManagement"
            exact
            component={CodesManagement}
          />
          <Route
            path="/dashboard/stock-management"
            exact
            component={StockManagement}
          />
          <Route path="/dashboard/settings" exact component={CustomPriceForm} />
          <Route path="/dashboard/sample" exact component={Sample} />
          <Route
            path="/dashboard/recycleOrders"
            exact
            component={RecycleOrder}
          />
          <Route
            path="/dashboard/api_documentation"
            exact
            component={ApiDocumentsMain}
          />
        </Switch>
      </main>

      {/* <Button
        sx={{
          backgroundColor: "var(--bg-color-inverted)",
          position: "fixed",
          right: 0,
          top: 70,
          width: 48,
          height: 40,
          borderTopLeftRadius: 30,
          borderBottomLeftRadius: 30,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "white",
          outline: 0,
          zIndex: 999,
          cursor: "pointer",
          boxShadow: 3,
        }}
      >
        <IconButton sx={{ width: "24px" }} onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? <DarkIcon /> : <SunnyIcon />}
        </IconButton>
      </Button> */}
    </div>
  );
}

export default Dashboard;
