import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { Box, Button, useTheme } from "@mui/material";
import TwentyLitre from "../../../src/Assests/FOM/bookwaternewcan.png";
import WaterCan from "../../AppMeta/AppImages/WaterCanImg.png";
import { tokens } from "../../AppMeta/theme";
const useStyles = makeStyles({
  card: {
    width: "200px",
    height: "300px",
    perspective: "1000px",
  },
  cardInner: {
    width: "200px",
    height: "100%",
    transition: "transform 0.6s",
    transformStyle: "preserve-3d",
  },
  cardFront: {
    position: "absolute",
    width: "100%",
    height: "auto",
    backfaceVisibility: "hidden",
  },
  cardBack: {
    position: "absolute",
    width: "100%",
    backfaceVisibility: "hidden",
    transform: "rotateY(180deg)",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  typo: {
    fontSize: { xs: "0.7rem", md: "1.2rem" },
  },
  backContent: {
    alignItems: "flex-start",
  },
});

export function CustomCheckStockCard() {
  const classes = useStyles();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [flipped, setFlipped] = useState(false);

  const handleClick = () => {
    setFlipped(!flipped);
  };

  return (
    <div className={classes.card}>
      <div
        className={classes.cardInner}
        style={{ transform: flipped ? "rotateY(180deg)" : "rotateY(0)" }}
      >
        <Card className={classes.cardFront}>
          <CardContent>
            <Box>
              <img src={TwentyLitre} className={classes.image} alt="20L Can" />
            </Box>
            <Button variant="contained" color="secondary" onClick={handleClick}>
              Show Details
            </Button>
          </CardContent>
        </Card>
        <Card className={classes.cardBack}>
          <CardContent className={classes.backContent}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ fontSize: { xs: "0.7rem", md: "1.2rem" } }}>
                Filled Containers
              </Typography>
              <Typography sx={{ fontSize: { xs: "0.8rem", md: "1.2rem" } }}>
                65
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ fontSize: { xs: "0.7rem", md: "1.2rem" } }}>
                Empty Containers
              </Typography>
              <Typography sx={{ fontSize: { xs: "0.8rem", md: "1.2rem" } }}>
                65
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ fontSize: { xs: "0.7rem", md: "1.2rem" } }}>
                Physically Damaged
              </Typography>
              <Typography sx={{ fontSize: { xs: "0.8rem", md: "1.2rem" } }}>
                65
              </Typography>
            </Box>
            <Button variant="contained" color="secondary" onClick={handleClick}>
              Show Back
            </Button>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
