/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Tahir Shaik
 * @description This file contains information about different Type of Actions Performing to Get/PUT/POST/DELETE data
 * @date 26/05/23
 * @version 0.0.1 - Initial Release
 */

const actionTypes = {
  SIGN_UP: "POST: signUp",
  LOGIN: "POST: logIn",
  SIGN_OUT: "signout",
  // Forgetpassword
  RESET_PASSWORD_VERIFY: "resetPasswordVerify",
  FORGOT_PASSWORD_USER_VERIFY_OTP: "userVerifyOtp",
  //   roles
  ADD_ROLE: "POST: addRole",
  GET_ROLES: "GET: getRoles",
  UPDATE_ROLES: "PUT: updateRoles",
  //   apis
  ADD_API: "POST: addApis",
  GET_API_LIST: "GET: getApiList",
  UPDATE_API_LIST: "PUT: updateApiList",
  //   organization
  ADD_ORGANIZATION: "POST: addOrganization",
  GET_ORGANIZATION_LIST: "GET: getOrganizationList",
  UPDATE_ORGANIZATION: "PUT: updateOrganization",
  // roles access
  ADD_ACCESS_ROLE: "POST: addAccessToRoles",
  GET_ACCESS_ROLE: "GET: getAccessToRoles",
  EDIT_ACCESS_ROLE: "PUT: editAccessToRoles",
  // add user roles domain
  ADD_USER_ROLES_DOMAIN: "POST: addUserRolesDomain",
  GET_USER_ROLES_DOMAIN_DETAILS: "GET: getUserRolesDomainDetails",
  EDIT_USER_DETAILS: "PUT: getUserRolesDomainDetails",
  // Wfs details
  ADD_WFS: "POST: addNewFillingStation",
  GET_WFS_LIST: "GET: getWfsList",
  UPDATE_WFS: "PUT: updateWfs",
  // Products
  // ADD_PRODUCT: "POST: addProduct",
  // GET_PRODUCTS: "GET: getProducts",
  // UPDATE_PRODUCT: "PUT: updateProducts",
  // Manufacturers
  ADD_MANUFACTURER: "POST: addManufacturer",
  GET_MANUFACTURER: "GET: getManufacturer",
  UPDATE_MANUFACTURER: "PUT: updateManufacturer",
  // Manufacturing orders
  CREATE_MFT_ORDER: "POST: createManufacturingOrders",
  GET_MFT_ORDERS: "GET: getManufacturingOrders",
  MFT_ORDER_APPROVAL: "PUT: mftOrdersApproval",
  EDIT_MFT_ORDER: "PUT: editMftOrder",
  COMPLETE_MFT_ORDER: "PUT: completeMftOrder",
  // File handling
  UPLOAD_FILE: "POST: uploadFile",
  GET_FILES: "GET: getFiles",
  // IoT Devices
  ADD_IOT_DEVICE: "POST: addIotDevice",
  GET_IOT_DEVICE: "GET: getIotDevices",
  GET_IOT_DEVICES: "GET_IOT_DEVICES_ALL",
  UPDATE_IOT_DEVICE: "PUT: updsateIotDevice",
  // WFS lines details
  ADD_LINES_WFS: "POST: addLinesWfs",
  GET_LINES_WFS: "GET: getLinesWfs",
  // generate qr
  GENERATE_QR: "POST: generateQR",
  GET_GENERATED_QR: "GET: getGeneratedQR",
  REGENERATE_QR_CODE: "POST: regenerateQR",
  // Stock details
  GET_STOCK_DETAILS: "GET: getStockDetails",
  GET_STOCK_TRACKING: "GET: getStockTracking",

  // wfs Batchd details
  CREATE_WFS_BATCH: "POST: creteWfsBatch",
  GET_WFS_BATCH: "GET: getWfsBatch",

  // Product components
  ADD_PRODUCT: "Post: addProduct",
  GET_PRODUCT: "GET: getProduct",
  GET_FINAL_PRODUCT: "GET: getFinalProducts",
  UPDATE_PRODUCT: "PUT: updateProduct",

  GET_COMPONENTS: "GET: getComponents",
  ADD_COMPONENT: "POST: addComponent",
  GET_COMPONENTS_FOR_PRODUCT: "GET: getComponentsByProduct",
  UPDATE_COMPONENTS: "PUT : updateComponentByProduct",

  // raw materials
  ADD_RAW_MATERIAL: "POST: addRawMaterials",
  UPDATE_RAW_MATERIAL: "PUT: updateRawMaterial",
  GET_RAW_MATERIAL: "GET: getRawMaterials",

  // Validate
  VALIDATE_ORDER: "POST: validateOrder",
  CHECK_PURCHASE_ORDER: "POST: checkPurchaseOrder",
  PO_FILE_UPDATE: "PUT: poFileUpdate",
  PO_ACCEPT_REJECT: "PUT: poAcceptReject",
  PLACE_ORDER: "POST: placeOrder",
  GET_SKU_STOCKS: "GET: getSkuStocks",
  VALIDATE_PROCESSINGPARTNER_ORDER: "POST: validateProcessingPartnerOrder",
  PLACE_ORDER_PROCESSINGPARTNER: "POST: placeProcessingPartnerOrder",
  CONFIRM_PROCESSING_PARTNER_ORDER: "PUT: confirmProcessingPartnerOrder",
  EDIT_PROCESSING_PARTNER_ORDER: "PUT: editProcessingPartnerOrder",

  // Crates
  ADD_CRATES: "POST: addCrates",
  GET_CRATES: "GET: getAllCrates",
  UPDATE_CRATES: "PUT: updateCrates",
  LINK_CRATES: "PUT: linkCrates",

  // Pincode and ares
  ADD_PINCODE_AREAS: "POST: allPincodeAreas",
  GET_PINCODE_AREAS: "GET: getAllPincodeAreas",
  PRODUCTS_TO_LOCALITY: "PUT: addProductsToLocality",
  REMOVE_PRODUCTS_LOCALITY: "PUT: removeProductsFromLocality",
  REMOVE_LOCALITY: "PUT: removeLocality",

  // linking and relinking process
  LINK_QR_BARCODES: "linkQrBarcodes",
  RELINK_QR_BARCODES: "relinkQrBarcodes",
  VALIDATE_QR_BARCODES_RELINK: "validateQrBarcodeRelink",
  CLEAR_LINKING_DATA: "clearLinkingData",

  //Empty New Can Orders
  GET_EMPTY_NEW_CAN_ORDER: "GET:getEmptyNewCanOrder",
  UPDATE_EMPTY_NEW_CAN_ORDER: "PUT:updateEmptyNewCanOrder",
  DELETE_EMPTY_NEW_CAN_ORDER: "DELETE:deleteEmptyNewCanOrder",

  //Facilities and Filling Process
  ADD_LINK_FACILITY: "POST: SelectingFacilities",
  GET_LINK_FACILITY: "GET: GetFacilities",
  PUT_LINK_FACILITY: "PUT: PutFacilities",

  //Create Batch
  ADD_CREATE_BATCH: "POST: Create Batch",
  GET_CREATE_BATCH: "GET : Get Batch",
  PUT_CREATE_BATCH: "PUT : Update Batch",
  CANCEL_CREATE_BATCH: "PUT: Cancel Batch",
  START_BATCH: "PUT: startBatch",
  STOP_BATCH: "PUT: stopBatch",

  //Facility Incharge
  ADD_FACILITY_INCHARGE: "POST : Create Facility Incharge",

  //Get Profile
  GET_PROFILE: "GET: Get Profile",

  //Recycle Orders
  GET_DAMAGED_EXPIRED: "GET : Get Damaged Expired",
  CREATE_RECYCLE_ORDER: "POST : Create Recycle Order",
  GET_RECYCLE_DAMAGE_TABLE: "GET : Get Recycle Damage Table",
  UPDATE_RECYCLE_ORDER: "UPDATE : Approve Recycle Damage",

  //Facility Orders
  CREATE_FACILITY_ORDER: "POST:Create Facility Order",
  GET_FACILITY_ORDER: "GET:Get Facility Order",
  CANCEL_FACILITY_ORDER: "PUT:Cancel Facility Order",
  //Organization Orders
  CART_CALCULATION_ORG: "POST: cartCalculationOrg",
  GET_PRODUCTS_ORG: "GET: getProductsforOrg",
  CREATE_ORG_ORDER: "POST: createOrgOrder",

  //SKU booking action
  ADD_SKU_PRODUCT_QTY: "AddSkuProductQty",
  REDUCE_SKU_PRODUCT_QTY: "ReduseSkuProductQty",
  REDUCE_RETURN_PRODUCT_QTY: "ReduseProductQty",
  ADD_RETURN_PRODUCT_QTY: "AddReturnProductQty",
  DELETE_SKU_PRODUCT_QTY: "DeleteSkuProductQty",
  SELECTED_PRODUCT_ACTION: "selectedProduct",
  // Checkout
  CHECKOUT_PRODUCTS_ACTION: "CheckOutDetails",
  ADD_CHECKOUT_REQUIRED_QTY: "AddCheckOutSkuQty",
  REDUSE_CHECKOUT_REQUIRED_QTY: "ReduseRequiredQty",
  ADD_CHECKOUT_RETURN_QTY: "AddCheckoutReturnQty",
  REDUSE_CHECKOUT_RETURN_QTY: "ReduseCheckoutReturnQty",

  SET_LOADING_FALSE_AFTER_DELAY: "SetLoadingFalseAfterDelay",
  FETCH_DATA_CHECKOUT_START: "FetchDataCheckOutStart",

  // Scanning in and out
  OUTSCAN_QR_LESS: "post: outScanQrless",

  // User Management
  GET_COUNTRY_CODES: "Get: get country codes",

  ADD_USER: "Post: add user",
  GET_USERS_LIST: "Get: get users list",
  UPDATE_USER: "Get: update user",
  CHANGE_USER_STATUS: "Put: change user status",
  DELETE_USER: "Put: delete user",
  CHANGE_USER_ROLE: "Put: change user role",
  ADD_ROLE: "Post: add role",
  GET_ROLES_LIST: "Get: get roles list",
  UPDATE_ROLE: "Put: update role",
  REMOVE_CHAPTERS: "Put: remove chapters",
  CHANGE_ROLE_STATUS: "Put: change role status",
  DELETE_ROLE: "Put: delete role",
  ADD_PAGE_ACCESS: "Put: add page access",
  REMOVE_PAGE_ACCESS: "Put: remove page access",
  ADD_FEATURE_ACCESS: "Put: add feature access",
  REMOVE_FEATURE_ACCESS: "Put: remove feature access",

  GET_APP_META_DATA: "Get: get app meta data",

  GET_FEATURES_LIST: "Get: get features list",
};

export default actionTypes;
