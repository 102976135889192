/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Edit access given for roles
 * @date 26/07/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CustomInputTextField from "../../../Components/CustomComp/CustomInputTextField";
import CustomTypography from "../../../Components/CustomComp/CustomTypography";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import Popover from "@mui/material/Popover";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import { useDispatch } from "react-redux";
import {
  EditAccessForRoles,
  GetAccessForRoles,
} from "../../../Redux/Actions/rolesAccessAction";
import ApiList from "../ApiList/ApiList";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ApiAccessProviderTable from "./ApiAccessProviderTable";
import appStyle from "../../../AppMeta/appStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function EditRoleAccessDialog({
  editRolesAccessDialog,
  setEditRolesAccessDialog,
  selectedRow,
  providedAccessList,
  listOfApis,
  selectedRole,
}) {
  console.log(selectedRow);
  // Required variable declared here
  const dispatch = useDispatch();
  const [chipData, setChipData] = React.useState([]);
  const [apisList, setApiList] = React.useState([]);
  const [httpsMethodsList, setHttpsMethodList] = React.useState([]);
  const [isForceUpdate, setIsForceUpdate] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [alignment, setAlignment] = React.useState("remove");

  const [newAccessApis, setNewsAccessApis] = React.useState([]);
  const [newAccessHttps, setNewAccessHttps] = React.useState([]);
  const [removedList, setRemovedList] = React.useState([]);

  React.useEffect(() => {
    console.log(newAccessApis);
    console.log(newAccessHttps);
  }, [newAccessApis, newAccessHttps]);

  // Toggle Button will switch between romove and add
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setEditRolesAccessDialog(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Executes whenever there is a change in selectedRow to set the chip data(APIs)
  React.useEffect(() => {
    console.log(selectedRow);
    if (true) {
      console.log("Here");
      const newChipData = selectedRow.api_names.map((api, index) => ({
        key: index,
        apis: api,
        httpsMethods: selectedRow.https_methods[index],
        description: selectedRow.api_descriptions[index],
      }));
      setChipData(newChipData);
    }
  }, [selectedRow]);

  // When user tries to remove the APIs access given for the role
  const handleDelete = (chipToDelete) => () => {
    console.log(chipToDelete);
    setRemovedList((prevArray) => [...prevArray, chipToDelete]);
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  // Executes when there is a change in removedList useState array
  React.useEffect(() => {
    console.log(removedList);
    const apiArray = [];
    const httpsArray = [];
    removedList.map((data) => {
      apiArray.push(data.apis);
      httpsArray.push(data.httpsMethods);
    });
    console.log(apiArray);
    setApiList(apiArray);
    console.log(httpsArray);
    setHttpsMethodList(httpsArray);
  }, [removedList]);

  // Executes when user clicks confirm edit, will have a Edit API call with required request object
  const handleClickConfirmUpdate = () => {
    if (alignment === "remove") {
      const reqObj = {
        role_name: selectedRow.roleName,
        api_name: apisList,
        https_method: httpsMethodsList,
        is_remove_api: true,
        is_force_update: isForceUpdate,
      };
      dispatch(EditAccessForRoles(reqObj));
      setTimeout(() => {
        dispatch(GetAccessForRoles());
      }, 1000);
      setEditRolesAccessDialog(false);
    } else if (alignment === "add") {
      console.log(newAccessApis);
      const apisArray = [];
      const httpsMethodArray = [];
      newAccessApis.map((apis) => {
        apisArray.push(apis.apiName);
      });
      newAccessHttps.map((httpsMethod) => {
        httpsMethodArray.push(httpsMethod.httpsMethod);
      });
      console.log(apisArray);
      console.log(httpsMethodArray);
      const reqObj = {
        role_name: selectedRow.role_name,
        api_name: apisArray,
        https_method: httpsMethodArray,
        is_remove_api: false,
        is_force_update: isForceUpdate,
      };
      dispatch(EditAccessForRoles(reqObj));
      setTimeout(() => {
        dispatch(GetAccessForRoles());
      }, 1000);
      setEditRolesAccessDialog(false);
    }
  };
  // jsx starts here

  return (
    <div>
      <Dialog
        open={editRolesAccessDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Edit roles and access provided"}</DialogTitle>
        <DialogContent>
          <Typography>Role name: {selectedRow?.roleName}</Typography>
          <Box sx={{ padding: "10px" }}>
            <ToggleButtonGroup
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
            >
              <ToggleButton value="remove">Remove Existing Access</ToggleButton>
              <ToggleButton value="add">Provide New Access</ToggleButton>
            </ToggleButtonGroup>
          </Box>
          {alignment === "remove" && (
            <Box>
              {chipData?.map((data) => {
                let icon;

                if (data.label === "React") {
                  icon = <TagFacesIcon />;
                }

                return (
                  <>
                    {" "}
                    {/* <ListItem key={data.key}> */}
                    <Box
                      sx={{
                        width: "fit-content",
                        padding: "10px",
                        height: "fit-content",
                      }}
                    >
                      <Chip
                        variant="outlined"
                        color="success"
                        size="large"
                        icon={icon}
                        sx={{ height: "100px" }}
                        label={
                          <div
                            style={{
                              padding: "10px",
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            <div>
                              <span style={{ fontWeight: "bold" }}>
                                API Name
                              </span>
                              : {data.apis}
                            </div>
                            <div>
                              <span style={{ fontWeight: "bold" }}>
                                Https Method
                              </span>
                              : {data.httpsMethods}
                            </div>
                            <div>
                              <span style={{ fontWeight: "bold" }}>
                                Description
                              </span>
                              : {data.description}
                            </div>
                          </div>
                        }
                        onDelete={
                          data.label === "React"
                            ? undefined
                            : handleDelete(data)
                        }
                        // Set the desired height
                      />
                    </Box>
                    {/* </ListItem> */}
                  </>
                );
              })}
            </Box>
          )}
          {alignment === "add" && (
            <Box>
              <ApiAccessProviderTable
                listOfApis={listOfApis}
                selectedRole={selectedRow.role_name}
                isEditAccessMode={true}
                setNewsAccessApis={setNewsAccessApis}
                setNewAccessHttps={setNewAccessHttps}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={appStyle.general.button.redButton}>
            Cancel
          </Button>
          <Button
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
            sx={appStyle.general.button.blueButton}
          >
            Update
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Box
              sx={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <FormControlLabel
                required
                control={
                  <Checkbox
                    checked={isForceUpdate}
                    onChange={(event) => setIsForceUpdate(event.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={
                  isForceUpdate
                    ? "You are force updating"
                    : "Do you want to force update"
                }
              />
              {isForceUpdate && (
                <CustomButton
                  variant="contained"
                  label="Confirm"
                  handleClick={handleClickConfirmUpdate}
                />
              )}
            </Box>
          </Popover>
        </DialogActions>
      </Dialog>
    </div>
  );
}
