/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description List of organization details presented in form of table
 * @date 26/07/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
import EditOrgDialogBox from "./EditOrgDialogBox";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TablePagination,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import CorporateFareRoundedIcon from "@mui/icons-material/CorporateFareRounded";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { appColors } from "../../../AppMeta/appColors";
import { FacilityInchargeDetails } from "./FacilityInchargeDetails";
import appStyle from "../../../AppMeta/appStyle";
import { tokens } from "../../../AppMeta/theme";
import appFonts from "../../../AppMeta/appFonts";
import CustomFilter from "../../../Components/CustomComp/CustomFilter";
import CustomTable from "../../../Components/CustomComp/CustomTable";

export default function OrganizationDetailsList({
  organizationList,
  setAddOrganizationDialog,
  setIsAddFacility,
  isAddFacility,
  pageNumber,
  pageSize,
  isActive,
  ascOrDesc,
  lastPageNumber,
  setPageNumber,
  setPageSize,
  setIsActive,
  setAscOrDesc,
  setFacilityId,
  setFacilityName,
}) {
  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            backgroundColor: "#040c45", // Set the desired background color here
            color: "white", // Set the desired text color here
            position: "sticky",
            marginTop: "0 !important",
          },
        },
      },
    },
  });
  // Required variables declared here
  const colors = tokens(useTheme().palette.mode);
  const [editOrgDialog, setEditOrgDialog] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [addFacilityInchargeDialog, setAddFacilityInchargeDialog] =
    React.useState(false);
  const [facility, setFacility] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0);

  React.useState(false);
  React.useEffect(() => {
    console.log(organizationList);
  }, []);

  //Pagination for table
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // If user clicks on any row in the table this function will set the selected row data in variable
  // const handleRowClick = (row) => {
  //   console.log("SelectedRow", row);
  //   setSelectedRow(row);
  //   setEditOrgDialog(true);
  // };

  // function createData(
  //   id,
  //   orgName,
  //   domain,
  //   areaName,
  //   pincode,
  //   state,
  //   district,
  //   isActive
  // ) {
  //   return {
  //     id,
  //     orgName,
  //     domain,
  //     areaName,
  //     pincode,
  //     state,
  //     district,
  //     isActive,
  //   };
  // }

  // console.log("organizationList", organizationList);
  // const rows = organizationList.map((item) =>
  //   createData(
  //     item.id,
  //     item.org_name,
  //     item.domain,
  //     item.details.area_name,
  //     item.details.pincode,
  //     item.details.state,
  //     item.details.district,
  //     item.is_active
  //   )
  // );

  // Toggle button for filtering the active and inactive status
  const handleChangeStatus = (event, newAlignment) => {
    setIsActive(newAlignment);
  };
  // Toggle button for filtering the Ascending and descending data in the table
  const handleChangeAscDesc = (event, newAlignment) => {
    console.log(newAlignment);
    setAscOrDesc(newAlignment);
  };
  // Pagenumber will be incremented
  const pageNumberForward = () => {
    if (organizationList.length > 0) {
      setPageNumber(pageNumber + 1);
      // setSearchOrderFlag(true);
    }
  };
  // Pagenumber will be decremented
  const pageNumberBackward = () => {
    if (pageNumber - 1 > 0) {
      setPageNumber(pageNumber - 1);
      // setdisableBtn(false);
      // setSearchOrderFlag(true);
    }
  };

  const facilityRowClick = (index, row) => {
    console.log(index);
    console.log("SelectedRow", row);
    setSelectedRow(row);
    setEditOrgDialog(true);
  };

  const handleAddFacilityButton = () => {
    console.log("--------Add facility------------");
  };

  // Custom toolbar for showing the filter option above the table
  // const CustomToolbar = () => {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         flexWrap: "wrap",
  //         alignItems: "center",
  //         justifyContent: "center",
  //         padding: "10px",
  //       }}
  //     >
  //       <Box
  //         sx={{
  //           padding: "10px",
  //           display: "flex",
  //           flexWrap: "wrap",
  //           alignItems: "center",
  //           justifyContent: "center",
  //           gap: "10px",
  //         }}
  //       >
  //         <CustomButton
  //           variant="outlined"
  //           color="primary"
  //           label="Add New Organization"
  //           handleClick={() => {
  //             setAddOrganizationDialog(true);
  //             setIsAddFacility(false);
  //           }}
  //           endIcon={<CorporateFareRoundedIcon />}
  //         />
  //         <CustomButton
  //           variant="outlined"
  //           color="primary"
  //           label="Add New Facility"
  //           handleClick={() => {
  //             setAddOrganizationDialog(true);
  //             setIsAddFacility(true);
  //           }}
  //           endIcon={<CorporateFareRoundedIcon />}
  //         />
  //       </Box>
  //       <Box
  //         sx={{
  //           display: "flex",

  //           flexWrap: "wrap",
  //           gap: "20px",
  //           alignItems: "center",
  //           justifyContent: "center",
  //         }}
  //       >
  //         <Box>
  //           <ToggleButtonGroup
  //             size="small"
  //             color="primary"
  //             value={isActive}
  //             exclusive
  //             onChange={handleChangeStatus}
  //             aria-label="Platform"
  //           >
  //             <ToggleButton value={true}>Active</ToggleButton>
  //             <ToggleButton value={false}>Inactive</ToggleButton>
  //           </ToggleButtonGroup>
  //         </Box>
  //         <Box>
  //           {" "}
  //           <ToggleButtonGroup
  //             size="small"
  //             color="primary"
  //             value={ascOrDesc}
  //             exclusive
  //             onChange={handleChangeAscDesc}
  //             aria-label="Platform"
  //           >
  //             <ToggleButton value="ASC">Ascending</ToggleButton>
  //             <ToggleButton value="DESC">Descending</ToggleButton>
  //           </ToggleButtonGroup>
  //         </Box>
  //       </Box>
  //     </div>
  //   );
  // };

  // Jsx starts here..
  return (
    <Box>
      <CustomTable
        tableHead={[
          "ID",
          "Facility Name",
          "Domain",
          "Area Name",
          "PINCODE",
          "State",
          "District",
          "Add organization",
          "Add Facility Incharge",
        ]}
        tableData={organizationList}
        tableDataKey={[
          "id",
          "org_name",
          "domain",
          "details.area_name",
          "details.pincode",
          "details.state",
          "details.district",
          <CustomButton
            btnName="ADD ORGANIZATION"
            size="small"
            endIcon={<CorporateFareRoundedIcon />}
            handleClick={(index, row) => {
              console.log(
                "ADD ORGANIZATION",
                row,
                row?.facility_id,
                row?.org_name
              );
              // e.stopPropagation();
              setFacilityId(row?.id);
              setFacilityName(row?.org_name);
              setAddOrganizationDialog(true);
              setIsAddFacility(false);
            }}
          />,
          <CustomButton
            btnName="ADD FACILITY INCHARGE"
            size="small"
            endIcon={<CorporateFareRoundedIcon />}
            handleClick={(index, row) => {
              console.log("ADD FACILITY INCHARGE", index, row);
              // e.stopPropagation();
              setAddFacilityInchargeDialog(true);
              setFacility(row);
              setIsAddFacility(false);
            }}
          />,
        ]}
        tableRowClickFunction={facilityRowClick}
        filter={true}
        sort={true}
        addButton={true}
        handleOnClickAddButton={handleAddFacilityButton}
      />
      {/* <Box sx={appStyle.general.button.addButtonBox}>
        <Button
          variant="contained"
          color="secondary"
          label="Add New Facility"
          sx={appStyle.general.button.addButton}
          handleClick={() => {
            setAddOrganizationDialog(true);
            setIsAddFacility(true);
          }}
          endIcon={<CorporateFareRoundedIcon />}
        >
          Add New Facility
        </Button>
      </Box> */}
      {/* <Toolbar>
        <CustomToolbar />
      </Toolbar> */}

      {/* Table with organization data */}

      {/* <Box sx={appStyle.general.table.containerForTable}>
        <TableContainer sx={appStyle.general.table.tableContainer}>
          <Table aria-label="simple table" size="small" stickyHeader>
            <TableHead
              sx={{
                backgroundColor:
                  appStyle.general.colors(colors).table.tableHeadBackground,
                color: colors.primary[300],
                ...appStyle.general.table.tableHead,
              }}
            >
              <TableRow>
                <TableCell sx={appStyle.general.table.headderRowCell}>
                  ID
                </TableCell>
                <TableCell sx={appStyle.general.table.headderRowCell}>
                  Facility Name
                </TableCell>
                <TableCell sx={appStyle.general.table.headderRowCell}>
                  Domain
                </TableCell>
                <TableCell sx={appStyle.general.table.headderRowCell}>
                  Area Name
                </TableCell>
                <TableCell sx={appStyle.general.table.headderRowCell}>
                  Pincode
                </TableCell>
                <TableCell sx={appStyle.general.table.headderRowCell}>
                  State
                </TableCell>
                <TableCell sx={appStyle.general.table.headderRowCell}>
                  District
                </TableCell>
                <TableCell sx={appStyle.general.table.headderRowCell}>
                  Active status
                </TableCell>
                <TableCell sx={appStyle.general.table.headderRowCell}>
                  Add organization
                </TableCell>
                <TableCell sx={appStyle.general.table.headderRowCell}>
                  Add Facility Incharge
                </TableCell>
                <TableCell sx={appStyle.general.table.headderRowCell}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: colors.primary[600] }}>
              {(rowsPerPage > 0
                ? rows.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : rows
              ).map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={appStyle.general.table.bodyRowCell}
                  >
                    {row.id}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {row.orgName}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {row.domain}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {row.areaName}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {row.pincode}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {row.state}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {row.district}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {row.isActive.toString()}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    <CustomButton
                      variant={appFonts.tableSecondaryButtonVarient}
                      color={appFonts.tableSecondaryButtonColor}
                      size={appFonts.tableButtonSize}
                      label="Add New Organization"
                      handleClick={(e) => {
                        e.stopPropagation();
                        setAddOrganizationDialog(true);
                        setIsAddFacility(false);
                      }}
                      endIcon={<CorporateFareRoundedIcon />}
                    />
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    <CustomButton
                      variant={appFonts.tableSecondaryButtonVarient}
                      color={appFonts.tableSecondaryButtonColor}
                      label="Add New Facility InCharge"
                      size={appFonts.tableButtonSize}
                      handleClick={(e) => {
                        e.stopPropagation();
                        setAddFacilityInchargeDialog(true);
                        setFacility(row);
                        setIsAddFacility(false);
                      }}
                      endIcon={<CorporateFareRoundedIcon />}
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      color={appFonts.tablePrimaryButtonColor}
                      variant={appFonts.tablePrimaryButtonVarient}
                      size={appFonts.tableButtonSize}
                      // onClick={() => handleRowClick(row)}
                      sx={appStyle.general.button.editButton}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={appStyle.general.table.pagination}>
          <TablePagination
            rowsPerPageOptions={[25, 100, 500, 1000]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box> */}
      {/* Pagination and pageSize  */}
      {/* <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          gap: "15px",
          padding: "10px",
          marginTop: "10px",
        }}
      >
        <Box>
          <Button
            sx={{
              cursor: "pointer",
            }}
            onClick={pageNumberBackward}
          >
            <ArrowBackIosIcon />
          </Button>
        </Box>
        <Typography
          variant="button"
          display="block"
          gutterBottom
          sx={{
            marginLeft: "10px",
            marginRight: "10px",
            backgroundColor:
              appStyle.general.colors(colors).pageNumberBackground,
            color: "white",
            padding: "5px",
            width: "40px",
            height: "35px",
            borderRadius: "15px",
            textAlign: "center",
          }}
        >
          {pageNumber}
        </Typography>
        <Box>
          <Button
            sx={{
              cursor: "pointer",
            }}
            onClick={pageNumberForward}
            disabled={pageNumber < lastPageNumber ? false : true}
          >
            <ArrowForwardIosIcon />
          </Button>
        </Box>
        <Box>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Page size</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={pageSize}
              label="Page size"
              onChange={(e) => {
                setPageSize(e.target.value);
                // setSearchOrderFlag(true);
              }}
            >
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={500}>500</MenuItem>
              <MenuItem value={1000}>1000</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box> */}
      {editOrgDialog && (
        <>
          <EditOrgDialogBox
            editOrgDialog={editOrgDialog}
            setEditOrgDialog={setEditOrgDialog}
            selectedRow={selectedRow}
          />
        </>
      )}
      {addFacilityInchargeDialog && (
        <>
          <FacilityInchargeDetails
            addFacilityInchargeDialog={addFacilityInchargeDialog}
            setAddFacilityInchargeDialog={setAddFacilityInchargeDialog}
            facility={facility}
          />
        </>
      )}
    </Box>
  );
}
