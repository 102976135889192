/**
 * @author Gowtham Prasath
 * @description Add/Update Picode area from sku
 * @copyright Bookwater tech pvt ltd
 * @version
 * @Date 29-01-2024
 */
// Importing the Libraries and Other Files
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import * as Material from "@mui/material";
import Radio from "@mui/material/Radio";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
// import { AddingNewPincodes } from "../../Pages/Redux/Actions/pincodeAndServiceAreaAction";
import { toast } from "react-toastify";
import {
  AddPincodeAreas,
  AddProductsToLocalities,
} from "../../Redux/Actions/pincodeAreasAction";
import { ConstructionOutlined } from "@mui/icons-material";
import appStyle from "../../AppMeta/appStyle";
// import {
//   AddPincodeSeriviceAreaSku,
//   DeleteProductFromLocality,
//   UpdatePincodePriceSku,
// } from "../../Pages/Redux/Actions/skuPincodeAction";
// import ConformationDialog from "./conformationDialog";

const data = [
  {
    product_details: {
      id: 1006,
      name: "New Dev Test Product#1",
      image: "",
      price: 0,
      amount: 0,
      refill: {
        sp: 100,
        mrp: 100,
        deposit: 200,
        refund_type: 100,
        refund_amount: 100,
      },
      sku_qty: [6],
      capacity: "100ml",
      hsn_code: "123456",
      material: "RUBBER",
      cgst_perc: 1.2,
      igst_perc: 1.2,
      life_span: 10000,
      sgst_perc: 1.2,
      created_at: "2024-02-03T08:48:29.997",
      order_type: 400,
      updated_at: "2024-02-03T08:48:30.021682",
      description: "This is a developer test product",
      booking_type: 1,
      max_book_qty: 1000,
      min_book_qty: 2,
      one_time_sale: {
        sp: 1,
        mrp: 1,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: 6,
        name: "Plasticd",
        image: "",
        price: 180,
        capacity: 1,
        description: "You can return the glass bottle and earn the Rewards",
      },
    ],
  },
  {
    product_details: {
      id: 1005,
      name: "test sd",
      image: "",
      price: 0,
      amount: 0,
      refill: {
        sp: 1,
        mrp: 1,
        deposit: 1,
        refund_type: 100,
        refund_amount: 1,
      },
      sku_qty: [12],
      capacity: "12",
      hsn_code: "12133",
      material: "JPET M",
      cgst_perc: 12,
      igst_perc: 21,
      life_span: 1223222,
      sgst_perc: 12,
      created_at: "2024-02-02T12:58:55.512",
      order_type: 100,
      updated_at: "2024-02-02T12:58:55.527878",
      description: "cdsfweerewre dfsdf",
      booking_type: 1,
      max_book_qty: 12112,
      min_book_qty: 12,
      one_time_sale: {
        sp: 1,
        mrp: 1,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 1004,
      name: "BW_Bookwater 1 l Bottle",
      image: "uNBwW9aap.png",
      price: 0,
      amount: 0,
      refill: {
        sp: 5,
        mrp: 10,
        deposit: 10,
        refund_type: 100,
        refund_amount: 55,
      },
      sku_qty: [2],
      capacity: "1 litre",
      hsn_code: "70109",
      material: "BOTTLE",
      cgst_perc: 6,
      igst_perc: 6,
      life_span: 10,
      sgst_perc: 6,
      created_at: "2024-02-01T13:44:12.277",
      order_type: 400,
      updated_at: "2024-02-01T13:44:12.28599",
      description: "BW_Bookwater 1 l Bottle",
      booking_type: 3,
      max_book_qty: 999,
      min_book_qty: 2,
      one_time_sale: {
        sp: 15,
        mrp: 20,
        deposit: 10,
        refund_type: 100,
        refund_amount: 10,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 998,
      name: "test tests",
      image: "JZ-HVbOFb.png",
      price: 0,
      amount: 0,
      refill: {
        sp: 30,
        mrp: 50,
        deposit: 100,
        refund_type: 100,
        refund_amount: 200,
      },
      sku_qty: [2],
      capacity: "1 litre",
      hsn_code: "22344",
      material: "GLASS",
      cgst_perc: 4,
      igst_perc: 4,
      life_span: 20,
      sgst_perc: 4,
      created_at: "2024-02-01T11:52:19.919",
      order_type: 400,
      updated_at: "2024-02-01T11:52:19.927525",
      description: "asdassfdsaa sdfsada",
      booking_type: 1,
      max_book_qty: 122,
      min_book_qty: 1,
      one_time_sale: {
        sp: 1,
        mrp: 1,
        deposit: 1,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 1001,
      name: "200 ML Steel Bottle (M)",
      image: "GaQWqvUYo.png",
      price: 0,
      amount: 0,
      refill: {
        sp: 40,
        mrp: 55,
        deposit: 100,
        refund_type: 100,
        refund_amount: 50,
      },
      sku_qty: [6],
      capacity: "200 ML",
      hsn_code: "080319",
      material: "GLASS",
      cgst_perc: 9,
      igst_perc: 9,
      life_span: 5,
      sgst_perc: 9,
      created_at: "2024-02-01T12:18:05.828",
      order_type: 400,
      updated_at: "2024-02-01T12:18:05.83822",
      description: "20 ML Steel Bottle",
      booking_type: 1,
      max_book_qty: 12,
      min_book_qty: 6,
      one_time_sale: {
        sp: 1,
        mrp: 1,
        deposit: 1,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: 27,
        name: "200 ML steel bottle (M)",
        image: "jKvKcduFr.png",
        price: 40,
        capacity: 6,
        description: "200 ML steel Bottle crate (M)",
      },
    ],
  },
  {
    product_details: {
      id: 1013,
      name: "add 7 ",
      image: "",
      price: 0,
      amount: 0,
      refill: {
        sp: 1,
        mrp: 1,
        deposit: 1,
        refund_type: 100,
        refund_amount: 1,
      },
      sku_qty: [5],
      capacity: "20 liter",
      hsn_code: "33434",
      material: "PET MATERIAL",
      cgst_perc: 2,
      igst_perc: 2,
      life_span: 4,
      sgst_perc: 2,
      created_at: "2024-02-05T05:12:41.225",
      order_type: 400,
      updated_at: "2024-02-05T05:12:41.236547",
      description: "To add product into system",
      booking_type: 1,
      max_book_qty: 5,
      min_book_qty: 2,
      one_time_sale: {
        sp: 1,
        mrp: 1,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 888,
      name: "Bookwater Can",
      image: "bHJ9tgNCZ.png",
      price: 110,
      amount: 110,
      refill: {
        sp: 15,
        mrp: 100,
        deposit: 10,
        refund_type: 100,
        refund_amount: 110,
      },
      sku_qty: [12],
      capacity: "20 liters",
      hsn_code: "13555",
      material: "PLASITIC",
      cgst_perc: 18,
      igst_perc: 10,
      life_span: 30,
      sgst_perc: 18,
      created_at: "2024-01-23T05:05:03.457",
      order_type: 400,
      updated_at: "2024-01-23T05:05:03.469281",
      description: "Sample Bookwater can",
      booking_type: 3,
      max_book_qty: 12,
      min_book_qty: 2,
      one_time_sale: {
        sp: 16,
        mrp: 150,
        deposit: 10,
        refund_type: 100,
        refund_amount: 110,
      },
    },
    crate_details: [
      {
        id: 22,
        name: "BW_common crate 500ml/1l",
        image: "",
        price: 100,
        capacity: 36,
        description: "common crate 500ml/1l",
      },
    ],
  },
  {
    product_details: {
      id: 910,
      name: "chennai 11",
      image: "jROJOdYCH.png",
      price: 120,
      amount: 120,
      refill: {
        sp: 17,
        mrp: 100,
        deposit: 10,
        refund_type: 100,
        refund_amount: 10,
      },
      sku_qty: [12],
      capacity: "20-liters",
      hsn_code: "67666",
      material: "PLASTIC",
      cgst_perc: 7,
      igst_perc: 2,
      life_span: 30,
      sgst_perc: 2,
      created_at: "2024-01-24T05:24:11.499",
      order_type: 400,
      updated_at: "2024-01-24T10:55:14",
      description: "gjhgfghjhgfghjjhg",
      booking_type: 3,
      max_book_qty: 7,
      min_book_qty: 2,
      one_time_sale: {
        sp: 81,
        mrp: 100,
        deposit: 20,
        refund_type: 100,
        refund_amount: 20,
      },
    },
    crate_details: [
      {
        id: 9,
        name: "Bookwater catrates",
        image: "",
        price: 124,
        capacity: 12,
        description: "Plastic premium crate",
      },
    ],
  },
  {
    product_details: {
      id: 889,
      name: "20 Liter ",
      image: "ymHvrMlju.png",
      price: 300,
      amount: 300,
      refill: {
        sp: 12,
        mrp: 122,
        deposit: 100,
        refund_type: 100,
        refund_amount: 12,
      },
      sku_qty: [33],
      capacity: "20 Liters",
      hsn_code: "33222",
      material: "PLASTIC",
      cgst_perc: 12,
      igst_perc: 1,
      life_span: 30,
      sgst_perc: 12,
      created_at: "2024-01-23T05:40:21.943",
      order_type: 400,
      updated_at: "2024-01-23T05:40:21.956189",
      description: "Sample product by gowtham",
      booking_type: 3,
      max_book_qty: 12,
      min_book_qty: 2,
      one_time_sale: {
        sp: 50,
        mrp: 150,
        deposit: 100,
        refund_type: 100,
        refund_amount: 13,
      },
    },
    crate_details: [
      {
        id: 22,
        name: "BW_common crate 500ml/1l",
        image: "",
        price: 100,
        capacity: 36,
        description: "common crate 500ml/1l",
      },
    ],
  },
  {
    product_details: {
      id: 186,
      name: "",
      image: "",
      price: 1,
      amount: 1,
      refill: {
        sp: 55,
        mrp: 75,
        deposit: 200,
        refund_type: 100,
        refund_amount: 200,
      },
      sku_qty: [1],
      capacity: "20_Liters",
      hsn_code: "",
      material: "COPPER",
      cgst_perc: 0,
      igst_perc: 0,
      life_span: 0,
      sgst_perc: 0,
      created_at: "2022-07-08T18:02:55",
      order_type: 0,
      updated_at: "2023-12-20T06:30:52.990656",
      description: "",
      booking_type: 1,
      max_book_qty: 1,
      min_book_qty: 1,
      one_time_sale: {
        sp: 0,
        mrp: 0,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 915,
      name: "",
      image: "",
      price: 200,
      amount: 200,
      refill: {
        sp: 55,
        mrp: 75,
        deposit: 200,
        refund_type: 100,
        refund_amount: 200,
      },
      sku_qty: [1],
      capacity: "20_Liters",
      hsn_code: "",
      material: "PET",
      cgst_perc: 0,
      igst_perc: 0,
      life_span: 30,
      sgst_perc: 0,
      created_at: "2024-01-24T18:33:23",
      order_type: 0,
      updated_at: "2024-02-01T11:48:45",
      description: "",
      booking_type: 1,
      max_book_qty: 1,
      min_book_qty: 1,
      one_time_sale: {
        sp: 0,
        mrp: 0,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 710,
      name: "",
      image: "",
      price: 25,
      amount: 25,
      refill: {
        sp: 55,
        mrp: 75,
        deposit: 200,
        refund_type: 100,
        refund_amount: 200,
      },
      sku_qty: [1],
      capacity: "20_Liters",
      hsn_code: "",
      material: "POLYCARBONATE",
      cgst_perc: 0,
      igst_perc: 0,
      life_span: 25,
      sgst_perc: 0,
      created_at: "2023-06-06T13:00:38",
      order_type: 0,
      updated_at: "2023-12-20T06:30:52.990656",
      description: "",
      booking_type: 1,
      max_book_qty: 1,
      min_book_qty: 1,
      one_time_sale: {
        sp: 0,
        mrp: 0,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 709,
      name: "",
      image: "",
      price: 30,
      amount: 30,
      refill: {
        sp: 55,
        mrp: 75,
        deposit: 200,
        refund_type: 100,
        refund_amount: 200,
      },
      sku_qty: [1],
      capacity: "20_Liters",
      hsn_code: "",
      material: "PVC",
      cgst_perc: 0,
      igst_perc: 0,
      life_span: 25,
      sgst_perc: 0,
      created_at: "2023-06-06T13:00:38",
      order_type: 0,
      updated_at: "2023-12-20T06:30:52.990656",
      description: "",
      booking_type: 1,
      max_book_qty: 1,
      min_book_qty: 1,
      one_time_sale: {
        sp: 0,
        mrp: 0,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 876,
      name: "20ml bottle",
      image: "CKADeZPa9.jpg",
      price: 122,
      amount: 122,
      refill: {
        sp: 55,
        mrp: 75,
        deposit: 200,
        refund_type: 100,
        refund_amount: 200,
      },
      sku_qty: [1],
      capacity: "20mls",
      hsn_code: "86777776",
      material: "GLASS",
      cgst_perc: 8,
      igst_perc: 6,
      life_span: 22,
      sgst_perc: 6,
      created_at: "2024-01-20T09:07:16.199",
      order_type: 400,
      updated_at: "2024-01-20T09:07:16.209225",
      description: "This is a sample product by vinoth",
      booking_type: 1,
      max_book_qty: 33,
      min_book_qty: 1,
      one_time_sale: {
        sp: 0,
        mrp: 0,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 1014,
      name: "add 8 ",
      image: "",
      price: 0,
      amount: 0,
      refill: {
        sp: 1,
        mrp: 1,
        deposit: 1,
        refund_type: 100,
        refund_amount: 1,
      },
      sku_qty: [5],
      capacity: "21 liter",
      hsn_code: "33434",
      material: "PET MATERIAL",
      cgst_perc: 2,
      igst_perc: 2,
      life_span: 4,
      sgst_perc: 2,
      created_at: "2024-02-05T05:13:02.573",
      order_type: 400,
      updated_at: "2024-02-05T05:13:02.58689",
      description: "To add product into system",
      booking_type: 1,
      max_book_qty: 5,
      min_book_qty: 2,
      one_time_sale: {
        sp: 1,
        mrp: 1,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 1015,
      name: "add 9 ",
      image: "",
      price: 0,
      amount: 0,
      refill: {
        sp: 1,
        mrp: 1,
        deposit: 1,
        refund_type: 100,
        refund_amount: 1,
      },
      sku_qty: [5],
      capacity: "22 liter",
      hsn_code: "33434",
      material: "PET MATERIAL",
      cgst_perc: 2,
      igst_perc: 2,
      life_span: 4,
      sgst_perc: 2,
      created_at: "2024-02-05T05:13:08.725",
      order_type: 400,
      updated_at: "2024-02-05T05:13:08.736467",
      description: "To add product into system",
      booking_type: 1,
      max_book_qty: 5,
      min_book_qty: 2,
      one_time_sale: {
        sp: 1,
        mrp: 1,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 1012,
      name: "add 6 ",
      image: "",
      price: 0,
      amount: 0,
      refill: {
        sp: 1,
        mrp: 1,
        deposit: 1,
        refund_type: 100,
        refund_amount: 1,
      },
      sku_qty: [5],
      capacity: "2 liter",
      hsn_code: "33434",
      material: "PET MATERIAL",
      cgst_perc: 2,
      igst_perc: 2,
      life_span: 4,
      sgst_perc: 2,
      created_at: "2024-02-05T05:12:26.619",
      order_type: 400,
      updated_at: "2024-02-05T05:12:26.657039",
      description: "To add product into system",
      booking_type: 1,
      max_book_qty: 5,
      min_book_qty: 2,
      one_time_sale: {
        sp: 1,
        mrp: 1,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 987,
      name: "One bottle down",
      image: "jZOkxY6YM.png",
      price: 0,
      amount: 0,
      refill: {
        sp: 5,
        mrp: 10,
        deposit: 20,
        refund_type: 100,
        refund_amount: 10,
      },
      sku_qty: [2],
      capacity: "2 liter",
      hsn_code: "70109000",
      material: "THERMOCOL",
      cgst_perc: 2,
      igst_perc: 3,
      life_span: 1,
      sgst_perc: 3,
      created_at: "2024-02-01T10:12:04.582",
      order_type: 400,
      updated_at: "2024-02-01T10:12:04.592995",
      description: "kuch add kiya jaye ",
      booking_type: 1,
      max_book_qty: 1,
      min_book_qty: 1,
      one_time_sale: {
        sp: 1,
        mrp: 1,
        deposit: 1,
        refund_type: 100,
        refund_amount: 1,
      },
    },
    crate_details: [
      {
        id: 13,
        name: "cratePremium",
        image: "zjJdLAT26.jpg",
        price: 123,
        capacity: 122,
        description: "wdgvfcdsfdcxsfdrfds",
      },
      {
        id: 17,
        name: "One bottle down crate",
        image: "KS4Kagr09.jpg",
        price: 200,
        capacity: 3,
        description: "kuch add kiya jaye",
      },
    ],
  },
  {
    product_details: {
      id: 1009,
      name: "Add 3",
      image: "",
      price: 0,
      amount: 0,
      refill: {
        sp: 1,
        mrp: 1,
        deposit: 2,
        refund_type: 100,
        refund_amount: 1,
      },
      sku_qty: [3],
      capacity: "3 pack",
      hsn_code: "34543",
      material: "PLASTIC",
      cgst_perc: 3,
      igst_perc: 3,
      life_span: 4,
      sgst_perc: 3,
      created_at: "2024-02-05T05:08:51.671",
      order_type: 400,
      updated_at: "2024-02-05T05:08:51.683678",
      description: "To add product into system",
      booking_type: 1,
      max_book_qty: 6,
      min_book_qty: 3,
      one_time_sale: {
        sp: 1,
        mrp: 1,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 1011,
      name: "add 5 ",
      image: "",
      price: 0,
      amount: 0,
      refill: {
        sp: 1,
        mrp: 1,
        deposit: 1,
        refund_type: 100,
        refund_amount: 1,
      },
      sku_qty: [5],
      capacity: "41 liter",
      hsn_code: "33434",
      material: "PET MATERIAL",
      cgst_perc: 2,
      igst_perc: 2,
      life_span: 4,
      sgst_perc: 2,
      created_at: "2024-02-05T05:12:13.665",
      order_type: 400,
      updated_at: "2024-02-05T05:12:13.678344",
      description: "To add product into system",
      booking_type: 1,
      max_book_qty: 5,
      min_book_qty: 2,
      one_time_sale: {
        sp: 1,
        mrp: 1,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 1010,
      name: "add 4 ",
      image: "",
      price: 0,
      amount: 0,
      refill: {
        sp: 1,
        mrp: 1,
        deposit: 1,
        refund_type: 100,
        refund_amount: 1,
      },
      sku_qty: [5],
      capacity: "4 liter",
      hsn_code: "33434",
      material: "PET MATERIAL",
      cgst_perc: 2,
      igst_perc: 2,
      life_span: 4,
      sgst_perc: 2,
      created_at: "2024-02-05T05:10:17.546",
      order_type: 400,
      updated_at: "2024-02-05T05:10:17.557914",
      description: "To add product into system",
      booking_type: 1,
      max_book_qty: 5,
      min_book_qty: 2,
      one_time_sale: {
        sp: 1,
        mrp: 1,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 1007,
      name: "add 1",
      image: "",
      price: 0,
      amount: 0,
      refill: {
        sp: 1,
        mrp: 1,
        deposit: 1,
        refund_type: 100,
        refund_amount: 1,
      },
      sku_qty: [2],
      capacity: "4 liter",
      hsn_code: "45345",
      material: "PLASTIC",
      cgst_perc: 4,
      igst_perc: 2,
      life_span: 3,
      sgst_perc: 3,
      created_at: "2024-02-05T05:05:48.604",
      order_type: 400,
      updated_at: "2024-02-05T05:05:48.617522",
      description: "just for add test",
      booking_type: 1,
      max_book_qty: 6,
      min_book_qty: 3,
      one_time_sale: {
        sp: 1,
        mrp: 1,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
  {
    product_details: {
      id: 995,
      name: "BW_Bookwater 500ml",
      image: "",
      price: 0,
      amount: 0,
      refill: {
        sp: 1,
        mrp: 1,
        deposit: 1,
        refund_type: 100,
        refund_amount: 0,
      },
      sku_qty: [12],
      capacity: "500ml",
      hsn_code: "701090",
      material: "BOTTLE",
      cgst_perc: 5,
      igst_perc: 5,
      life_span: 10,
      sgst_perc: 5,
      created_at: "2024-02-01T10:50:52.395",
      order_type: 400,
      updated_at: "2024-02-01T10:50:52.403186",
      description: "BW_Bookwater 500ml",
      booking_type: 2,
      max_book_qty: 999,
      min_book_qty: 2,
      one_time_sale: {
        sp: 5,
        mrp: 10,
        deposit: 10,
        refund_type: 100,
        refund_amount: 5,
      },
    },
    crate_details: [
      {
        id: null,
        name: null,
        image: null,
        price: null,
        capacity: null,
        description: null,
      },
    ],
  },
];

function AddUpdatePicodeAreaSku({
  openPincodeDialog,
  setOpenPicodeDialog,
  updatePincodeData,
  setupdatePincodeData,
  allProducts,
}) {
  // const skuPincodeDetails = useSelector((state) => state.skuPincodeDetails);
  let initialPicodeData = {
    pincode: "",
    country: "",
    country_code: "+91",
    district: "",
    state: "",
    locality: [],
    products: [],
  };
  const skuContainerDetails = useSelector(
    (state) => state.newProductContainerDetails
  );
  const [pincodeData, setPincodeData] = useState(initialPicodeData);
  const [serviceArea, setServiceArea] = React.useState("");
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [productList, setProductList] = React.useState({
    product_id: "",
    booking_type: "",
    name: "",
    refill: {
      mrp: 1,
      sp: 1,
      deposit: 0,
      refund_type: 100,
      refund_amount: 0,
    },
    one_time_sale: {
      mrp: 1,
      sp: 1,
      deposit: 0,
      refund_type: 0,
      refund_amount: 0,
    },
  });
  const [deleteProduct, setDeleteProduct] = React.useState({
    locality_id: "",
    products: [],
  });
  const updateFlag = Object.keys(updatePincodeData)?.length > 0;
  console.log(updatePincodeData);
  const dispatch = useDispatch();
  //variable for different Refund types
  const refundTypes = [
    {
      label: "WALLET",
      value: "100",
    },
    {
      label: "WATER DROPS",
      value: "200",
    },
    {
      label: "None",
      value: "0",
    },
  ];

  //useEffect for setting up initial data in state
  console.log(updateFlag);
  React.useEffect(() => {
    if (updateFlag) {
      setPincodeData((prev) => ({
        products: updatePincodeData.products,
        locality_ids: [updatePincodeData.id],
      }));
    }
  }, [updatePincodeData, updateFlag]);

  // handle change function for adding pincode details
  const handelChangePicodeData = (event) => {
    const { name, value } = event.target;
    let pinCodeRegex = /^[1-9][0-9]{5}$/;
    if (name === "pincode" && !pinCodeRegex.test(value)) {
      setErrors(true);
      setPincodeData((prev) => ({ ...prev, [name]: value }));
    } else {
      setErrors(false);
      setPincodeData((prev) => ({ ...prev, [name]: value }));
    }
  };

  //UseEffect for validation
  React.useEffect(() => {
    if (pincodeData.country?.trim().length < 3) {
      setErrors(true);
    } else if (pincodeData.district?.trim().length < 3) {
      setErrors(true);
    } else if (pincodeData.state?.trim().length < 3) {
      setErrors(true);
    } else {
      setErrors(false);
    }
  }, [pincodeData]);

  // handle change function for adding locality
  const handleAddServiceArea = () => {
    if (serviceArea?.trim() !== "") {
      setPincodeData((prev) => ({
        ...prev,
        locality: [...prev.locality, serviceArea],
      }));
      setServiceArea("");
    } else {
    }
  };

  // remove area from locality array
  const removeAreaFrArr = (idx) => {
    const newLocality = [...pincodeData.locality];
    newLocality.splice(idx, 1);
    setPincodeData((prev) => ({
      ...prev,
      locality: newLocality,
    }));
  };

  // function for closing dialog
  const handelClose = () => {
    setOpenPicodeDialog(false);
    setPincodeData(initialPicodeData);
    setupdatePincodeData({});
  };

  //function for handling product selection change
  const handleChangeSelectedProduct = (event) => {
    const selectedDetails = allProducts.find(
      (data) => data?.product_details?.name === event.target.value
    );
    console.log(selectedDetails);
    if (selectedDetails) {
      setProductList((prev) => ({
        ...prev,
        name: selectedDetails?.product_details?.name,
        product_id: selectedDetails?.product_details?.id,
        booking_type: selectedDetails?.product_details?.booking_type,
        refill: {
          ...prev.refill,
          mrp: selectedDetails?.product_details?.refill?.mrp,
          sp: selectedDetails?.product_details?.refill?.sp,
          deposit: selectedDetails?.product_details?.refill?.deposit,
          refund_type: selectedDetails?.product_details?.refill?.refund_type,
          refund_amount:
            selectedDetails?.product_details?.refill?.refund_amount,
        },
        one_time_sale: {
          ...prev.one_time_sale,
          mrp: selectedDetails?.product_details?.one_time_sale?.mrp,
          sp: selectedDetails?.product_details?.one_time_sale?.sp,
          deposit: selectedDetails?.product_details?.one_time_sale?.deposit,
          refund_type:
            selectedDetails?.product_details?.one_time_sale?.refund_type,
          refund_amount:
            selectedDetails?.product_details?.one_time_sale?.refund_amount,
        },
      }));
    }
  };

  //function for handling add product detaisls
  const handelAddProduct = () => {
    if (Object.keys(productList).length > 0) {
      let duplicateElement = pincodeData.products.filter(
        (data) => data.product_id === productList.product_id
      );
      if (duplicateElement.length === 0) {
        setPincodeData((prev) => ({
          ...prev,
          products: [...prev.products, productList],
        }));
        setProductList({});
      }
    } else {
    }
  };

  //function for handling product change
  const handleProductDetailsChange = (event, index, saleType, field) => {
    const newValue =
      event.target.value.trim() !== "" ? parseFloat(event.target.value) : 0;
    setPincodeData((prev) => ({
      ...prev,
      products: prev.products.map((product, i) =>
        i === index
          ? {
              ...product,
              [saleType]: {
                ...product[saleType],
                [field]: newValue,
              },
            }
          : product
      ),
    }));
  };

  //function for handling product Booking type change
  const handleProductBookingTypeChange = (value, index, field) => {
    const newValue = value;
    console.log(newValue);
    setPincodeData((prev) => ({
      ...prev,
      products: prev.products.map((product, i) =>
        i === index
          ? {
              ...product,
              [field]: newValue,
            }
          : product
      ),
    }));
  };

  //useEffect for closing dialog after sucessfull adding of pincode
  // React.useEffect(() => {
  //   if (!skuPincodeDetails.addPincodeError) {
  //     handelClose();
  //     setPincodeData(initialPicodeData);
  //   }
  // }, [skuPincodeDetails.addPincodeError]);

  // console.log(skuPincodeDetails);
  //function for updating data
  const handelUpdateData = () => {
    console.log(pincodeData);
    if (!updateFlag) {
      // dispatch(AddPincodeSeriviceAreaSku(pincodeData));
      dispatch(AddPincodeAreas(pincodeData));
      setOpenPicodeDialog(false);
    } else {
      UpdatePincodePrice(pincodeData);
    }
  };

  //function for updating pincode price detaisl
  const UpdatePincodePrice = () => {
    dispatch(AddProductsToLocalities(pincodeData));
    setOpenPicodeDialog(false);
  };

  //useEffect for hitting api
  // React.useEffect(() => {
  //   if (!skuPincodeDetails.updatePincodeError) {
  //     handelClose();
  //     setPincodeData(initialPicodeData);
  //     setupdatePincodeData({});
  //   }
  // }, [skuPincodeDetails.updatePincodeError]);

  //function for opening conformation dialog
  const handelDeleteProduct = (value, idx) => {
    console.log(value, idx);
    if (updateFlag) {
      setConfirmDialog(true);
      setDeleteProduct((prev) => ({
        locality_id: updatePincodeData.id,
        products: [value.product_id],
      }));
    } else {
      const newProductList = [...pincodeData.products];
      newProductList.splice(idx, 1);
      setPincodeData((prev) => ({
        ...prev,
        products: newProductList,
      }));
    }
  };

  //function for deleting product from locality
  const handelConformDelete = () => {
    // dispatch(DeleteProductFromLocality(deleteProduct));
    setConfirmDialog(false);
  };

  //useEffect for hitting api
  // React.useEffect(() => {
  //   if (!skuPincodeDetails.deleteProductError) {
  //     handelClose();
  //   }
  // }, [skuPincodeDetails.deleteProductError]);

  console.log(confirmDialog);
  return (
    <Box>
      <Dialog open={openPincodeDialog} onClose={handelClose}>
        <DialogContent>
          <DialogTitle>Add/Update Pincode</DialogTitle>
          <Card>
            <CardContent>
              {!updateFlag ? (
                <>
                  <Stack spacing={2}>
                    <Grid container spacing={2}>
                      <Grid item sm={4} xs={12}>
                        <TextField
                          type="number"
                          label="Pincode*"
                          name="pincode"
                          size="small"
                          value={pincodeData.pincode}
                          inputProps={{
                            maxLength: 6,
                          }}
                          onChange={handelChangePicodeData}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <TextField
                          type="text"
                          label="District*"
                          name="district"
                          size="small"
                          value={pincodeData.district}
                          onChange={handelChangePicodeData}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <TextField
                          type="text"
                          name="state"
                          label="State*"
                          size="small"
                          value={pincodeData.state}
                          onChange={handelChangePicodeData}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <TextField
                          type="text"
                          label="Country*"
                          name="country"
                          size="small"
                          value={pincodeData.country}
                          onChange={handelChangePicodeData}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <TextField
                          type="text"
                          id="outlined-read-only-input"
                          name="country_code"
                          label="Country_code*"
                          size="small"
                          value={pincodeData.country_code}
                          onChange={handelChangePicodeData}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid
                        container
                        sm={12}
                        xs={12}
                        justifyContent="center"
                        alignItems="center"
                        sx={{ marginTop: "1rem" }}
                      >
                        <TextField
                          type="text"
                          label="Service Area*"
                          name="locality"
                          size="small"
                          value={serviceArea}
                          onChange={(e) => setServiceArea(e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button
                                  variant="contained"
                                  size="small"
                                  onClick={handleAddServiceArea}
                                  sx={appStyle.general.button.blueButton}
                                >
                                  Add
                                </Button>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                  <Grid container spacing={2} sx={{ marginTop: ".5rem" }}>
                    {pincodeData.locality.map((val, idx) => (
                      <Grid item key={idx}>
                        <Box
                          sx={{
                            padding: "5px",
                            // backgroundColor: "#C9EEFF",
                            display: "flex",
                            borderRadius: "10px",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: 600,
                              fontFamily: "sans-serif",
                            }}
                          >
                            {val.length > 15 ? val.slice(0, 15) + "...." : val}
                          </Typography>
                          <CloseIcon
                            sx={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              removeAreaFrArr(idx);
                            }}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </>
              ) : (
                <Typography>LOCALITY:{updatePincodeData?.locality}</Typography>
              )}
              <Grid
                container
                spacing={2}
                sx={{ marginTop: ".5rem" }}
                justifyContent="center"
                alignItems="center"
              >
                <FormControl sx={{ m: 1, minWidth: 230 }} size="small">
                  <InputLabel id="demo-select-small">Product*</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={productList.name}
                    label="Capacity"
                    onChange={handleChangeSelectedProduct}
                  >
                    {allProducts.map((data) => (
                      <MenuItem
                        value={data?.product_details?.name}
                        key={data?.product_details?.id}
                      >
                        <div>
                          <span>
                            {" "}
                            <strong>{data?.product_details?.name}</strong>
                          </span>
                          <br />
                          <small>
                            Capacity:
                            {data?.product_details?.capacity}/
                          </small>
                          <small>
                            Material:
                            {data?.product_details?.material}
                          </small>
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  onClick={handelAddProduct}
                  variant="contained"
                  size="small"
                  sx={appStyle.general.button.blueButton}
                >
                  Add
                </Button>
              </Grid>

              <Grid container spacing={2} sx={{ marginTop: ".5rem" }}>
                {pincodeData.products.map((val, idx) => (
                  <Grid item xs={12} key={idx}>
                    <Material.Grid item md={12} sm={12} xs={12}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Material.FormControlLabel
                          control={<Radio />}
                          checked={val.booking_type == 1}
                          value={val.booking_type}
                          label="Refill"
                          onChange={() =>
                            handleProductBookingTypeChange(
                              1,
                              idx,
                              "booking_type"
                            )
                          }
                        />
                        <Material.FormControlLabel
                          control={<Radio />}
                          checked={val.booking_type == 2}
                          value={val.booking_type}
                          label="One time sale"
                          onChange={() =>
                            handleProductBookingTypeChange(
                              2,
                              idx,
                              "booking_type"
                            )
                          }
                        />
                        <Material.FormControlLabel
                          control={<Radio />}
                          checked={val.booking_type == 3}
                          value={val.booking_type}
                          label="Both"
                          onChange={() =>
                            handleProductBookingTypeChange(
                              3,
                              idx,
                              "booking_type"
                            )
                          }
                        />
                        <>
                          <DeleteIcon
                            sx={{ color: "red" }}
                            onClick={() => handelDeleteProduct(val, idx)}
                          />
                        </>
                      </Box>
                    </Material.Grid>
                    <Box
                      sx={{
                        border: ".5px solid gray",
                        borderRadius: "10px",
                        padding: ".5rem",
                      }}
                    >
                      {val.booking_type == 2 || val.booking_type == 3 ? (
                        <>
                          <Typography>
                            {val.name}
                            {val.name !== undefined ? "/" : null} {val.capacity}
                            {val.material !== undefined ? "/" : null}
                            {val.material}/One Time Sale
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              gap: 1,
                              marginTop: "1rem",
                            }}
                          >
                            <TextField
                              type="number"
                              label="Deposit"
                              name="deposit"
                              size="small"
                              value={val.one_time_sale.deposit}
                              onChange={(e) =>
                                handleProductDetailsChange(
                                  e,
                                  idx,
                                  "one_time_sale",
                                  "deposit"
                                )
                              }
                            />
                            <TextField
                              type="number"
                              label="MRP"
                              name="mrp"
                              size="small"
                              onChange={(e) =>
                                handleProductDetailsChange(
                                  e,
                                  idx,
                                  "one_time_sale",
                                  "mrp"
                                )
                              }
                              value={val.one_time_sale.mrp}
                            />
                            <Material.TextField
                              id="outlined-select-currency-native"
                              select
                              sx={{
                                width: { sm: "70%", xs: "100%" },
                              }}
                              size="small"
                              label="Refund Type"
                              defaultValue={val.one_time_sale.refund_type}
                              SelectProps={{
                                native: true,
                              }}
                              onChange={(e) =>
                                handleProductDetailsChange(
                                  e,
                                  idx,
                                  "one_time_sale",
                                  "refund_type"
                                )
                              }
                            >
                              {refundTypes.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </Material.TextField>
                            <TextField
                              type="number"
                              label="Refund Amount"
                              name="refund_amount"
                              size="small"
                              onChange={(e) =>
                                handleProductDetailsChange(
                                  e,
                                  idx,
                                  "one_time_sale",
                                  "refund_amount"
                                )
                              }
                              value={val.one_time_sale.refund_amount}
                            />
                            <TextField
                              type="text"
                              label="Special Price"
                              name="sp"
                              size="small"
                              value={val.one_time_sale.sp}
                              onChange={(e) =>
                                handleProductDetailsChange(
                                  e,
                                  idx,
                                  "one_time_sale",
                                  "sp"
                                )
                              }
                            />
                          </Box>
                        </>
                      ) : null}
                      {val.booking_type == 1 || val.booking_type == 3 ? (
                        <>
                          <Typography>
                            {" "}
                            {val.name}
                            {val.name !== undefined ? "/" : null} {val.capacity}
                            {val.material !== undefined ? "/" : null}
                            {val.material}/Refill Sale
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              gap: 1,

                              marginTop: "1rem",
                            }}
                          >
                            <TextField
                              type="number"
                              label="Deposit"
                              name="deposit"
                              size="small"
                              value={val.refill.deposit}
                              onChange={(e) =>
                                handleProductDetailsChange(
                                  e,
                                  idx,
                                  "refill",
                                  "deposit"
                                )
                              }
                            />
                            <TextField
                              type="number"
                              label="MRP"
                              name="mrp"
                              size="small"
                              value={val.refill.mrp}
                              onChange={(e) =>
                                handleProductDetailsChange(
                                  e,
                                  idx,
                                  "refill",
                                  "mrp"
                                )
                              }
                            />
                            <Material.TextField
                              id="outlined-select-currency-native"
                              select
                              sx={{
                                width: { sm: "70%", xs: "100%" },
                              }}
                              size="small"
                              label="Refund Type"
                              defaultValue={val.refill.refund_type}
                              SelectProps={{
                                native: true,
                              }}
                              onChange={(e) =>
                                handleProductDetailsChange(
                                  e,
                                  idx,
                                  "refill",
                                  "refund_type"
                                )
                              }
                            >
                              {refundTypes.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </Material.TextField>
                            <TextField
                              type="number"
                              label="Refund Amount"
                              name="refund_amount"
                              size="small"
                              value={val.refill.refund_amount}
                              onChange={(e) =>
                                handleProductDetailsChange(
                                  e,
                                  idx,
                                  "refill",
                                  "refund_amount"
                                )
                              }
                            />
                            <TextField
                              type="text"
                              label="Special Price"
                              name="country"
                              size="small"
                              value={val.refill.sp}
                              onChange={(e) =>
                                handleProductDetailsChange(
                                  e,
                                  idx,
                                  "refill",
                                  "sp"
                                )
                              }
                            />
                          </Box>
                        </>
                      ) : null}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
            {/* <ConformationDialog
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
              dialogMsg="Are you sure to delete Product from Locality"
              handelProceed={handelConformDelete}
            /> */}
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={handelClose} sx={appStyle.general.button.redButton}>
            Close
          </Button>
          <Button
            disabled={errors}
            onClick={handelUpdateData}
            variant="contained"
            size="small"
            sx={appStyle.general.button.blueButton}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AddUpdatePicodeAreaSku;
