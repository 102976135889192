import axios from "axios";
// import useAxios from "../../Hooks/useAxios";
import useAxios from "../../Hooks/useAxios";
import { Dispatch } from "redux";
import { toast } from "react-toastify";
import actionType from "../actionTypes";

//function for adding product qty
export const GetProductsByOrgId = (orgId) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(
        `POPM/products/organization?org_id=${orgId}`
      );
      if (response?.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_PRODUCTS_ORG,
          payload: response?.data,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};

export const CreateOrderOrg = (reqObj) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`POPM/products/booknow`, reqObj);
      if (response.status === httpStatus.SUCCESS) {
        dispatch({
          type: actionType.CREATE_ORG_ORDER,
          payload: response.data,
        });
        toast.success(response.data.msg, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
};

export const AddProductQuantity = (productId, bookingType) => ({
  type: actionType.ADD_SKU_PRODUCT_QTY,
  payload: { data: productId, statusFlag: false, bookingType: bookingType },
});
//function for redusing product qty
export const ReduceProductQuantity = (productId, bookingType) => ({
  type: actionType.REDUCE_SKU_PRODUCT_QTY,
  payload: { data: productId, statusFlag: false, bookingType: bookingType },
});

export const ReduceReturnQuantity = (productId, bookingType) => ({
  type: actionType.REDUCE_RETURN_PRODUCT_QTY,
  payload: { data: productId, statusFlag: false, bookingType: bookingType },
});

//function for adding Return qty
export const AddReturnQuantity = (productId, bookingType) => ({
  type: actionType.ADD_RETURN_PRODUCT_QTY,
  payload: { data: productId, statusFlag: false, bookingType: bookingType },
});

//function for storing product details Return qty
export const DeleteProductaction = (product) => ({
  type: actionType.DELETE_SKU_PRODUCT_QTY,
  payload: { data: product, statusFlag: false },
});

//function for storing product details Return qty

export const SelectedProductaction = (product) => ({
  type: actionType.SELECTED_PRODUCT_ACTION,
  payload: { data: product, statusFlag: false },
});
