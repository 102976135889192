import { Box, Button, Tab, Tabs, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import AllProductsTable from "../Orders/PurchaseProduct/AllProductsTable";
import { useDispatch, useSelector } from "react-redux";
import ManufacturerTable from "../Products/ManufacturerTable";
import ProductPurchase from "./ProductPurchase";
import PurchaseProductStepper from "./PurchaseProductStepper";
import { GetManuFacturer } from "../../Redux/Actions/ManufacturersAction";
import SKUManagementApp from "./SkuDemo";
import {
  GetComponents,
  GetProductSku,
  GetRawMaterials,
} from "../../Redux/Actions/ProductComponentsAction";
import PurchaseSKU from "./PurchaseSKU";
import { GetSkuStocks } from "../../Redux/Actions/purchaseProductsAction";
import { tokens } from "../../AppMeta/theme";
import { GetFinalProductSKU } from "../../Redux/Actions/productsAction";
import appStyle from "../../AppMeta/appStyle";
import App from "../../App";
import appFonts from "../../AppMeta/appFonts";
import DisplayTitle from "../../Components/CustomComp/DisplayTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CustomSelect from "../../Components/CustomComp/CustomSelect";
import appData from "../../AppMeta/appData";
import AddIcon from "@mui/icons-material/Add";
import FactoryIcon from "@mui/icons-material/Factory";

// function CustomTabLabel({ label, ...props }) {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   return (
//     <Typography color={colors.primary[400]} {...props}>
//       {label}
//     </Typography>
//   );
// }

function Sku() {
  const productDetails = useSelector((state) => state.productComponentsData);
  const productData = useSelector((state) => state.productData);
  const pincodesAndAreasData = useSelector(
    (state) => state.pincodesAndAreasData
  );
  const purchaseProductDetails = useSelector(
    (state) => state.purchaseProductData
  );
  const manufacturerDetails = useSelector((state) => state.manufacturerData);
  const cratesData = useSelector((state) => state.cratesData);
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [subTabValue, setSubTabValue] = useState(0);
  const [productTypeValue, setProductTypeValue] = useState(0);

  const [pageNumber, setPageNumber] = React.useState(1);
  const [lastPageNumber, setLastPageNumber] = React.useState(null);
  const [pageSize, setPageSize] = React.useState(25);
  const [createdAtSort, setCreatedAtSort] = React.useState("ASC");
  const [available, setAvailable] = React.useState(true);
  const [allProducts, setAllProducts] = React.useState([]);

  //   add products variables
  const [productType, setProductType] = React.useState("");
  const [productName, setProductName] = React.useState("");
  const [productPrice, setProductPrice] = React.useState("");
  const [lifeSpan, setLifeSpan] = React.useState("");
  const [productMaterial, setProductMaterial] = React.useState("");
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState("");

  // manufacturer data
  const [manufacturersList, setManufacturersList] = React.useState([]);
  const [allRawMaterials, setAllRawMaterials] = React.useState([]);
  const [allFinishedGoods, setAllFinishedGoods] = React.useState();

  const [rawMaterialsStock, setRawMaterialsStock] = React.useState([]);
  const [finishedGoodStock, setFinishedGoodStock] = React.useState([]);
  const [finalProductStock, setFinalProductStock] = React.useState([]);

  const [allCrates, setAllCrates] = React.useState([]);
  const [allPincodesAndAreas, setAllPincodesAndAreas] = React.useState([]);

  const [drawOpenProp, setDrawOpenProps] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  React.useEffect(() => {
    dispatch(GetSkuStocks());
  }, []);

  React.useEffect(() => {
    console.log("pincodesAndAreasData");
    console.log(pincodesAndAreasData);
    setAllPincodesAndAreas(pincodesAndAreasData.pincodesAndAreas);
  }, [pincodesAndAreasData]);

  React.useEffect(() => {
    console.log("cratesData");
    console.log(cratesData);
    setAllCrates(cratesData.crates);
  }, [cratesData]);

  React.useEffect(() => {
    console.log("productData");
    console.log(productData);
    setAllProducts(productData?.finalProduct);
  }, [productData]);

  React.useEffect(() => {
    console.log("Product details");
    console.log(productDetails);
    if (productDetails.error === false) {
      // setAllProducts(productDetails?.products);
      setAllRawMaterials(productDetails?.rawMaterial);
      setAllFinishedGoods(productDetails?.components);
    }
  }, [productDetails]);

  React.useEffect(() => {
    console.log("purchaseProductDetails");
    console.log(purchaseProductDetails);
    purchaseProductDetails?.skuStock?.map((stocks) => {
      if (stocks.product_type === "100") {
        setRawMaterialsStock((prev) => [...prev, stocks]);
      } else if (stocks.productType === "200") {
        setFinishedGoodStock((prev) => [...prev, stocks]);
      } else if (stocks.product_type === "300") {
        setFinalProductStock((prev) => [...prev, stocks]);
      }
    });
  }, [purchaseProductDetails]);

  React.useEffect(() => {
    console.log("rawMaterialsStock");
    console.log(rawMaterialsStock);
    console.log("finishedGoodStock");
    console.log(finishedGoodStock);
    console.log("finalProductStock");
    console.log(finalProductStock);
  }, [rawMaterialsStock, finalProductStock, finishedGoodStock]);

  React.useEffect(() => {
    console.log("manufacturerDetails");
    console.log(manufacturerDetails);
    if (manufacturerDetails.error === false) {
      setManufacturersList(manufacturerDetails?.manufacturers?.data);
    }
  }, [manufacturerDetails]);

  React.useEffect(() => {
    dispatch(GetManuFacturer(pageNumber, pageSize, available, createdAtSort));
    dispatch(GetRawMaterials(pageNumber, pageSize, available, createdAtSort));
    dispatch(
      GetFinalProductSKU(pageNumber, pageSize, available, createdAtSort)
    );
    // dispatch(GetProductSku(pageNumber, pageSize, available, createdAtSort));
  }, [pageNumber, pageSize, available, createdAtSort]);

  const handleMainSelectorChange = (event) => {
    setValue(event.target.value);
    setDrawOpenProps(false);
  };

  const handleSubSelectorChange = (event) => {
    setSubTabValue(event.target.value);
  };

  const handleDrawerOpen = () => {
    setDrawOpenProps(!drawOpenProp);
  };

  const handleProductTypeChange = (event) => {
    setProductTypeValue(event.target.value);
  };

  return (
    <Box width="100%">
      {/* <Header /> */}

      <Box
        sx={
          //   {
          //   display: "flex",
          //   alignItems: "center",
          //   justifyContent: "center",
          //   marginTop: "1rem",
          //   gap: "1rem",
          // }
          appStyle.box
        }
      >
        <Box>
          <CustomSelect
            name={"Stock keeping unit"}
            input={appData.skuMainSelectData}
            handleChange={handleMainSelectorChange}
            defaultValue={value}
          />
        </Box>
        <Box>
          {/* {value !== 2 && ( */}
          <CustomSelect
            name={
              value === 0
                ? "SKU"
                : value === 1
                ? "Manufacturers/Suppliers"
                : "Order products"
            }
            input={
              value === 0
                ? appData.skuSubSelectData
                : value === 1
                ? appData.supplierManufacturer
                : appData.skuOrderSubSelect
            }
            handleChange={handleSubSelectorChange}
            defaultValue={subTabValue}
          />
          {/* )} */}
        </Box>

        <Box sx={appStyle.general.button.addButtonBox}>
          {value === 0 ? (
            <Button
              onClick={handleDrawerOpen}
              variant="contained"
              color="secondary"
              sx={appStyle.general.button.addButton}
              endIcon={<AddIcon />}
              size="small"
            >
              Add{" "}
              {subTabValue === 0
                ? "Raw Material"
                : subTabValue === 1
                ? "Finished Good"
                : subTabValue === 2
                ? "Final Product"
                : subTabValue === 3
                ? "Crates"
                : " pincodes and areas"}
            </Button>
          ) : (
            value === 1 && (
              <Button
                onClick={handleDrawerOpen}
                variant="contained"
                color="secondary"
                sx={appStyle.general.button.addButton}
                startIcon={<FactoryIcon />}
                size="small"
              >
                Add Manufacturer
              </Button>
            )
          )}
          {value === 2 && subTabValue === 0 && (
            <CustomSelect
              name={"product type"}
              input={appData.skuOrderPurchaseProductSubSelect}
              defaultValue={productTypeValue}
              handleChange={handleProductTypeChange}
            />
          )}
        </Box>
      </Box>
      {value === 0 && (
        <>
          <SKUManagementApp
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPageNumber={lastPageNumber}
            createdAtSort={createdAtSort}
            available={available}
            setAvailable={setAvailable}
            setCreatedAtSort={setCreatedAtSort}
            allRawMaterials={allRawMaterials}
            allFinishedGoods={allFinishedGoods}
            allProducts={allProducts}
            productType={productType}
            productName={productName}
            productPrice={productPrice}
            lifeSpan={lifeSpan}
            productMaterial={productMaterial}
            setProductType={setProductType}
            setProductName={setProductName}
            setProductPrice={setProductPrice}
            setLifeSpan={setLifeSpan}
            setProductMaterial={setProductMaterial}
            setIsConfirmed={setIsConfirmed}
            isConfirmed={isConfirmed}
            setSelectedProduct={setSelectedProduct}
            allCrates={allCrates}
            allPincodesAndAreas={allPincodesAndAreas}
            tabProps={subTabValue}
            drawOpenProp={drawOpenProp}
            setDrawOpenProp={setDrawOpenProps}
          />
        </>
      )}

      {value === 1 && (
        <>
          <ManufacturerTable
            manufacturersList={manufacturersList}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPageNumber={lastPageNumber}
            createdAtSort={createdAtSort}
            available={available}
            setAvailable={setAvailable}
            setCreatedAtSort={setCreatedAtSort}
            tabProps={subTabValue}
            drawOpenProp={drawOpenProp}
            setDrawOpenProp={setDrawOpenProps}
          />
        </>
      )}

      {value === 2 && (
        <>
          <PurchaseProductStepper
            allProducts={allProducts}
            manufacturersList={manufacturersList}
            allRawMaterials={allRawMaterials}
            allFinishedGoods={allFinishedGoods}
            rawMaterialsStock={rawMaterialsStock}
            subTabValue={subTabValue}
            setSubTabValue={setSubTabValue}
            productTypeValue={productTypeValue}
            setProductTypeValue={setProductTypeValue}
          />
          {/* <Box
            sx={{ 
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PurchaseSKU
              manufacturersList={manufacturersList}
              allProducts={allProducts}
              allRawMaterials={allRawMaterials}
              allFinishedGoods={allFinishedGoods}
            />
          </Box> */}
        </>
      )}
    </Box>
  );
}

export default Sku;

// const Header = () => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   return (
//     <Box>
//       <DisplayTitle>Stock keeping unit</DisplayTitle>
//     </Box>
//   );
// };
