import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Select,
  MenuItem,
  IconButton,
  TextField,
  Checkbox,
  Button,
  useTheme,
  TablePagination,
  FormControl,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useSelector } from "react-redux";
import { appColors } from "../../AppMeta/appColors";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { tokens } from "../../AppMeta/theme";
import appFonts from "../../AppMeta/appFonts";
import appStyle from "../../AppMeta/appStyle";
import CustomFilter from "../../Components/CustomComp/CustomFilter";

const dummyData = [
  {
    componentName: "Component A",
    materialName: "Material 1",
    area: "Area 1",
    manufacturer: "Manufacturer 1",
  },
  {
    componentName: "Component B",
    materialName: "Material 2",
    area: "Area 2",
    manufacturer: "Manufacturer 2",
  },
  {
    componentName: "Component C",
    materialName: "Material 3",
    area: "Area 1",
    manufacturer: "Manufacturer 1",
  },
  {
    componentName: "Component D",
    materialName: "Material 1",
    area: "Area 3",
    manufacturer: "Manufacturer 3",
  },
  {
    componentName: "Component E",
    materialName: "Material 2",
    area: "Area 2",
    manufacturer: "Manufacturer 2",
  },
];

const FinishedGoodsList = ({
  manufacturersList,
  selectedProduct,
  activeStep,
  hitValidateApi,
  setSelectedData,
  selectedData,
  allFinishedGoods,
}) => {
  const productDetails = useSelector((state) => state.productComponentsData);
  const purchaseProductDetails = useSelector(
    (state) => state.purchaseProductData
  );

  React.useEffect(() => {
    console.log("purchaseProductDetails");
    console.log(purchaseProductDetails);
  }, [purchaseProductDetails]);
  const [areas, setAreas] = useState(["Area 1", "Area 2", "Area 3"]);
  const [manufacturers, setManufacturers] = useState([
    "Manufacturer 1",
    "Manufacturer 2",
    "Manufacturer 3",
  ]);

  const [componentsForProducts, setComponentsForProducts] = React.useState([]);

  // const [quantities, setQuantities] = useState(
  //   Array(componentsForProducts?.length).fill("0")
  // );
  const [quantities, setQuantities] = useState(
    Array(allFinishedGoods?.length).fill("0")
  );

  const [prices, setPrices] = useState(
    Array(allFinishedGoods?.length).fill("0")
  );

  const [selectedRows, setSelectedRows] = useState([]);
  // const [selectedData, setSelectedData] = useState([]);

  React.useEffect(() => {
    console.log("productDetails");
    console.log(productDetails);
    // console.log(productDetails?.componentsForProduct?.data?.components);

    if (productDetails.error === false && selectedProduct !== "") {
      console.log(productDetails.componentsForProduct.data);

      // setComponentsForProducts(productDetails.componentsForProduct.data);
      const componentsArray =
        productDetails?.componentsForProduct?.data?.reduce(
          (componentsArray, product) => {
            if (product.components && Array.isArray(product.components)) {
              componentsArray.push(...product.components);
            }
            return componentsArray;
          },
          []
        );
      setComponentsForProducts(componentsArray);
    }
  }, [productDetails]);

  React.useEffect(() => {
    console.log("allFinishedGoods");
    console.log(allFinishedGoods);
  }, [allFinishedGoods]);
  const handleRowSelect = (index) => {
    const newSelectedRows = [...selectedRows];
    if (newSelectedRows.includes(index)) {
      newSelectedRows.splice(newSelectedRows.indexOf(index), 1);
    } else {
      newSelectedRows.push(index);
    }
    setSelectedRows(newSelectedRows);
  };

  const handleAreaChange = (event, index) => {
    const updatedData = [...componentsForProducts];
    updatedData[index].area = event.target.value;
    setAreas([...new Set([...areas, event.target.value])]);
  };

  const handleManufacturerChange = (event, index) => {
    const updatedData = [...allFinishedGoods];
    console.log(updatedData);
    updatedData[index].manufacturer = event.target.value;
    setManufacturers([...new Set([...manufacturers, event.target.value])]);
  };

  const handleQuantityChange = (event, index) => {
    const updatedQuantities = [...quantities];
    updatedQuantities[index] = event.target.value;
    setQuantities(updatedQuantities);
  };

  const handlePriceChange = (event, index) => {
    const updatedPrices = [...prices];
    updatedPrices[index] = event.target.value;
    setPrices(updatedPrices);
  };

  const handleQuantityIncrement = (index) => {
    const updatedQuantities = [...quantities];
    updatedQuantities[index] = String(
      parseInt(updatedQuantities[index], 10) + 1
    );
    setQuantities(updatedQuantities);
  };

  const handleQuantityDecrement = (index) => {
    const updatedQuantities = [...quantities];
    if (parseInt(updatedQuantities[index], 10) > 0) {
      updatedQuantities[index] = String(
        parseInt(updatedQuantities[index], 10) - 1
      );
      setQuantities(updatedQuantities);
    }
  };

  React.useEffect(() => {
    console.log("hitValidateApi");
    console.log(hitValidateApi);
  }, [hitValidateApi]);

  // const handleAddToCart = () => {
  //   const selectedDataItems = selectedRows.map((index) => {
  //     return {
  //       ...componentsForProducts[index],
  //       // id: index,
  //       quantity: quantities[index],
  //       price: prices[index],
  //     };
  //   });

  //   const groupedData = groupByManufacturer(selectedDataItems);
  //   // Do something with the grouped data, like adding it to the cart
  //   console.log(groupedData);
  //   setSelectedData(groupedData);
  // };

  const handleAddToCart = () => {
    console.log(selectedRows);
    const selectedDataItems = selectedRows.map((index) => {
      return {
        id: allFinishedGoods[index].id,
        mft_id: allFinishedGoods[index].manufacturer, // Assuming manufacturer id is used as mft_id
        quantity: quantities[index],
        price: prices[index],
      };
    });

    console.log(selectedDataItems);
    // const groupedData = groupByProduct(selectedDataItems);
    const groupedData = groupByManufacturerId(selectedDataItems);
    // Do something with the grouped data, like adding it to the cart
    console.log(groupedData);
    setSelectedData(groupedData);
  };

  // React.useEffect(() => {
  //   console.log("selectedData");
  //   console.log(selectedData);
  // }, [selectedData]);

  // const groupByManufacturer = (data) => {
  //   return data.reduce((result, item) => {
  //     const manufacturer = item.manufacturer;
  //     if (!result[manufacturer]) {
  //       result[manufacturer] = [];
  //     }
  //     result[manufacturer].push(item);
  //     return result;
  //   }, {});
  // };

  // const groupByProduct = (data) => {
  //   return data.reduce((result, item) => {
  //     const productID = selectedProduct;
  //     const existingOrder = result.find(
  //       (order) => order.product_id === productID
  //     );

  //     if (existingOrder) {
  //       existingOrder.orders.push({
  //         mft_id: item.mft_id,
  //         components: [
  //           {
  //             id: item.id,
  //             quantity: item.quantity,
  //             price: item.price,
  //           },
  //         ],
  //       });
  //     } else {
  //       result.push({
  //         product_id: productID,
  //         orders: [
  //           {
  //             mft_id: item.mft_id,
  //             components: [
  //               {
  //                 id: item.id,
  //                 quantity: item.quantity,
  //                 price: item.price,
  //               },
  //             ],
  //           },
  //         ],
  //       });
  //     }

  //     return result;
  //   }, []);
  // };

  function groupByManufacturerId(inputArray) {
    const groupedByMftId = inputArray.reduce((accumulator, current) => {
      const mftId = current.mft_id;

      if (!accumulator[mftId]) {
        accumulator[mftId] = {
          mft_id: parseInt(mftId, 10),
          product_details: [],
        };
      }

      accumulator[mftId].product_details.push({
        id: parseInt(current.id, 10),
        quantity: parseInt(current.quantity, 10),
        price: parseFloat(current.price),
      });

      return accumulator;
    }, {});

    return Object.values(groupedByMftId);
  }
  const groupByManufacturer1 = (data) => {};

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [getOrders, setGetOrders] = React.useState([]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeOrders = () => {
    setGetOrders(allFinishedGoods);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getOrders.length) : 0;

  return (
    <Box>
      <Box sx={appStyle.general.table.containerForTable}>
        <CustomFilter />
        <TableContainer
          // component={Paper}
          sx={appStyle.general.table.tableContainer}
        >
          <Table stick>
            <TableHead
              sx={{
                backgroundColor:
                  appStyle.general.colors(colors).table.tableHeadBackground,
                color: colors.primary[300],
                boxShadow: 2,
              }}
            >
              <TableRow>
                <TableCell sx={appStyle.general.table.headderRowCell}>
                  Select
                </TableCell>
                <TableCell sx={appStyle.general.table.headderRowCell}>
                  {" "}
                  Finished Good Name
                </TableCell>
                <TableCell sx={appStyle.general.table.headderRowCell}>
                  Current Stock
                </TableCell>
                {/* <TableCell>Units</TableCell> */}
                <TableCell sx={appStyle.general.table.headderRowCell}>
                  Required Quantity
                </TableCell>
                <TableCell sx={appStyle.general.table.headderRowCell}>
                  Price / Quantity
                </TableCell>
                {/* <TableCell>Area</TableCell> */}
                <TableCell sx={appStyle.general.table.headderRowCell}>
                  Manufacturer
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: colors.primary[600] }}>
              <>
                {allFinishedGoods.length && (
                  <>
                    {" "}
                    {(rowsPerPage > 0
                      ? allFinishedGoods.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : allFinishedGoods
                    ).map((row, index) => (
                      <TableRow onChange={handleChangeOrders} key={index}>
                        <TableCell sx={appStyle.general.table.bodyRowCell}>
                          <Checkbox
                            checked={selectedRows.includes(index)}
                            onChange={() => handleRowSelect(index)}
                          />
                        </TableCell>
                        <TableCell sx={appStyle.general.table.bodyRowCell}>
                          {row.name}
                        </TableCell>
                        <TableCell sx={appStyle.general.table.bodyRowCell}>
                          0
                        </TableCell>
                        {/* <TableCell>{row.units}</TableCell> */}
                        <TableCell sx={appStyle.general.table.bodyRowCell}>
                          {/* <IconButton
                          color="primary"
                          onClick={() => handleQuantityDecrement(index)}
                        >
                          <RemoveIcon />
                        </IconButton> */}
                          <TextField
                            size="small"
                            type="number"
                            value={quantities[index]}
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(event) =>
                              handleQuantityChange(event, index)
                            }
                          />
                          {/* <IconButton
                          color="primary"
                          onClick={() => handleQuantityIncrement(index)}
                        >
                          <AddIcon />
                        </IconButton> */}
                        </TableCell>
                        <TableCell sx={appStyle.general.table.bodyRowCell}>
                          <TextField
                            type="number"
                            size="small"
                            value={prices[index]}
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(event) =>
                              handlePriceChange(event, index)
                            }
                          />
                        </TableCell>
                        {/* <TableCell>
                  <Select
                    value={row.area}
                    onChange={(event) => handleAreaChange(event, index)}
                  >
                    {areas.map((area) => (
                      <MenuItem key={area} value={area}>
                        {area}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell> */}
                        <TableCell sx={appStyle.general.table.bodyRowCell}>
                          <FormControl fullWidth variant="standard">
                            <Select
                              value={row.manufacturer}
                              onChange={(event) =>
                                handleManufacturerChange(event, index)
                              }
                            >
                              {manufacturersList.map((manufacturer) => (
                                <MenuItem
                                  key={manufacturer.id}
                                  value={manufacturer.id}
                                >
                                  {manufacturer.details.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </>
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={appStyle.general.table.pagination}>
          <TablePagination
            rowsPerPageOptions={[25, 100, 500, 1000]}
            component="div"
            count={allFinishedGoods.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
      <Box
        mt={2}
        sx={{
          padding: "10px",
          position: "fixed",
          right: "1rem",
          bottom: "4rem",
          zIndex: 2000,
          ...appStyle.general.button.addButtonBox,
        }}
        display="flex"
        justifyContent="flex-end"
      >
        <Button
          sx={appStyle.general.button.blueButton}
          startIcon={<ShoppingCartIcon />}
          size="large"
          variant="contained"
          color="secondary"
          onClick={handleAddToCart}
        >
          Add to Cart
        </Button>
      </Box>
      {/* <Box mt={2}>
        <Typography variant="h6">Selected Data</Typography>
        <pre>{JSON.stringify(selectedData, null, 2)}</pre>
      </Box> */}
    </Box>
  );
};

export default FinishedGoodsList;
