import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
  Box,
  Typography,
  TableContainer,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
  useTheme,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { appColors } from "../../AppMeta/appColors";
import AddUpdatePicodeAreaSku from "./AddUpdatePicodeSku";
import CustomConfirmationDialog from "../../Components/CustomComp/CustomConfirmationDialog";
import { useDispatch } from "react-redux";
import { RemoveLocality } from "../../Redux/Actions/pincodeAreasAction";
import { tokens } from "../../AppMeta/theme";
import appFonts from "../../AppMeta/appFonts";
import appStyle from "../../AppMeta/appStyle";

const rows = [
  {
    id: 69257,
    pincode: "603103",
    locality: "KELAMBAKAM",
    district: "CHENNAI",
    state_name: "TAMIL NADU",
    is_deleted: false,
    created_at: "2024-01-24T08:24:55.000Z",
    country_code: "+91",
    country: "INDIA",
    created_by: "",
    products: [],
    updated_at: "2024-02-07T05:53:41.000Z",
    updated_by: "diEc2Wi8O",
    created_by_user: {
      fullname: null,
      phone: null,
    },
    updated_by_user: {
      fullname: "dinesh",
      phone: "9345945085",
    },
  },
  {
    id: 69276,
    pincode: "603103",
    locality: "RAMAPURAM",
    district: "CHENNAI",
    state_name: "TAMIL NADU",
    is_deleted: false,
    created_at: "2024-01-29T10:18:35.000Z",
    country_code: "+91",
    country: "INDIA",
    created_by: "Th3HxTlYA",
    products: [],
    updated_at: "2024-01-29T13:06:11.000Z",
    updated_by: "Th3HxTlYA",
    created_by_user: {
      fullname: "Noor super admin",
      phone: "9146939602",
    },
    updated_by_user: {
      fullname: "Noor super admin",
      phone: "9146939602",
    },
  },
  {
    id: 69278,
    pincode: "603103",
    locality: "ASHOK NAGAR",
    district: "CHENNAI",
    state_name: "TAMIL NADU",
    is_deleted: false,
    created_at: "2024-01-29T14:28:03.000Z",
    country_code: "+91",
    country: "INDIA",
    created_by: "Th3HxTlYA",
    products: [
      {
        product_id: 902,
        booking_type: 3,
        refill: {
          mrp: 100,
          sp: 50,
          deposit: 10,
          refund_type: 100,
          refund_amount: 12,
        },
        one_time_sale: {
          mrp: 150,
          sp: 60,
          deposit: 30,
          refund_type: 100,
          refund_amount: 30,
        },
        material: "PETCONTAINER",
        capacity: "20liters",
      },
      {
        product_id: 910,
        booking_type: 3,
        refill: {
          mrp: 100,
          sp: 17,
          deposit: 10,
          refund_type: 100,
          refund_amount: 10,
        },
        one_time_sale: {
          mrp: 100,
          sp: 81,
          deposit: 20,
          refund_type: 100,
          refund_amount: 20,
        },
        material: "PLASTIC",
        capacity: "20-liters",
      },
      {
        product_id: 887,
        booking_type: 1,
        refill: {
          mrp: 100,
          sp: 10,
          deposit: 400,
          refund_type: 100,
          refund_amount: 33,
        },
        one_time_sale: {
          mrp: 13,
          sp: 1,
          deposit: 133,
          refund_type: 100,
          refund_amount: 20,
        },
        material: "DEWDFED",
        capacity: "20 litwe",
      },
      {
        product_id: 864,
        booking_type: 1,
        refill: {
          mrp: 75,
          sp: 55,
          deposit: 200,
          refund_type: 100,
          refund_amount: 200,
        },
        one_time_sale: {
          mrp: 30,
          sp: 20,
          deposit: 30,
          refund_type: 100,
          refund_amount: 20,
        },
        material: "GLASS",
        capacity: "750ml",
      },
    ],
    updated_at: "2024-02-01T10:27:08.000Z",
    updated_by: "diEc2Wi8O",
    created_by_user: {
      fullname: "Noor super admin",
      phone: "9146939602",
    },
    updated_by_user: {
      fullname: "dinesh",
      phone: "9345945085",
    },
  },
  {
    id: 69283,
    pincode: "603103",
    locality: "CHINDATHRIPET",
    district: "CHENNAI",
    state_name: "TAMIL NADU",
    is_deleted: false,
    created_at: "2024-01-29T14:28:03.000Z",
    country_code: "+91",
    country: "INDIA",
    created_by: "Th3HxTlYA",
    products: [
      {
        product_id: 902,
        booking_type: 3,
        refill: {
          mrp: 100,
          sp: 50,
          deposit: 10,
          refund_type: 100,
          refund_amount: 12,
        },
        one_time_sale: {
          mrp: 150,
          sp: 60,
          deposit: 30,
          refund_type: 100,
          refund_amount: 30,
        },
        material: "PETCONTAINER",
        capacity: "20liters",
      },
      {
        product_id: 910,
        booking_type: 3,
        refill: {
          mrp: 100,
          sp: 17,
          deposit: 10,
          refund_type: 100,
          refund_amount: 10,
        },
        one_time_sale: {
          mrp: 100,
          sp: 81,
          deposit: 20,
          refund_type: 100,
          refund_amount: 20,
        },
        material: "PLASTIC",
        capacity: "20-liters",
      },
      {
        product_id: 887,
        booking_type: 1,
        refill: {
          mrp: 100,
          sp: 10,
          deposit: 400,
          refund_type: 100,
          refund_amount: 33,
        },
        one_time_sale: {
          mrp: 13,
          sp: 1,
          deposit: 133,
          refund_type: 100,
          refund_amount: 20,
        },
        material: "DEWDFED",
        capacity: "20 litwe",
      },
      {
        product_id: 864,
        booking_type: 1,
        refill: {
          mrp: 75,
          sp: 55,
          deposit: 200,
          refund_type: 100,
          refund_amount: 200,
        },
        one_time_sale: {
          mrp: 30,
          sp: 20,
          deposit: 30,
          refund_type: 100,
          refund_amount: 20,
        },
        material: "GLASS",
        capacity: "750ml",
      },
    ],
    updated_at: "2024-01-29T14:28:03.000Z",
    updated_by: "Th3HxTlYA",
    created_by_user: {
      fullname: "Noor super admin",
      phone: "9146939602",
    },
    updated_by_user: {
      fullname: "Noor super admin",
      phone: "9146939602",
    },
  },
];

const PincodesAndAres = ({
  openPincodeDialog,
  setOpenPicodeDialog,
  currentRow,
  setCurrentRow,
  allPincodesAndAreas,
  allProducts,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState({});
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    React.useState(false);
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [localityId, setLocalityId] = React.useState("");
  //   const [currentRow, setCurrentRow] = React.useState({});

  //   const [openPincodeDialog, setOpenPicodeDialog] = React.useState(false);
  React.useEffect(() => {
    console.log("currentRow");
    console.log(currentRow);
    // currentRow.products.map((data) => {
    //   console.log(data);
    // });
  }, [currentRow]);
  const handleToggle = (id) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [id]: !prevOpen[id],
    }));
  };

  const handleEditClick = (row) => {
    setCurrentRow(row);
    // setEditDialogOpen(true);
    setOpenPicodeDialog(true);
  };
  const handleBookingTypeChange = (event) => {
    setCurrentRow({ ...currentRow, booking_type: Number(event.target.value) });
  };

  const handleClose = () => {
    setEditDialogOpen(false);
  };

  const handleProductChange = (event, index, fieldName) => {
    // Clone the currentRow to avoid direct state mutation
    console.log(event.target.value);
    let newRow = { ...currentRow };
    // Ensure the products array is also cloned to avoid nested state mutation
    let products = [...newRow.products];

    // Update the specific field of the product at the given index
    products[index] = { ...products[index], [fieldName]: event.target.value };

    // Update the newRow object with the modified products array
    newRow.products = products;

    // Update the state with the modified newRow object
    setCurrentRow(newRow);
  };

  React.useEffect(() => {
    if (isConfirmed) {
      const reqObj = {
        locality_ids: [localityId],
      };
      dispatch(RemoveLocality(reqObj));
      setOpenConfirmationDialog(false);
    }
  }, [isConfirmed]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <>
      {/* <TableContainer
        sx={{
          borderRadius: "10px",
        }}
        component={Paper}
      >
        <Table aria-label="collapsible table" stickyHeader>
          <TableHead
            sx={{
              backgroundColor:
                appStyle.general.colors(colors).table.tableHeadBackground,
              color: colors.primary[300],
              boxShadow: 2,
            }}
          >
            <TableRow>
              <TableCell sx={appStyle.general.table.headderRowCell} />
              <TableCell sx={appStyle.general.table.headderRowCell}>
                S.no
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Pincode
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Locality
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                District
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                State
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Country
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: colors.primary[600] }}>
            {allPincodesAndAreas?.map((row, index) => (
              <React.Fragment key={row.id}>
                <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handleToggle(row.id)}
                    >
                      {open[row.id] ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell
                    sx={appStyle.general.table.bodyRowCell}
                    component="th"
                    scope="row"
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {row.pincode}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {row.locality}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {row.district}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {row.state_name}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    {row.country}
                  </TableCell>
                  <TableCell sx={appStyle.general.table.bodyRowCell}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <Button
                        color="secondary"
                        variant="contained"
                        size="small"
                        onClick={() => handleEditClick(row)}
                      >
                        Edit
                      </Button>
                      <Button
                        color="error"
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setLocalityId(row.id);
                          setOpenConfirmationDialog(true);
                        }}
                      >
                        Delete
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={8}
                    sx={appStyle.general.table.bodyRowCell}
                  >
                    <Collapse in={open[row.id]} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        <Typography variant="h6" gutterBottom component="div">
                          Products
                        </Typography>
                        <Table size="small" aria-label="purchases">
                          <TableHead>
                            <TableRow>
                              <TableCell>Product ID</TableCell>
                              <TableCell>Material</TableCell>
                              <TableCell>Capacity</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {row.products.map((productRow) => (
                              <TableRow key={productRow.product_id}>
                                <TableCell component="th" scope="row">
                                  {productRow.product_id}
                                </TableCell>
                                <TableCell>{productRow.material}</TableCell>
                                <TableCell>{productRow.capacity}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}
      <AddUpdatePicodeAreaSku
        openPincodeDialog={openPincodeDialog}
        setOpenPicodeDialog={setOpenPicodeDialog}
        updatePincodeData={currentRow}
        setupdatePincodeData={setCurrentRow}
        allPincodesAndAreas={allPincodesAndAreas}
        allProducts={allProducts}
      />

      <CustomConfirmationDialog
        openConfirmationDialog={openConfirmationDialog}
        setOpenConfirmationDialog={setOpenConfirmationDialog}
        description={"Are you sure that you want to delete?"}
        setIsConfirmed={setIsConfirmed}
      />
    </>
  );
};

export default PincodesAndAres;
