import { AppBar, Box, Button, useTheme } from "@mui/material";
import React from "react";
import { tokens } from "../../AppMeta/theme";
import BookWaterLogo from "../../Assests/Images/BwIcon.png";
function MobileTopBar() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const NumberFormatter = ({ value }) => {
    const formatNumber = (num) => {
      if (num >= 1000) {
        return (num / 1000).toFixed(2) + "k";
      } else {
        return num.toString();
      }
    };
    return <span>{formatNumber(value)}</span>;
  };
  return (
    <>
      <AppBar
        sx={{
          backgroundColor: colors.primary[500],

          display: "flex",
          justifyContent: "space-around",
          position: "fixed",
          boxShadow: 2,
        }}
      >
        <Box
          sx={{
            alignItems: "space-between",
            justifyContent: "flex-start",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0.1rem",
              marginBottom: "10px",
              marginLeft: "10px",
            }}
          >
            <img
              alt=""
              src={BookWaterLogo}
              style={{
                width: "2rem",
                height: "2rem",
                objectFit: "contain",

                position: "relative",
                marginTop: ".6rem",
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Box>
                <Button
                  sx={{
                    backgroundColor: colors.basic.card_color,

                    borderRadius: "10px",
                    marginLeft: "10px",
                    height: "2rem",
                    boxShadow: "2px 2px 2px 2px rgba(0, 0, 0.2, 0.2)",
                    fontSize: ".8rem",
                  }}
                  size="small"
                  variant="contained"
                >
                  <img
                    alt=""
                    src={""}
                    style={{
                      width: "2rempx",
                      height: "1.5rem",
                    }}
                  />
                  <span
                    style={{
                      fontWeight: "bold",
                      marginLeft: "5px",
                      color: colors.basic.primary_test_color,
                    }}
                  >
                    <NumberFormatter value={0} />
                  </span>
                </Button>
              </Box>

              <Box>
                <Button
                  sx={{
                    backgroundColor: colors.basic.card_color,
                    borderRadius: "10px", // Set the border radius to 0 (no rounding)
                    marginLeft: "10px",
                    height: "2rem",
                    boxShadow: "2px 2px 2px 2px rgba(0, 0, 0.2, 0.2)",
                    fontSize: ".8rem",
                  }}
                  size="small"
                  variant="contained"
                >
                  <img
                    alt=""
                    style={{
                      width: "2rem",
                      height: "1.5rem",
                    }}
                  />
                  <span
                    style={{
                      fontWeight: "bold",
                      marginLeft: "5px",
                      color: colors.basic.primary_test_color,
                    }}
                  >
                    <NumberFormatter value={0} />
                  </span>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </AppBar>
    </>
  );
}

export default MobileTopBar;
