import { Box, TextField } from "@mui/material";
import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

function AddSKUProduct() {
  const [saleType, setSaleType] = useState("refill");
  const [name, setName] = useState("");
  const [capacity, setCapacity] = useState("");
  const [material, setMaterial] = useState("");
  const [lifeSpan, setLifeSpan] = useState("");
  const [skuQty, setSkuQty] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [cgstPerc, setCgstPerc] = useState("");
  const [sgstPerc, setSgstPerc] = useState("");
  const [igstPerc, setIgstPerc] = useState("");
  const [minBookQty, setMinBookQty] = useState("");
  const [maxBookQty, setMaxBookQty] = useState("");
  const [orderType, setOrderType] = useState("");
  const [deliveryCharges, setDeliveryCharges] = useState("");
  const [description, setDescription] = useState("");
  const [mrp, setMRP] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [deposit, setDeposit] = useState("");
  const [refundType, setRefundType] = useState("");
  const [refundAmount, setRefundAmount] = useState("");

  const handleChange = (event) => {
    setSaleType(event.target.value);
  };
  return (
    <Box>
      <form>
        <Box
          sx={{ 
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
            marginTop: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <TextField label="Name" fullWidth size="small" value={name} />
            <TextField
              label="Capacity"
              fullWidth
              size="small"
              value={capacity}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <TextField
              label="Material"
              fullWidth
              size="small"
              value={material}
            />
            <TextField
              label="Life Span"
              fullWidth
              size="small"
              value={lifeSpan}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <TextField
              label="SKU Quantity"
              fullWidth
              size="small"
              value={skuQty}
            />
            <TextField
              label="HSN Code"
              fullWidth
              size="small"
              value={hsnCode}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <TextField label="CGST %" fullWidth size="small" value={cgstPerc} />
            <TextField label="SGST %" fullWidth size="small" value={sgstPerc} />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <TextField label="IGST %" fullWidth size="small" value={igstPerc} />
            <TextField
              label="Min Book Quantity"
              fullWidth
              size="small"
              value={minBookQty}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <TextField
              label="Max Book Quantity"
              fullWidth
              size="small"
              value={maxBookQty}
            />
            <TextField
              label="Order Type"
              fullWidth
              size="small"
              value={orderType}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <TextField
              label="Delivery charges"
              fullWidth
              size="small"
              value={deliveryCharges}
            />
            <TextField
              label="Description"
              fullWidth
              size="small"
              value={description}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={saleType}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="refill"
                  control={<Radio />}
                  label="Refill"
                />
                <FormControlLabel
                  value="OneTimeSale"
                  control={<Radio />}
                  label="One Time Sale"
                />
                <FormControlLabel
                  value="both"
                  control={<Radio />}
                  label="Both"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <TextField label="MRP" fullWidth size="small" value={mrp} />
            <TextField
              label="Sale price"
              fullWidth
              size="small"
              value={salePrice}
            />
            <TextField label="Deposit" fullWidth size="small" value={deposit} />
            <TextField
              label="Order Type"
              fullWidth
              size="small"
              value={orderType}
            />
            <TextField
              label="Refund Amount"
              fullWidth
              size="small"
              value={refundAmount}
            />
          </Box>
        </Box>
      </form>
    </Box>
  );
}

export default AddSKUProduct;
