import React, { useState } from "react";
import {
  Box,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Divider,
  Paper,
  Table,
  Typography,
  Slide,
  TablePagination,
  Button,
  Chip,
  colors,
  useMediaQuery,
  useTheme,
  Dialog,
  Modal,
  DialogTitle,
  DialogContent,
  Avatar,
  Stack,
  DialogContentText,
  paperClasses,
  Grid,
} from "@mui/material";
import Inventory from "@mui/icons-material/Inventory2Rounded";
import AddCircle from "@mui/icons-material/AddCircleOutline";
import Filter from "@mui/icons-material/FilterAlt";
import { CheckStock } from "./CheckStock";
import { FilterOptions } from "./FilterOptions";
import { NewOrder } from "../../OrganizationBooking/FacilityOrderManagement/NewOrder";
import { useDispatch, useSelector } from "react-redux";
import { GetEmptyCanAction } from "../../../../Redux/Actions/emptyNewCanAction";
import { EditDialogEmptyCanOrder } from "../EditDialogEmptyCanOrder";
import { CratesDetails } from "../cratesDetails";
import CustomConfirmationDialog from "../../../../Components/CustomComp/CustomConfirmationDialog";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Cancel from "@mui/icons-material/Cancel";
import Edit from "@mui/icons-material/Edit";
import Pending from "@mui/icons-material/Pending";
import Failed from "@mui/icons-material/SentimentVeryDissatisfied";
import Success from "@mui/icons-material/ThumbUp";
import Confirmed from "@mui/icons-material/SentimentSatisfiedAlt";
import Prepaid from "@mui/icons-material/Money";
import Postpaid from "@mui/icons-material/CreditCard";
import { tokens } from "../../../../AppMeta/theme";
import CancelIcon from "@mui/icons-material/Cancel";
import InventoryIcon from "@mui/icons-material/Inventory";
import { GetProfile } from "../../../../Redux/Actions/profile";
import { FlashOnRounded, Instagram } from "@mui/icons-material";
import WaterCan from "../../../../AppMeta/AppImages/WaterCanImg.png";
import { GetFacilityAction } from "../../../../Redux/Actions/facilityAction";
import { OutScanQrless } from "../../../../Redux/Actions/scanningActions";
import ScanningDialogQrLess from "../../../../Components/Scanning/ScanningDialogQrLess";
import ScanningDialog from "../../../../Components/CustomComp/ScanningDialog";
import appStyle from "../../../../AppMeta/appStyle";
import DisplayTitle from "../../../../Components/CustomComp/DisplayTitle";

const data = [
  {
    ec_master_id: 52,
    ec_order_id: "ECNF0051",
    facility_id: 0,
    container_id: 50,
    new_container_qty: 15,
    damaged_container_qty: 0,
    expired_container_qty: 0,
    ec_order_status: 100,
    ordered_by: 0,
    is_admin: false,
    otp: "78127",
    created_at: "2024-02-21T11:09:29.000Z",
    updated_at: "2024-02-21T11:09:29.000Z",
    is_submit_started: false,
    submitted_containers: 0,
    is_submit_finished: false,
    empty_containers_submitting: 0,
    received_containers: 0,
    return_containers_submitting: 0,
    submitted_return_cans: 0,
    address_id: "0",
    is_crate_submission_finished: true,
    crates: [
      {
        id: "23",
        container_capacity: 1,
        container_material: "PLASTIC",
        received_qty: 0,
        submitted_qty: 0,
        order_qty: 1,
        return_qty: 0,
        is_crate: true,
      },
      {
        id: "22",
        container_capacity: 1,
        container_material: "PAPER",
        received_qty: 0,
        submitted_qty: 0,
        order_qty: 1,
        return_qty: 0,
        is_crate: true,
      },
    ],
    payment_type: "100",
    payment_method: "3",
    payment_status: "300",
    po_file: "",
    updated_by: null,
    container_material: "GLASS",
    container_capacity: "500ml",
    facility_price: null,
  },
  {
    ec_master_id: 59,
    ec_order_id: "ECNF0058",
    facility_id: 0,
    container_id: 50,
    new_container_qty: 15,
    damaged_container_qty: 0,
    expired_container_qty: 0,
    ec_order_status: 100,
    ordered_by: 0,
    is_admin: false,
    otp: "78127",
    created_at: "2024-02-27T05:04:34.000Z",
    updated_at: "2024-02-27T05:04:34.000Z",
    is_submit_started: false,
    submitted_containers: 0,
    is_submit_finished: false,
    empty_containers_submitting: 0,
    received_containers: 0,
    return_containers_submitting: 0,
    submitted_return_cans: 0,
    address_id: "0",
    is_crate_submission_finished: true,
    crates: [
      {
        id: "23",
        container_capacity: 1,
        container_material: "PLASTIC",
        received_qty: 0,
        submitted_qty: 0,
        order_qty: 1,
        return_qty: 0,
        is_crate: true,
      },
      {
        id: "22",
        container_capacity: 1,
        container_material: "PAPER\n",
        received_qty: 0,
        submitted_qty: 0,
        order_qty: 1,
        return_qty: 0,
        is_crate: true,
      },
    ],
    payment_type: "100",
    payment_method: "3",
    payment_status: "300",
    po_file: "",
    updated_by: null,
    container_material: "GLASS",
    container_capacity: "500ml",
    facility_price: null,
  },
  {
    ec_master_id: 34,
    ec_order_id: "ECNF0033",
    facility_id: 0,
    container_id: 17,
    new_container_qty: 13,
    damaged_container_qty: 0,
    expired_container_qty: 0,
    ec_order_status: 100,
    ordered_by: 0,
    is_admin: false,
    otp: "78127",
    created_at: "2024-02-20T14:36:43.000Z",
    updated_at: "2024-02-20T14:36:43.000Z",
    is_submit_started: false,
    submitted_containers: 0,
    is_submit_finished: false,
    empty_containers_submitting: 0,
    received_containers: 0,
    return_containers_submitting: 0,
    submitted_return_cans: 0,
    address_id: "0",
    is_crate_submission_finished: true,
    crates: [
      {
        id: "23",
        container_capacity: 1,
        container_material: "PLASTIC",
        received_qty: 0,
        submitted_qty: 0,
        order_qty: 1,
        return_qty: 0,
        is_crate: true,
      },
      {
        id: "22",
        container_capacity: 1,
        container_material: "PAPER",
        received_qty: 0,
        submitted_qty: 0,
        order_qty: 1,
        return_qty: 0,
        is_crate: true,
      },
    ],
    payment_type: "200",
    payment_method: null,
    payment_status: null,
    po_file: "",
    updated_by: null,
    container_material: "PET MATERIAL",
    container_capacity: "20 Liter",
    facility_price: null,
  },
  {
    ec_master_id: 36,
    ec_order_id: "ECNF0035",
    facility_id: 0,
    container_id: 17,
    new_container_qty: 13,
    damaged_container_qty: 0,
    expired_container_qty: 0,
    ec_order_status: 100,
    ordered_by: 0,
    is_admin: false,
    otp: "78127",
    created_at: "2024-02-20T14:37:06.000Z",
    updated_at: "2024-02-20T14:37:06.000Z",
    is_submit_started: false,
    submitted_containers: 0,
    is_submit_finished: false,
    empty_containers_submitting: 0,
    received_containers: 0,
    return_containers_submitting: 0,
    submitted_return_cans: 0,
    address_id: "0",
    is_crate_submission_finished: true,
    crates: [
      {
        id: "23",
        container_capacity: 1,
        container_material: "PLASTIC",
        received_qty: 0,
        submitted_qty: 0,
        order_qty: 1,
        return_qty: 0,
        is_crate: true,
      },
      {
        id: "22",
        container_capacity: 1,
        container_material: "PAPER",
        received_qty: 0,
        submitted_qty: 0,
        order_qty: 1,
        return_qty: 0,
        is_crate: true,
      },
    ],
    payment_type: "200",
    payment_method: null,
    payment_status: null,
    po_file: "",
    updated_by: null,
    container_material: "PET MATERIAL",
    container_capacity: "20 Liter",
    facility_price: null,
  },
  {
    ec_master_id: 61,
    ec_order_id: "ECNF0060",
    facility_id: 1,
    container_id: 50,
    new_container_qty: 16,
    damaged_container_qty: 0,
    expired_container_qty: 0,
    ec_order_status: 100,
    ordered_by: 0,
    is_admin: false,
    otp: "78127",
    created_at: "2024-02-29T08:53:36.000Z",
    updated_at: "2024-02-29T08:53:36.000Z",
    is_submit_started: false,
    submitted_containers: 0,
    is_submit_finished: false,
    empty_containers_submitting: 0,
    received_containers: 0,
    return_containers_submitting: 0,
    submitted_return_cans: 0,
    address_id: "0",
    is_crate_submission_finished: true,
    crates: [
      {
        id: "23",
        container_capacity: 1,
        container_material: "PLASTIC",
        received_qty: 0,
        submitted_qty: 0,
        order_qty: 1,
        return_qty: 0,
        is_crate: true,
      },
      {
        id: "22",
        container_capacity: 1,
        container_material: "PAPER",
        received_qty: 0,
        submitted_qty: 0,
        order_qty: 1,
        return_qty: 0,
        is_crate: true,
      },
    ],
    payment_type: "100",
    payment_method: "3",
    payment_status: "300",
    po_file: "",
    updated_by: null,
    container_material: "GLASS",
    container_capacity: "500ml",
    facility_price: null,
  },
  {
    ec_master_id: 55,
    ec_order_id: "ECNF0054",
    facility_id: 0,
    container_id: 50,
    new_container_qty: 15,
    damaged_container_qty: 0,
    expired_container_qty: 0,
    ec_order_status: 300,
    ordered_by: 0,
    is_admin: false,
    otp: "78127",
    created_at: "2024-02-29T11:34:21.000Z",
    updated_at: "2024-02-29T11:34:21.000Z",
    is_submit_started: false,
    submitted_containers: 0,
    is_submit_finished: false,
    empty_containers_submitting: 0,
    received_containers: 0,
    return_containers_submitting: 0,
    submitted_return_cans: 0,
    address_id: "0",
    is_crate_submission_finished: true,
    crates: [],
    payment_type: "100",
    payment_method: "3",
    payment_status: "300",
    po_file: "",
    updated_by: "Lkgx_lbEM",
    container_material: "GLASS",
    container_capacity: "500ml",
    facility_price: null,
  },
];

const Header = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box>
      <Typography
        color={colors.grey[100]}
        fontWeight="bold"
        mt="20px"
        variant="h1"
      >
        Facility Order Management
      </Typography>
    </Box>
  );
};

const CheckBoxNewOrder = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [showBox, setShowBox] = React.useState(false);
  const [buttonChange, setButtonChange] = React.useState(false);
  const [newOrder, setNewOrder] = React.useState(false);

  const handleClickNewOrder = () => {
    setNewOrder(!newOrder);
  };

  const handleClick = () => {
    setShowBox(!showBox);
  };
  const handleChangeButton = () => {
    setButtonChange((prevState) => !prevState);
  };
  return (
    <Box mt="10px" sx={{ display: "flex", justifyContent: "flex-end" }}>
      {/* <Button
        size="medium"
        sx={{ fontWeight: "bold" }}
        startIcon={<InventoryIcon />}
        variant="contained"
        color="info"
        onClick={handleClick}
      >
        Check Stock
        <CheckStock/>
      </Button>
      {showBox && (
        <Box
          sx={{
            position: "absolute",
            transform: "translateY(40%)",
            width: "300px",
            height: "100px",
            backgroundColor: "primary",
            boxShadow: "3",
            borderRadius: "10px",
          }}
        ></Box>
      )} */}
      <Box sx={appStyle.general.button.addButtonBox}>
        <Button
          size="medium"
          endIcon={<AddCircle />}
          variant="contained"
          sx={appStyle.general.button.addButton}
          color="secondary"
          onClick={handleClickNewOrder}
        >
          New Order
        </Button>
      </Box>
      {newOrder && (
        <NewOrder open={newOrder} handleCloseNewOrder={handleClickNewOrder} />
      )}
    </Box>
  );
};

const ViewBox = ({
  open,
  onClose,
  orderDetails,
  getPaymentType,
  getPaymentStatus,
  getPaymentMethod,
  handleClickShowMore,
  handleEditClick,
  setOrderId,
  setOpenConfirmationDialog,
  otp,
  // setOpenScanningQrlessDialog,
  // openScanningQrlessDialog,
  // outScanQty,
  // setOutScanQty,
}) => {
  const dispatch = useDispatch();
  const [isEdit, setisEdit] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // Scanning variables
  const [openScanningQrlessDialog, setOpenScanningQrlessDialog] =
    React.useState(false);
  // for QR less product outscan
  const [outScanQty, setOutScanQty] = React.useState(
    orderDetails?.new_container_qty
  );
  const [submitted, setSubmitted] = React.useState(false);

  // For QR product scanning variables
  const [openScanningDialog, setOpenScanningDialog] = React.useState(false);
  const [scannedQrBarcode, setScannedQrBarcode] = React.useState("");
  const [scanQtyMax, setScanQtyMax] = React.useState(null);
  const [currentScanned, setCurrentScanned] = React.useState({});
  const [scannedCount, setScannedCount] = React.useState(0);

  const [openLinkFromStartDialog, setOpenLinkFromStartDialog] =
    React.useState(false);
  const [requestObject, setRequestObject] = React.useState({});
  const [validateExpired, setValidateExpired] = React.useState([]);
  const isBatch = false;

  console.log("orderDetails");
  console.log(orderDetails);

  React.useEffect(() => {
    console.log("submitted");
    console.log(submitted);
    if (submitted) {
      // Hit the submit API
      const reqObj = {
        order_id: orderDetails?.ec_order_id,
        container_material: orderDetails?.container_capacity,
        container_capacity: orderDetails?.container_material,
        instock_qty: outScanQty,
      };
      console.log(reqObj);
      dispatch(OutScanQrless(reqObj));
      setTimeout(() => {
        setSubmitted(false);
      }, 1000);
    }
  }, [submitted]);

  return (
    <>
      {" "}
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <Box m="20px" display="flex" justifyContent="space-between">
          <Typography fontWeight="bold" variant="h3">
            Show Details
          </Typography>
          <Button
            color="error"
            size="large"
            variant="contained"
            onClick={onClose}
          >
            <CancelIcon size="large" />
          </Button>
        </Box>
        <DialogContent>
          {isEdit ? (
            <>
              <EditDialogEmptyCanOrder
                open={isEdit}
                onClose={() => {
                  setisEdit(false);
                }}
                item={orderDetails}
              />
              <Button>Save</Button>
            </>
          ) : (
            <Box>
              <Grid container spacing={2} padding="5px">
                <Grid item xs={7}>
                  <Typography variant="h4">Payment Type</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="h4">
                    {getPaymentType(orderDetails?.payment_type)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} padding="5px">
                <Grid item xs={7}>
                  <Typography variant="h4">Payment Status</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="h4">
                    {getPaymentStatus(orderDetails?.payment_status)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} padding="5px">
                <Grid item xs={7}>
                  <Typography variant="h4">Payment Method</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="h4">
                    {getPaymentMethod(orderDetails?.payment_method)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} padding="5px">
                <Grid item xs={7}>
                  <Typography variant="h4">OTP</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="h4">{orderDetails.otp}</Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} padding="5px">
                <Grid item xs={7}>
                  <Typography variant="h4">Quantity</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="h4">
                    {orderDetails.new_container_qty}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} padding="5px">
                <Grid item xs={7}>
                  <Typography variant="h4">Scan status</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => setOpenScanningDialog(true)}
                    // onClick={() => setOpenScanningQrlessDialog(true)}
                  >
                    Start Outscan
                  </Button>
                </Grid>
              </Grid>

              <Box mt="10px" display="flex" justifyContent="space-around">
                <Button
                  onClick={() => {
                    setisEdit(true);
                  }}
                  variant="contained"
                  color="secondary"
                >
                  Edit
                </Button>
                <Button
                  onClick={() => {
                    setOrderId(orderDetails.id);
                    setOpenConfirmationDialog(true);
                  }}
                  variant="outlined"
                  color="error"
                >
                  Cancel Order
                </Button>
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>
      {openScanningQrlessDialog && (
        <ScanningDialogQrLess
          outScanQty={outScanQty}
          setOutScanQty={setOutScanQty}
          openScanningQrlessDialog={openScanningQrlessDialog}
          setOpenScanningQrlessDialog={setOpenScanningQrlessDialog}
          setSubmitted={setSubmitted}
        />
      )}
      {openScanningDialog && (
        <ScanningDialog
          setOpenScanningDialog={setOpenScanningDialog}
          openScanningDialog={openScanningDialog}
          scannedQrBarcode={scannedQrBarcode}
          setScannedQrBarcode={setScannedQrBarcode}
          currentScanned={currentScanned}
          setCurrentScanned={setCurrentScanned}
          // scanQtyMax={scanQtyMax}
          scanQtyMax={orderDetails?.new_container_qty}
          scanOrderId={orderDetails?.ec_order_id}
          scannedCount={scannedCount}
          setScannedCount={setScannedCount}
          setRequestObject={setRequestObject}
          setOpenLinkFromStartDialog={setOpenLinkFromStartDialog}
          validateExpired={validateExpired}
          isBatch={isBatch}
        />
      )}
    </>
  );
};

//Main Function Export
export function FacilityOrderManagement(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  //useSelector
  const orderDetails = useSelector((state) => state.productData);
  const facilityDetails = useSelector((state) => state.facilityManagementData);

  //1.useState Hooks
  const [openDialogStock, setOpenDialogStock] = React.useState(false);
  const [filterOpen, setFilterOpen] = React.useState("false");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [newOrder, setNewOrder] = React.useState(false);
  const [getOrders, setGetOrders] = React.useState([]);
  const [isPopperOpen, setIsPopperOpen] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    React.useState(false);
  const [selectedOrder, setSelectedOrder] = useState();
  const [viewBoxOpen, setViewBoxOpen] = React.useState(false);
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const [openOrderDetailsDialog, setOpenOrderDetailsDialog] =
    React.useState(false);
  const [moreDetails, setMoreDetails] = React.useState({});
  const [currentItem, setCurrentItem] = React.useState(null);
  const [orderId, setOrderId] = React.useState("");
  const [facilityAllOrders, setFacilityAllOrders] = React.useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // Scanning variables
  const [openScanningQrlessDialog, setOpenScanningQrlessDialog] =
    React.useState(false);
  // for QR less product outscan
  const [outScanQty, setOutScanQty] = React.useState(null);
  //2.useEffect
  React.useEffect(() => {
    //dispatch(GetEmptyCanAction());
    dispatch(GetProfile());
  }, []);

  React.useEffect(() => {
    console.log("Get Facility Action");
    dispatch(GetFacilityAction());
  }, []);

  React.useEffect(() => {
    console.log("facilityDetails");
    console.log(facilityDetails);
    if (facilityDetails.error === false) {
      console.log("facilityAllOrders", facilityAllOrders);
      setFacilityAllOrders(facilityDetails?.facilityOrder);
    }
  }, [facilityDetails]);
  // React.useEffect(() => {
  //   console.log("Get Products");
  //   console.log(productDetails);
  // },[productDetails])

  //3.Functions
  const handleClickStock = () => {
    setOpenDialogStock(true);
  };
  const handleClickNewOrder = () => {
    setNewOrder(true);
  };

  const handleClickViewBox = (item) => {
    setViewBoxOpen(true);
    setSelectedOrder(item);
  };

  const handleCloseNewOrder = () => {
    setNewOrder(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClose = () => {
    setEditDialogOpen(false);
    setCurrentItem(null); // Clear the current item when dialog is closed
  };

  const handleClickShowMore = (details) => {
    console.log("details");
    console.log(details);
    setMoreDetails(details);
    setOpenOrderDetailsDialog(true);
  };

  const handleEditClick = (item) => {
    console.log("item");
    console.log(item);
    setCurrentItem(item);
    setEditDialogOpen(true);
  };

  const handleChangeOrders = () => {
    setGetOrders(data);
  };

  const getPaymentType = (code) => {
    return parseInt(code) === 100 ? (
      <Chip icon={<Prepaid />} color="success" label="Prepaid" />
    ) : (
      //<Typography color="success">Prepaid</Typography>
      <Chip icon={<Postpaid />} color="warning" label="Postpaid" />
    );
  };

  const getPaymentMethod = (code) => {
    switch (parseInt(code)) {
      case 1:
        return "RAZORPAY";
      case 2:
        return "PAYSHARP";
      case 3:
        return "WALLET";
      case 4:
        return "POSTPAID";
      default:
        return "";
    }
  };

  const getOrderStatus = (code) => {
    switch (code) {
      case 100:
        return <Chip icon={<Confirmed />} color="success" label="Confirmed" />;
      case 200:
        return "COMPLETED";
      case 300:
        return "CANCELLED";
      case 400:
        return <Chip size="medium" label="Pending" />;
      case 501:
        return "ACCEPTED";
      case 502:
        return "ADMIN_REJECTED";
      default:
        return "";
    }
  };

  const getPaymentStatus = (code) => {
    console.log(code);
    switch (parseInt(code)) {
      case 100:
        return (
          <Chip
            icon={<Success />}
            variant="outlined"
            sx={{ color: "red" }}
            size="medium"
            label="Success"
          />
        );

      case 200:
        return (
          <Chip
            icon={<Failed />}
            variant="outlined"
            sx={{ color: "red" }}
            size="medium"
            label="Failed"
          />
        );
      case 300:
        return <Chip icon={<Pending />} color="warning" label="Pending" />;
      default:
        return "";
    }
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getOrders.length) : 0;

  const colors = tokens(theme.palette.mode);

  return (
    <div>
      <Box sx={{ padding: "10px", marginLeft: "10px" }}>
        {/* <Header /> */}
        {/* <DisplayTitle>Facility Order Management</DisplayTitle> */}
        <CheckBoxNewOrder />

        {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              marginTop: "20px",
              borderRadius: "30px",
              textAlign: "start",
              backgroundColor: "#d9d9d9",
              padding: "8px",
              display: "flex",
              // width: "150px",
              height: "40px",
              cursor: "pointer",
            }}
            onClick={handleClickStock}
          >
            <Inventory sx={{ color: "black" }} />
            <Typography
              variant="h7"
              sx={{ color: "#0000ff", marginLeft: "5px" }}
            >
              Check Stock
            </Typography>
          </Box>

          <Box
            sx={{
              marginTop: "20px",
              borderRadius: "30px",
              textAlign: "start",
              backgroundColor: "#ace255",
              padding: "8px",
              display: "flex",
              // width: "150px",
              height: "40px",
              transition: "background-color 0.3s",
              "&:hover": {
                backgroundColor: "#88b327",
                boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              },
              cursor: "pointer",
            }}
            onClick={handleClickNewOrder}
          >
            <Typography
              variant="h7"
              sx={{ color: "black", marginRight: "10px", marginLeft: "10px" }}
            >
              New Order
            </Typography>
            <AddCircle sx={{ color: "black" }} />
          </Box>
        </Box> */}

        {/* <Box sx={appStyle.general.table.containerForTable}>
          <TableContainer sx={appStyle.general.table.tableContainer}>
            <Table stickyHeader>
              <TableHead
                sx={{
                  backgroundColor:
                    appStyle.general.colors(colors).table.tableHeadBackground,
                  color: colors.primary[300],
                  ...appStyle.general.table.tableHead,
                }}
              >
                <TableRow>
                  <TableCell sx={appStyle.general.table.headderRowCell}>
                    Order ID
                  </TableCell>
                  <TableCell sx={appStyle.general.table.headderRowCell}>
                    Product
                  </TableCell>
                  <TableCell sx={appStyle.general.table.headderRowCell}>
                    Quantity
                  </TableCell>
                  {!isMobile && (
                    <>
                      <TableCell sx={appStyle.general.table.headderRowCell}>
                        Payment Type
                      </TableCell>
                      <TableCell sx={appStyle.general.table.headderRowCell}>
                        Payment Method
                      </TableCell>
                      <TableCell sx={appStyle.general.table.headderRowCell}>
                        Payment Status
                      </TableCell>
                      <TableCell sx={appStyle.general.table.headderRowCell}>
                        OTP
                      </TableCell>
                      <TableCell sx={appStyle.general.table.headderRowCell}>
                        Status
                      </TableCell>
                      <TableCell sx={appStyle.general.table.headderRowCell}>
                        Crates
                      </TableCell>

                      <TableCell sx={appStyle.general.table.headderRowCell}>
                        Action
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody sx={{ backgroundColor: colors.primary[600] }}>
                {(rowsPerPage > 0
                  ? facilityAllOrders.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : facilityAllOrders
                ).map((row) => (
                  <TableRow
                    key={row.ec_master_id}
                    onChange={handleChangeOrders}
                    onClick={() => handleClickViewBox(row)}
                  >
                    <TableCell sx={appStyle.general.table.bodyRowCell}>
                      {row.ec_order_id}
                    </TableCell>
                    <TableCell sx={appStyle.general.table.bodyRowCell}>
                      <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Box>
                          <img
                            src={WaterCan}
                            alt="Water Can"
                            style={{ width: 56, height: 65 }}
                          />
                        </Box>

                        <Stack direction="column" alignItems="center">
                          <Typography sx={{ fontSize: "0.9rem" }}>
                            {row.container_capacity}
                          </Typography>
                          <Typography sx={{ fontSize: "10px" }}>
                            {row.container_material}
                          </Typography>
                        </Stack>
                      </Stack>
                    </TableCell>
                    <TableCell sx={appStyle.general.table.bodyRowCell}>
                      <Box display="flex" flexDirection="column">
                        {row.new_container_qty}

                        {isMobile && getOrderStatus(row.ec_order_status)}
                      </Box>
                    </TableCell>
                    {!isMobile && (
                      <>
                        <TableCell sx={appStyle.general.table.bodyRowCell}>
                          {getPaymentType(row.payment_type)}
                        </TableCell>
                        <TableCell sx={appStyle.general.table.bodyRowCell}>
                          {getPaymentMethod(row.payment_method)}
                        </TableCell>
                        <TableCell sx={appStyle.general.table.bodyRowCell}>
                          {getPaymentStatus(row.payment_status)}
                        </TableCell>
                        <TableCell sx={appStyle.general.table.bodyRowCell}>
                          {row.otp}
                        </TableCell>
                        <TableCell sx={appStyle.general.table.bodyRowCell}>
                          {getOrderStatus(row.ec_order_status)}
                        </TableCell>
                        <TableCell sx={appStyle.general.table.bodyRowCell}>
                          <Button
                            variant="contained"
                            color="inherit"
                            onClick={() => handleClickShowMore(row)}
                          >
                            View
                          </Button>
                        </TableCell>
                        <TableCell sx={appStyle.general.table.bodyRowCell}>
                          <Box display="flex" justifyContent="space-evenly">
                            <Button
                              variant="contained"
                              color="secondary"
                              startIcon={<Edit />}
                              onClick={() => handleEditClick(row)}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="outlined"
                              color="error"
                              sx={{ marginLeft: "10px" }}
                              onClick={() => {
                                setOrderId(row.id);
                                setOpenConfirmationDialog(true);
                              }}
                              startIcon={<Cancel />}
                            >
                              Cancel
                            </Button>
                          </Box>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box> */}

        {openDialogStock && (
          <CheckStock open={openDialogStock} close={setOpenDialogStock} />
        )}

        {newOrder && (
          <NewOrder open={newOrder} handleCloseNewOrder={handleCloseNewOrder} />
        )}
        {openConfirmationDialog && (
          <CustomConfirmationDialog
            openConfirmationDialog={openConfirmationDialog}
            setOpenConfirmationDialog={setOpenConfirmationDialog}
            description={"Are you sure that you want to delete?"}
            setIsConfirmed={setIsConfirmed}
          />
        )}

        {openOrderDetailsDialog && (
          <CratesDetails
            openOrderDetailsDialog={openOrderDetailsDialog}
            setOpenOrderDetailsDialog={setOpenOrderDetailsDialog}
            moreDetails={moreDetails}
          />
        )}
        {editDialogOpen && (
          <EditDialogEmptyCanOrder
            open={editDialogOpen}
            onClose={handleClose}
            item={currentItem}
          />
        )}
        {viewBoxOpen && isMobile && (
          <ViewBox
            open={viewBoxOpen}
            onClose={() => setViewBoxOpen(false)}
            orderDetails={selectedOrder}
            getPaymentType={getPaymentType}
            getPaymentStatus={getPaymentStatus}
            getPaymentMethod={getPaymentMethod}
            setOrderId={setOrderId}
            setOpenConfirmationDialog={setOpenConfirmationDialog}
            // setOpenScanningQrlessDialog={setOpenScanningQrlessDialog}
            // openScanningQrlessDialog={openScanningQrlessDialog}
            // outScanQty={outScanQty}
            // setOutScanQty={setOutScanQty}
          />
        )}
      </Box>
    </div>
  );
}
