import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
import appStyle from "../../AppMeta/appStyle";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../AppMeta/theme";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export default function GeneratedQRBarcodeList() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box>
      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableHead
            sx={{
              background:
                appStyle.general.colors(colors).table.tableHeadBackground,
              color: colors.primary[300],
              boxShadow: 2,
            }}
          >
            <TableRow>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Start number{" "}
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                End number
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Quantity
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Created at{" "}
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Batch Number
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  // component="th"
                  // scope="row"
                  sx={appStyle.general.table.bodyRowCell}
                >
                  {row.name}
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  {row.calories}
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  {row.fat}
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  {row.carbs}
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  {row.protein}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
