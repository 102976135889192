import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Typography } from "@mui/material";
import { Fullscreen } from "@mui/icons-material";
import appStyle from "../../AppMeta/appStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomDialogMoreDetails({
  openCustomDialog,
  setOpenCustomDialog,
  title,
  moreDetails,
}) {
  // console.log("More Details");
  // console.log(moreDetails.created_user_details);
  const handleClose = () => {
    setOpenCustomDialog(false);
  };

  return (
    <Dialog
      open={openCustomDialog}
      onClose={handleClose}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>Updated By</DialogContentText>
        {moreDetails.updated_user_details && (
          <DialogContent>
            <Typography>
              Full Name: {moreDetails.updated_user_details.fullname}
            </Typography>
            <Typography>
              Phone: {moreDetails.updated_user_details.phone}
            </Typography>
            <Typography>
              Email: {moreDetails.updated_user_details.email}
            </Typography>
          </DialogContent>
        )}
      </DialogContent>
      <DialogContent>
        <DialogContentText>Created By</DialogContentText>
        {moreDetails.created_user_details && (
          <DialogContent>
            <Typography>
              Full Name: {moreDetails.created_user_details.fullname}
            </Typography>
            <Typography>
              Phone: {moreDetails.created_user_details.phone}
            </Typography>
            <Typography>
              Email: {moreDetails.created_user_details.email}
            </Typography>
          </DialogContent>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} sx={appStyle.general.button.redButton}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
