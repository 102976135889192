import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { appColors } from "../../AppMeta/appColors";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import TextField from "@mui/material/TextField";
import CustomInputTextField from "../../Components/CustomComp/CustomInputTextField";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RegenerateQrDialog from "../Orders/BookwaterBooking/RegenerateQrDialog";
import CustomDialogMoreDetails from "../../Components/CustomComp/CustomDialogMoreDetails";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch } from "react-redux";
import { GetGeneratedQR } from "../../Redux/Actions/GenerateQrAction";
import appStyle from "../../AppMeta/appStyle";
import { tokens } from "../../AppMeta/theme";

export default function CodesListTable({
  generatedCodesList,
  pageNumber,
  pageSize,
  lastPageNumber,
  createdAtSort,
  isQr,
  qrBarcodeId,
  orderId,
  setPageNumber,
  setPageSize,
  setCreatedAtSort,
  setisQr,
  setQrBarcodeId,
  setOrderId,
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme);
  const [openRegenerateQrDialog, setOpenRegenerateQrDialog] =
    React.useState(false);

  const [openCustomDialog, setOpenCustomDialog] = React.useState(false);
  const [moreDetails, setMoreDetails] = React.useState({});

  const [searchOrderFlag, setSearchOrderFlag] = React.useState(false);
  const [searchQrIdFlag, setSearchQrIdFlag] = React.useState(false);

  const handleRegenerateQr = () => {
    setOpenRegenerateQrDialog(true);
  };

  const CustomToolbar = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            // flexDirection: "column",
            gap: "20px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>
            <ToggleButtonGroup
              size="small"
              color="primary"
              value={isQr}
              exclusive
              onChange={handleChangeStatus}
              aria-label="Platform"
            >
              <ToggleButton value={true}>QR Code</ToggleButton>
              <ToggleButton value={false}>Bar Code</ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box>
            {" "}
            <ToggleButtonGroup
              size="small"
              color="primary"
              value={createdAtSort}
              exclusive
              onChange={handleChangeAscDesc}
              aria-label="Platform"
            >
              <ToggleButton value="ASC">Ascending</ToggleButton>
              <ToggleButton value="DESC">Descending</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
      </Box>
    );
  };
  const handleChangeStatus = (event, newAlignment) => {
    setisQr(newAlignment);
  };
  // Ascending and desending toggle function
  const handleChangeAscDesc = (event, newAlignment) => {
    setCreatedAtSort(newAlignment);
  };

  const handleChangeOrderId = (event) => {
    setOrderId(event.target.value);
    console.log(event.target.value);
  };

  const handleChangeCodeId = (event) => {
    console.log(event.target.value);
    setQrBarcodeId(event.target.value);
  };

  const pageNumberForward = () => {
    if (generatedCodesList.length > 0) {
      setPageNumber(pageNumber + 1);
      // setSearchOrderFlag(true);
    }
  };

  const pageNumberBackward = () => {
    if (pageNumber - 1 > 0) {
      setPageNumber(pageNumber - 1);
      // setdisableBtn(false);
      // setSearchOrderFlag(true);
    }
  };

  const handleShowMore = (generatedByDetails) => {
    console.log(generatedByDetails);
    setOpenCustomDialog(true);
    setMoreDetails(generatedByDetails);
  };

  const handleSearchOrderID = () => {
    console.log(orderId);
    setSearchOrderFlag(true);
  };
  const handleSearchQrBarcodeID = () => {
    console.log(orderId);
    setSearchQrIdFlag(true);
  };

  React.useEffect(() => {
    if (searchOrderFlag || searchQrIdFlag) {
      dispatch(
        GetGeneratedQR(
          pageNumber,
          pageSize,
          createdAtSort,
          isQr,
          qrBarcodeId,
          orderId
        )
      );
      // setOrderId("");
      // setQrBarcodeId("");
      setSearchOrderFlag(false);
      setSearchOrderFlag(false);
    }
  }, [searchOrderFlag, searchQrIdFlag]);
  return (
    <Box>
      <Toolbar>
        {/* <CustomToolbar /> */}
        {/* <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <Paper>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Order ID"
              inputProps={{ "aria-label": "search google maps" }}
              onChange={handleChangeOrderId}
            />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={handleSearchOrderID}
            >
              <SearchIcon />
            </IconButton>
          </Paper>

          <Paper>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search QR/Barcode  ID"
              inputProps={{ "aria-label": "search google maps" }}
              onChange={handleChangeCodeId}
            />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={handleSearchQrBarcodeID}
            >
              <SearchIcon />
            </IconButton>
          </Paper>

          <Button
            color="secondary"
            variant="outlined"
            size="small"
            onClick={() => handleRegenerateQr()}
            sx={appStyle.general.button.editButton}
          >
            Regenerate Codes
          </Button>
        </Box> */}
      </Toolbar>
      {/* <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650 }}
          aria-label="qr-barcode-table"
          stickyHeader
        >
          <TableHead
            sx={{
              backgroundColor:
                appStyle.general.colors(colors).table.tableHeadBackground,
              color: colors.primary[300],
              boxShadow: 2,
            }}
          >
            <TableRow>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Start Number
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                End Number
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Order ID
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Created At
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                More details
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {generatedCodesList?.map((item, index) => (
              <TableRow key={index}>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  {item.start_number}
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  {item.end_number}
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  {item.order_id}
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  {item.created_at}
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={() => handleShowMore(item.generated_user_details)}
                  >
                    Show More
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}

      {/* Pagenumber and pagesize */}

      {/* <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          gap: "15px",
          padding: "10px",
          marginTop: "10px",
        }}
      >
        <Box>
          <Button
            sx={{
              cursor: "pointer",
            }}
            onClick={pageNumberBackward}
          >
            <ArrowBackIosIcon />
          </Button>
        </Box>
        <Typography
          variant="button"
          display="block"
          gutterBottom
          sx={{
            marginLeft: "10px",
            marginRight: "10px",
            backgroundColor: "#2D81FF",
            color: "white",
            padding: "5px",
            width: "40px",
            height: "35px",
            borderRadius: "15px",
            textAlign: "center",
          }}
        >
          {pageNumber}
        </Typography>
        <Box>
          <Button
            sx={{
              cursor: "pointer",
            }}
            onClick={pageNumberForward}
            disabled={pageNumber < lastPageNumber ? false : true}
          >
            <ArrowForwardIosIcon />
          </Button>
        </Box>
        <Box>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Page size</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={pageSize}
              label="Page size"
              onChange={(e) => {
                setPageSize(e.target.value);
                // setSearchOrderFlag(true);
              }}
            >
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={500}>500</MenuItem>
              <MenuItem value={1000}>1000</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box> */}

      {openRegenerateQrDialog && (
        <>
          <RegenerateQrDialog
            openRegenerateQrDialog={openRegenerateQrDialog}
            setOpenRegenerateQrDialog={setOpenRegenerateQrDialog}
            isQr={isQr}
            setIsQr={setisQr}
            pageNumber={pageNumber}
            pageSize={pageSize}
            createdAtSort={createdAtSort}
            qrBarcodeId={qrBarcodeId}
            orderId={orderId}
          />
        </>
      )}

      {openCustomDialog && (
        <>
          <CustomDialogMoreDetails
            openCustomDialog={openCustomDialog}
            setOpenCustomDialog={setOpenCustomDialog}
            title={"Generated By"}
            moreDetails={moreDetails}
          />
        </>
      )}
    </Box>
  );
}
