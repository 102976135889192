import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import Cancel from "@mui/icons-material/Cancel";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetFinalProductSKU } from "../../Redux/Actions/productsAction";
import {
  CreateRecycleOrder,
  GetDamagedExpired,
  GetRecycleOrderTable,
} from "../../Redux/Actions/recycleActions";
import RecyclingIcon from "@mui/icons-material/Recycling";
import CloseIcon from "@mui/icons-material/Close";
import { tokens } from "../../AppMeta/theme";
import appStyle from "../../AppMeta/appStyle";
import appFonts from "../../AppMeta/appFonts";
import DisplayTitle from "../../Components/CustomComp/DisplayTitle";
import CustomFilter from "../../Components/CustomComp/CustomFilter";

const data = [
  {
    order_id: "ECNF00183",
    physically_damaged_quantity: 9,
    expired_quantity: 5,
    order_status: "100",
    container_capacity: "20_Liters",
    container_material: "PET MATERIAL",
    initiated_by: "",
    created_at: "2024-05-07T07:34:38.000Z",
    updated_by: "",
    updated_at: "2024-05-07T07:34:38.381Z",
  },
  {
    order_id: "ECNF00184",
    physically_damaged_quantity: 9,
    expired_quantity: 5,
    order_status: "100",
    container_capacity: null,
    container_material: null,
    initiated_by: "",
    created_at: "2024-05-08T10:10:42.000Z",
    updated_by: "Lkgx_lbEM",
    updated_at: "2024-05-08T10:10:43.022Z",
  },
  {
    order_id: "ECNF00182",
    physically_damaged_quantity: 2,
    expired_quantity: 1,
    order_status: "300",
    container_capacity: "20_Liters",
    container_material: "PET MATERIAL",
    initiated_by: "",
    created_at: "2024-05-07T07:20:45.000Z",
    updated_by: "Lkgx_lbEM",
    updated_at: "2024-05-08T07:00:15.165Z",
  },
];

const productDatas = {
  error: false,
  msg: "Successfully got the Details!",
  data: [
    {
      product_details: {
        id: 62,
        name: "1litre Glass bottle",
        image: "",
        price: 1,
        amount: 1,
        refill: {
          sp: 1,
          mrp: 1,
          deposit: 1,
          refund_type: 100,
          refund_amount: 1,
        },
        sku_qty: [3],
        capacity: "1litre",
        hsn_code: "22110",
        material: "GLASS",
        cgst_perc: 6,
        igst_perc: 6,
        life_span: 5,
        sgst_perc: 6,
        created_at: "11:17:29.449964",
        order_type: 100,
        updated_at: "2024-03-08T11:17:29.449964",
        description: "This is a 1litre glass bottle",
        booking_type: 2,
        max_book_qty: 99,
        min_book_qty: 1,
        one_time_sale: {
          sp: 1,
          mrp: 1,
          deposit: 1,
          refund_type: 100,
          refund_amount: 0,
        },
        facility_price: {
          deposit_price: 65,
        },
      },
      crate_details: [
        {
          id: 25,
          name: "1litre Glass bottle crate",
          image: "",
          price: 50,
          capacity: 3,
          description: "This is a 1litre glass bottle",
        },
      ],
    },
    {
      product_details: {
        id: 71,
        name: "20 Liter Water Can",
        image: "",
        price: 70,
        amount: 70,
        refill: {
          sp: 1,
          mrp: 1,
          deposit: 0,
          refund_type: 100,
          refund_amount: 0,
        },
        sku_qty: [12],
        capacity: "20_Liters",
        hsn_code: "125466",
        material: "PET MATERIAL",
        cgst_perc: 6,
        igst_perc: 2,
        life_span: 1000,
        sgst_perc: 6,
        created_at: "15:50:39.052949",
        order_type: 100,
        updated_at: "2024-04-29T15:50:39.052949",
        description: "20 liter water can",
        booking_type: 1,
        max_book_qty: 1,
        min_book_qty: 1,
        one_time_sale: {
          sp: 1,
          mrp: 1,
          deposit: 0,
          refund_type: 100,
          refund_amount: 0,
        },
        facility_price: null,
      },
      crate_details: [
        {
          id: null,
          name: null,
          image: null,
          price: null,
          capacity: null,
          description: null,
        },
      ],
    },
    {
      product_details: {
        id: 74,
        name: "20 Liter Water Can",
        image: "",
        price: 70,
        amount: 70,
        refill: {
          sp: 1,
          mrp: 1,
          deposit: 0,
          refund_type: 100,
          refund_amount: 0,
        },
        sku_qty: [12],
        capacity: "20_Liters",
        hsn_code: "125466",
        material: "PET MATERIALS",
        cgst_perc: 6,
        igst_perc: 2,
        life_span: 1000,
        sgst_perc: 6,
        created_at: "14:39:36.173621",
        order_type: 100,
        updated_at: "2024-05-03T14:39:36.173621",
        description: "20 liter water can",
        booking_type: 1,
        max_book_qty: 1,
        min_book_qty: 1,
        one_time_sale: {
          sp: 1,
          mrp: 1,
          deposit: 0,
          refund_type: 100,
          refund_amount: 0,
        },
        facility_price: null,
      },
      crate_details: [
        {
          id: null,
          name: null,
          image: null,
          price: null,
          capacity: null,
          description: null,
        },
      ],
    },
    {
      product_details: {
        id: 64,
        name: "ALUMINUM Bottle",
        image: "",
        price: 1,
        amount: 1,
        refill: {
          sp: 1,
          mrp: 1,
          deposit: 1,
          refund_type: 100,
          refund_amount: 1,
        },
        sku_qty: [3],
        capacity: "300 ml",
        hsn_code: "66787",
        material: "ALUMINUM",
        cgst_perc: 9,
        igst_perc: 9,
        life_span: 15,
        sgst_perc: 9,
        created_at: "11:26:12.927201",
        order_type: 400,
        updated_at: "2024-03-08T11:26:12.927201",
        description: "Aluminum bottle ",
        booking_type: 2,
        max_book_qty: 50,
        min_book_qty: 2,
        one_time_sale: {
          sp: 40,
          mrp: 50,
          deposit: 180,
          refund_type: 100,
          refund_amount: 150,
        },
        facility_price: {
          deposit_price: 50,
        },
      },
      crate_details: [
        {
          id: 27,
          name: "Aluminium bottle crate",
          image: "",
          price: 120,
          capacity: 3,
          description: "Aluminium bottle crate",
        },
      ],
    },
    {
      product_details: {
        id: 63,
        name: "500 ML Steel Bottle(M)",
        image: "",
        price: 1,
        amount: 1,
        refill: {
          sp: 1,
          mrp: 1,
          deposit: 1,
          refund_type: 100,
          refund_amount: 1,
        },
        sku_qty: [3],
        capacity: "500 ML",
        hsn_code: "22110",
        material: "STEEL",
        cgst_perc: 9,
        igst_perc: 9,
        life_span: 5,
        sgst_perc: 9,
        created_at: "11:19:42.610985",
        order_type: 100,
        updated_at: "2024-03-08T11:19:42.610985",
        description: "500 ML Steel Bottle",
        booking_type: 2,
        max_book_qty: 10,
        min_book_qty: 5,
        one_time_sale: {
          sp: 1,
          mrp: 1,
          deposit: 1,
          refund_type: 100,
          refund_amount: 0,
        },
        facility_price: {
          deposit_price: 80,
        },
      },
      crate_details: [
        {
          id: 26,
          name: "500 ML Steel Bottle Crate (M)",
          image: "",
          price: 40,
          capacity: 4,
          description: "500 ML steel bottle crate",
        },
      ],
    },
  ],
};
// const Header = () => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   return (
//     <Box>
//       <DisplayTitle>Recycle Order</DisplayTitle>
//     </Box>
//   );
// };

function RecycleOrders() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const dispatch = useDispatch();
  const damagedExpiredData = useSelector((state) => state.recycleData);
  //useState Hooks
  const [products, setProducts] = React.useState([]);
  const [productId, setProductId] = React.useState("");
  const [damagedQuantity, setDamagedQuantity] = React.useState(0);
  const [expiredQuantity, setExpiredQuantity] = React.useState(0);
  const [recycleDamagedTable, setRecycleDamagedTable] = React.useState([]);
  const [isOpenPlaceRecycleOrder, setIsOpenRecycleOrder] =
    React.useState(false);
  const [isApprove, setIsApprove] = React.useState(false);

  React.useEffect(() => {
    setProducts(productDatas.data);
  }, []);

  React.useEffect(() => {
    console.log("GetProducts");
    console.log(products);
  }, [products]);

  React.useEffect(() => {
    const selectedProduct = products?.filter(
      (item) => item.product_details?.id === productId
    )?.[0];
    if (selectedProduct) {
      dispatch(
        GetDamagedExpired(
          selectedProduct.product_details.capacity,
          selectedProduct.product_details.material
        )
      );
    }
  }, [productId]);

  React.useEffect(() => {
    console.log("Damaged and Expired");
    console.log(damagedExpiredData);
    setExpiredQuantity(damagedExpiredData.providedAccessData.expiredTotal);
    setDamagedQuantity(
      damagedExpiredData.providedAccessData.physicallyDamagedTotal
    );
    setRecycleDamagedTable(damagedExpiredData.providedAccessDataTable);
  }, [damagedExpiredData]);

  const handleClickRecycleOrder = () => {
    const selectedProduct = products?.filter(
      (item) => item.product_details?.id === productId
    )?.[0];
    if (selectedProduct) {
      const reqObj = {
        container_material: selectedProduct.product_details.material,
        container_capacity: selectedProduct.product_details.capacity,
        physically_damaged_quantity: damagedQuantity,
        expired_quantity: expiredQuantity,
      };
      console.log("ReqObj");
      console.log(reqObj);
      dispatch(CreateRecycleOrder(reqObj));
      setIsOpenRecycleOrder(false);
    }
  };

  const handleClickPlaceARecycleOrder = () => {
    setIsOpenRecycleOrder(!isOpenPlaceRecycleOrder);
  };

  const handleClickApprove = (order_id) => {
    const reqObj = {
      order_id: order_id,
      is_accepted: true,
    };
    console.log("reqObj");
    console.log(reqObj);
    setIsApprove(true);
    //dispatch();
  };
  React.useEffect(() => {
    dispatch(GetRecycleOrderTable());
  }, []);

  React.useEffect(() => {
    console.log("Get Table");
    console.log(recycleDamagedTable);
  });

  return (
    <Box m="10px">
      <Box display="flex" flexDirection="column">
        {/* <Header /> */}
        <Box display="flex" justifyContent="flex-end">
          <Button
            color="secondary"
            endIcon={<RecyclingIcon />}
            size="large"
            variant="contained"
            onClick={handleClickPlaceARecycleOrder}
            sx={{ borderRadius: "20px", ...appStyle.general.button.addButton }}
          >
            Place a Recycle Order
          </Button>
        </Box>

        <Box sx={appStyle.general.table.containerForTable}>
          <CustomFilter />
          <TableContainer
            sx={{
              overflow: "auto",
              borderRadius: "10px",
              // backgroundColor: [colors.primary[400]],
            }}
            component={Paper}
          >
            <Table
              sx={{ minWidth: 650, cursor: "pointer", position: "sticky" }}
              // stickyHeader
              aria-label="scrollable table"
              stickyHeader
            >
              <TableHead
                sx={{
                  backgroundColor:
                    appStyle.general.colors(colors).table.tableHeadBackground,
                  color: colors.primary[300],
                  ...appStyle.general.table.tableHead,
                }}
              >
                <TableRow>
                  <TableCell sx={appStyle.general.table.headderRowCell}>
                    Order Id
                  </TableCell>
                  <TableCell sx={appStyle.general.table.headderRowCell}>
                    Physically Damaged Quantity
                  </TableCell>
                  <TableCell sx={appStyle.general.table.headderRowCell}>
                    Expired Quantity
                  </TableCell>
                  <TableCell sx={appStyle.general.table.headderRowCell}>
                    Order Status
                  </TableCell>
                  <TableCell sx={appStyle.general.table.headderRowCell}>
                    Container Capacity
                  </TableCell>
                  <TableCell sx={appStyle.general.table.headderRowCell}>
                    Container Material
                  </TableCell>
                  <TableCell sx={appStyle.general.table.headderRowCell}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                {data.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      sx={appStyle.general.table.bodyRowCell}
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {row.order_id}
                    </TableCell>
                    <TableCell
                      sx={appStyle.general.table.bodyRowCell}
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {row.physically_damaged_quantity}
                    </TableCell>
                    <TableCell
                      sx={appStyle.general.table.bodyRowCell}
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {row.expired_quantity}
                    </TableCell>
                    <TableCell
                      sx={appStyle.general.table.bodyRowCell}
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {row.order_status}
                    </TableCell>
                    <TableCell
                      sx={appStyle.general.table.bodyRowCell}
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {row.container_capacity}
                    </TableCell>
                    <TableCell
                      sx={appStyle.general.table.bodyRowCell}
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {row.container_material}
                    </TableCell>
                    <TableCell
                      sx={appStyle.general.table.bodyRowCell}
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {!isApprove && (
                        <Box sx={appStyle.general.button.addButtonBox}>
                          <Button
                            variant={appFonts.tableSecondaryButtonVarient}
                            color="error"
                            size={appFonts.tableButtonSize}
                            sx={appStyle.general.button.blueButton}
                            startIcon={<CheckCircleOutlineIcon />}
                          >
                            Approve
                          </Button>
                          <Button
                            variant={appFonts.tableSecondaryButtonVarient}
                            color="error"
                            size={appFonts.tableButtonSize}
                            sx={appStyle.general.button.redButton}
                            startIcon={<Cancel />}
                          >
                            Cancel
                          </Button>
                        </Box>
                      )}
                      {isApprove && (
                        <Box>
                          <Button
                            color="secondary"
                            variant="contained"
                            size={appFonts.tableButtonSize}
                            startIcon={<SentimentSatisfiedAltIcon />}
                            sx={appStyle.general.button.blueButton}
                          >
                            Approved
                          </Button>
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {isOpenPlaceRecycleOrder && (
        <PlaceARecycleOrder
          open={isOpenPlaceRecycleOrder}
          close={handleClickPlaceARecycleOrder}
          productId={productId}
          setProductId={setProductId}
          products={products}
          handleClickRecycleOrder={handleClickRecycleOrder}
          setExpiredQuantity={setExpiredQuantity}
          setDamagedQuantity={setDamagedQuantity}
          damagedQuantity={damagedQuantity}
          expiredQuantity={expiredQuantity}
        />
      )}
    </Box>
  );
}

export default RecycleOrders;

function PlaceARecycleOrder({
  open,
  productId,
  setProductId,
  products,
  damagedQuantity,
  expiredQuantity,
  handleClickRecycleOrder,
  setExpiredQuantity,
  setDamagedQuantity,
  close,
}) {
  const handleChangeDamaged = (e) => {
    const inputValue = e.target.value;
    if (
      inputValue === "" ||
      (parseInt(inputValue) >= 0 && parseInt(inputValue) <= 9)
    ) {
      setDamagedQuantity(inputValue);
    }
  };
  const handleChangeExpired = (e) => {
    const inputValue = e.target.value;
    if (
      inputValue === "" ||
      (parseInt(inputValue) >= 0 && parseInt(inputValue) <= 9)
    ) {
      setExpiredQuantity(inputValue);
    }
  };
  return (
    <Dialog open={open} onClose={close}>
      <Box p="10px" display="flex" boxShadow={3} justifyContent="space-between">
        <Typography fontWeight="bold" m="10px" sx={{ fontSize: "15px" }}>
          Place a Recycle Order
        </Typography>
        <IconButton edge="start" color="inherit" aria-label="close">
          <CloseIcon onClick={close} />
        </IconButton>
      </Box>
      <Box p="10px">
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "1.2rem",
            fontWeight: "bold",
            color: "royalblue",
          }}
        >
          Select Your Product Here!!!!
        </Typography>
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Box p="20px">
            <FormControl fullWidth sx={{ m: 1, maxWidth: 200, margin: "auto" }}>
              <InputLabel id="demo-simple-select-label">
                Select Products
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={productId}
                label="Select Products"
                onChange={(e) => {
                  setProductId(e.target.value);
                }}
              >
                {products?.map((row) => {
                  return (
                    <MenuItem
                      key={row.product_details.id}
                      value={row.product_details.id}
                    >
                      {row.product_details.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          <TextField
            id="outlined-basic"
            label="Physically Damaged Quantity"
            variant="outlined"
            value={damagedQuantity}
            error={
              parseInt(damagedQuantity) < 0 || parseInt(damagedQuantity) > 9
            }
            helperText={
              parseInt(damagedQuantity) < 0 || parseInt(damagedQuantity) > 9
                ? "Value should be between 0 and 9"
                : ""
            }
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            onChange={handleChangeDamaged}
          ></TextField>

          <TextField
            sx={{ marginTop: "10px" }}
            id="outlined-basic"
            label="Expired Quantity"
            variant="outlined"
            value={expiredQuantity}
            error={
              parseInt(expiredQuantity) < 0 || parseInt(expiredQuantity) > 9
            }
            helperText={
              parseInt(expiredQuantity) < 0 || parseInt(expiredQuantity) > 9
                ? "Value should be between 0 and 9"
                : ""
            }
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            onChange={handleChangeExpired}
          />
        </Box>
      </Box>

      <Box display="flex" p="15px" justifyContent="center">
        <Button
          onClick={handleClickRecycleOrder}
          variant="contained"
          color="secondary"
          sx={appStyle.general.button.blueButton}
        >
          Create Recycle Order
        </Button>
      </Box>
    </Dialog>
  );
}
