import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";
import home from "../Assests/newMobileIcon/home.svg";
import homeDark from "../Assests/newMobileIcon/darkTheme/Home/home.svg";
import Order from "../Assests/newMobileIcon/ORDER.svg";
import OrderDark from "../Assests/newMobileIcon/darkTheme/Full Menu/Orders.svg";
import myOrders from "../Assests/newMobileIcon/Orders.svg";
import myOrdersDark from "../Assests/newMobileIcon/darkTheme/Full Menu/Orders.svg";
import Payment from "../Assests/newMobileIcon/Transactions.svg";
import PaymentDark from "../Assests/newMobileIcon/darkTheme/Full Menu/Transactions.svg";
import Bank from "../Assests/newMobileIcon/Personal Bank.svg";
import BankDark from "../Assests/newMobileIcon/darkTheme/Full Menu/Personal Bank.svg";
import Deposit from "../Assests/newMobileIcon/Deposits.svg";
import DepositDark from "../Assests/newMobileIcon/darkTheme/Full Menu/Deposits.svg";
import wallet from "../Assests/newMobileIcon/Water Wallet.svg";
import walletDark from "../Assests/newMobileIcon/darkTheme/Full Menu/Water Wallet.svg";
import Subcribe from "../Assests/newMobileIcon/Subscriptions.svg";
import subscribeDark from "../Assests/newMobileIcon/darkTheme/Full Menu/Subscriptions.svg";
import Refer from "../Assests/newMobileIcon/Refer & Earn.svg";
import referDark from "../Assests/newMobileIcon/darkTheme/Full Menu/Refer & Earn.svg";
import support from "../Assests/newMobileIcon/Customer.svg";
import supportDark from "../Assests/newMobileIcon/darkTheme/Full Menu/help-desk.svg";
import profile from "../Assests/newMobileIcon/Profile.svg";
import profileDark from "../Assests/newMobileIcon/darkTheme/Full Menu/Profile.svg";
import legal from "../Assests/newMobileIcon/Legal.svg";
import legalDark from "../Assests/newMobileIcon/darkTheme/Full Menu/Legal.svg";
import logout from "../Assests/newMobileIcon/Logout.svg";
import logoutDark from "../Assests/newMobileIcon/darkTheme/Full Menu/Logout.svg";
import Scanqr from "../Assests/newMobileIcon/SCAN.svg";
import ScanqrDark from "../Assests/newMobileIcon/darkTheme/Home/SCAN.svg";
import Menu from "../Assests/newMobileIcon/MENU.svg";
import menuDark from "../Assests/newMobileIcon/darkTheme/Home/MENU.svg";
import addressBook from "../Assests/newMobileIcon/Address Book.svg";
import addressBookDark from "../Assests/newMobileIcon/darkTheme/Full Menu/Address Book.svg";
import location from "../Assests/newMobileIcon/Booking/Delivery Address.svg";
import locationDark from "../Assests/newMobileIcon/darkTheme/Booking/Delivery Address.svg";
import calenderIcon from "../Assests/newMobileIcon/Booking/Delivery Date.svg";
import calenderIconDark from "../Assests/newMobileIcon/darkTheme/Booking/Delivery Date.svg";
import deliveryCanIcon from "../Assests/newMobileIcon/Booking/Delivery.svg";
import deliveryCanIconDark from "../Assests/newMobileIcon/darkTheme/Booking/Delivery.svg";
import returnCanIcon from "../Assests/newMobileIcon/Booking/Return.svg";
import returnCanIconDark from "../Assests/newMobileIcon/darkTheme/Booking/Return.svg";
import coupon from "../Assests/newMobileIcon/Booking/Coupon.svg";
import couponDark from "../Assests/newMobileIcon/darkTheme/Booking/Coupon.svg";
import otp from "../Assests/newMobileIcon/Booking/OTP.svg";
import otpDark from "../Assests/newMobileIcon/darkTheme/Booking/OTP.svg";
import otpMenu from "../Assests/newMobileIcon/OTP.svg";
import otpDarkMenu from "../Assests/newMobileIcon/darkTheme/Full Menu/OTP.svg";
import infoIcon from "../Assests/newMobileIcon/Booking/info-circle.svg";
import infoIconDark from "../Assests/newMobileIcon/Booking/info-circle-white.svg";
import sucessHand from "../Assests/newMobileIcon/darkTheme/Subscription Successful/Success hand.svg";
import sucessHandDark from "../Assests/newMobileIcon/darkTheme/Subscription Successful/Subscription Updated.svg";
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        grey: {
          100: "#e0e0e0",
          200: "#c2c2c2",
          300: "#a3a3a3",
          400: "#858585",
          500: "#666666",
          600: "#525252",
          700: "#3d3d3d",
          800: "#292929",
          900: "#141414",
        },
        primary: {
          100: "#d0d1d5",
          200: "#a1a4ab",
          300: "#727681",
          400: "#1F2A40",
          500: "#141b2d",
          600: "#101624",
          700: "#0c101b",
          800: "#080b12",
          900: "#040509",
        },
        greenAccent: {
          100: "#dbf5ee",
          200: "#b7ebde",
          300: "#94e2cd",
          400: "#70d8bd",
          500: "#4cceac",
          600: "#3da58a",
          700: "#2e7c67",
          800: "#1e5245",
          900: "#0f2922",
        },
        redAccent: {
          100: "#f8dcdb",
          200: "#f1b9b7",
          300: "#e99592",
          400: "#e2726e",
          500: "#db4f4a",
          600: "#af3f3b",
          700: "#832f2c",
          800: "#58201e",
          900: "#2c100f",
        },
        blueAccent: {
          100: "#e1e2fe",
          200: "#c3c6fd",
          300: "#a4a9fc",
          400: "#868dfb",
          500: "#6870fa",
          600: "#535ac8",
          700: "#3e4396",
          800: "#2a2d64",
          900: "#151632",
        },

        skyBlueAccent: {
          100: "#87CEEB",
        },
        white: {
          100: "#fff",
        },
        basic: {
          body_backGround: "",
          primary_test_color: " white",
          dark_blue: "white",
          light_gray: "#fff",
          black: "#fff",
          card_color: "#324F7D",
          order_heading: "#63b6ff",
          white_mode: "#58595b",
          svg_color: "#fff",
          dashbord_Appbar: "#1e3e70",
          lightBlue: "#324F7D",
          wallet_card: "#324F7D",
          secondary_card: "#1f4d95",
          sidebar_default: "#141b2d",
          sidebar_active: "#0e2954",
          sidebar_hover: "#0e2954",
          secondary_text: "#fff",
          homepage_cards: "#324F7D",
          info_card: "#068fff",
          footer_home: "#0d316a",
          header_test_color: "#63b6ff",
          footer_icon_color: "#068fff",
          app_bar: "#1e3e70",
          footer_indicator: "#068fff",
          check_out_card: "#0e3469",
        },
        mobileIcon: {
          home: homeDark,
          order: OrderDark,
          myOrders: myOrdersDark,
          transaction: PaymentDark,
          bankIcon: BankDark,
          depositIcon: DepositDark,
          walletIcon: walletDark,
          subcribeIcon: subscribeDark,
          referIcon: referDark,
          supportIcon: supportDark,
          profileIcon: profileDark,
          legalIcon: legalDark,
          logoutIcon: logoutDark,
          scanIcon: ScanqrDark,
          menuIcon: menuDark,
          addressIcon: addressBookDark,
          locationIcon: locationDark,
          CalenderIcon: calenderIconDark,
          deliveryCanIcon: deliveryCanIconDark,
          ReturnCanIcon: returnCanIconDark,
          couponIcon: couponDark,
          otpIcon: otpDark,
          infoIconIcon: infoIconDark,
          sucessIcon: sucessHandDark,
          menuOtp: otpDarkMenu,
        },
      }
    : {
        grey: {
          100: "#141414",
          200: "#292929",
          300: "#3d3d3d",
          400: "#525252",
          500: "#666666",
          600: "#858585",
          700: "#a3a3a3",
          800: "#c2c2c2",
          900: "#e0e0e0",
        },
        primary: {
          100: "#040509",
          200: "#080b12",
          300: "#0c101b",
          400: "#e8e8e8", // manually changed
          500: "#fafafa",
          600: "#ededed",
          700: "#727681",
          800: "#a1a4ab",
          900: "#d0d1d5",
        },
        greenAccent: {
          100: "#0f2922",
          200: "#1e5245",
          300: "#2e7c67",
          400: "#3da58a",
          500: "#4cceac",
          600: "#70d8bd",
          700: "#94e2cd",
          800: "#b7ebde",
          900: "#dbf5ee",
        },
        redAccent: {
          100: "#2c100f",
          200: "#58201e",
          300: "#832f2c",
          400: "#af3f3b",
          500: "#db4f4a",
          600: "#e2726e",
          700: "#e99592",
          800: "#f1b9b7",
          900: "#f8dcdb",
        },
        blueAccent: {
          100: "#151632",
          200: "#2a2d64",
          300: "#3e4396",
          400: "#535ac8",
          500: "#6870fa",
          600: "#868dfb",
          700: "#a4a9fc",
          800: "#c3c6fd",
          900: "#e1e2fe",
        },
        skyBlueAccent: {
          100: "#4598ba",
        },
        basic: {
          body_backGround: "",
          primary_test_color: "#58595b",
          dark_blue: "#58595b",
          light_gray: "#7e7e7e",
          black: "black",
          card_color: "#fff",
          order_heading: "#7e7e7e",
          white_mode: "#fff",
          svg_color: "#31a6ff",
          dashbord_Appbar: "#bfefff",
          lightBlue: "#bfefff",
          wallet_card: "#0690fd",
          secondary_card: "#fff",
          sidebar_default: "#e0e0e0",
          sidebar_active: "#cfd8dc",
          sidebar_hover: "#bfefff",
          secondary_text: "#068fff",
          homepage_cards: "#caedff",
          info_card: "#068fff",
          footer_home: "#caedff",
          header_test_color: "#068fff",
          footer_icon_color: "#068fff",
          app_bar: "#fff",
          footer_indicator: "#068fff",
          check_out_card: "#fff",
        },
        mobileIcon: {
          home: home,
          order: Order,
          myOrders: myOrders,
          transaction: Payment,
          bankIcon: Bank,
          depositIcon: Deposit,
          walletIcon: wallet,
          subcribeIcon: Subcribe,
          referIcon: Refer,
          supportIcon: support,
          profileIcon: profile,
          legalIcon: legal,
          logoutIcon: logout,
          scanIcon: Scanqr,
          menuIcon: Menu,
          addressIcon: addressBook,
          locationIcon: location,
          CalenderIcon: calenderIcon,
          deliveryCanIcon: deliveryCanIcon,
          ReturnCanIcon: returnCanIcon,
          couponIcon: coupon,
          otpIcon: otp,
          infoIconIcon: infoIcon,
          sucessIcon: sucessHand,
          menuOtp: otpMenu,
        },
      }),
});

export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              main: colors.primary[100],
            },
            secondary: {
              main: "#2d64e8",
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: colors.primary[500],
            },
          }
        : {
            // palette values for light mode
            primary: {
              main: colors.primary[100],
            },
            secondary: {
              main: "#2d64e8",
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: "#fcfcfc",
            },
          }),
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14,
      },
      semiBold: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16,
        fontWeight: 600,
      },
      bold: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16,
        fontWeight: "bold",
      },
      title: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 24,
        fontWeight: "bold",
      },
      subTitle: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14,
        // fontWeight: "bold",
      },
      text: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  // console.log(colorMode);
  return [theme, colorMode];
};
