import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import appFonts from "../../AppMeta/appFonts";
import { tokens } from "../../AppMeta/theme";
import appStyle from "../../AppMeta/appStyle";

function DisplayTitle({ children = "" }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box sx={{ margin: "10px" }}>
      <Typography
        // variant={appFonts.title}
        sx={{
          color: appStyle.general.colors(colors).title,
          ...appStyle.general.displayTitle.fontSize,
        }}
      >
        {children.toUpperCase()}
      </Typography>
    </Box>
  );
}

export default DisplayTitle;
