import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

function CustomSelect({
  name,
  input,
  handleChange,
  defaultValue,
  variant = "standard",
  sx,
}) {
  return (
    <FormControl sx={{ ...sx }} variant={variant} size="small">
      <InputLabel id="demo-select-small-label">{name}</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        name={name}
        value={defaultValue}
        label={name}
        onChange={handleChange}
        color="secondary"
      >
        <MenuItem value="">
          <em></em>
        </MenuItem>
        {input?.map(({ value, menuItem }) => {
          return <MenuItem value={value}>{menuItem}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
}

export default CustomSelect;
