import HomeTwoToneIcon from "@mui/icons-material/HomeTwoTone";
import ManageAccountsTwoToneIcon from "@mui/icons-material/ManageAccountsTwoTone";
import AddShoppingCartRoundedIcon from "@mui/icons-material/AddShoppingCartRounded";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import CorporateFareRoundedIcon from "@mui/icons-material/CorporateFareRounded";
import QrCodeScannerRoundedIcon from "@mui/icons-material/QrCodeScannerRounded";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import QrCodeRoundedIcon from "@mui/icons-material/QrCodeRounded";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import SummarizeRoundedIcon from "@mui/icons-material/SummarizeRounded";
import HelpCenterRoundedIcon from "@mui/icons-material/HelpCenterRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import BookWaterIcon from "../../Assests/FOM/bw_logo.png";

export const sideBarData = {
  bookWaterDashboard: [
    {
      title: "Home",
      path: "/dashboard/home",
      icon: <HomeTwoToneIcon />,
    },
    {
      title: "Stock Keeping Unit",
      path: "/dashboard/products",
      icon: <AddShoppingCartRoundedIcon />,
    },

    {
      title: "Users management",
      path: "/dashboard/user-management",
      icon: <ManageAccountsRoundedIcon />,
    },
    {
      title: "Water station",
      path: "/dashboard/water-station",
      icon: <CorporateFareRoundedIcon />,
    },

    {
      title: "Facility Order Management",
      path: "/dashboard/facility-order-management",
      icon: <ListAltRoundedIcon />,
    },
    {
      title: "Organisation Order Management",
      path: "/dashboard/organisation-order-management",
      icon: <ListAltRoundedIcon />,
    },
    {
      title: "All Orders(Organization)",
      path: "/dashboard/organization_orders_List",
      icon: <ListAltRoundedIcon />,
    },
    {
      title: "BookWater Order Management",
      path: "/dashboard/bookwater-order-management",
      icon: <ListAltRoundedIcon />,
    },
    {
      title: "QR/Barcodes Management",
      path: "/dashboard/codesManagement",
      icon: <QrCodeRoundedIcon />,
    },

    {
      title: "Rewards and offers",
      path: "/dashboard/organization",
      icon: <LocalOfferRoundedIcon />,
    },
    {
      title: "Reports",
      path: "/dashboard/organization",
      icon: <SummarizeRoundedIcon />,
    },
    {
      title: "Support",
      path: "/dashboard/organization",
      icon: <HelpCenterRoundedIcon />,
    },
    {
      title: "Settings",
      path: "/dashboard/settings",
      icon: <SettingsRoundedIcon />,
    },
    {
      title: "Recycle Orders",
      path: "/dashboard/recycleOrders",
      icon: <ListAltRoundedIcon />,
    },
    {
      title: "Api Documentation",
      path: "/dashboard/api_documentation",
      icon: <ListAltRoundedIcon />,
    },
  ],
  facilityDashBoard: [
    {
      title: "Home",
      path: "/dashboard/home",
      icon: <HomeTwoToneIcon />,
    },
    {
      title: "Stock Keeping Unit",
      path: "/dashboard/products",
      icon: <AddShoppingCartRoundedIcon />,
    },
    {
      title: "Users management",
      path: "/dashboard/user-management",
      icon: <ManageAccountsRoundedIcon />,
    },
    {
      title: "Water station",
      path: "/dashboard/water-station",
      icon: <CorporateFareRoundedIcon />,
    },
    {
      title: "Facility Order Management",
      path: "/dashboard/facility-order-management",
      icon: <ListAltRoundedIcon />,
    },
    {
      title: "Rewards and offers",
      path: "/dashboard/organization",
      icon: <LocalOfferRoundedIcon />,
    },
    {
      title: "Reports",
      path: "/dashboard/organization",
      icon: <SummarizeRoundedIcon />,
    },
    {
      title: "Support",
      path: "/dashboard/organization",
      icon: <HelpCenterRoundedIcon />,
    },
    {
      title: "Settings",
      path: "/dashboard/settings",
      icon: <SettingsRoundedIcon />,
    },
  ],
  organisationDashboard: [
    {
      title: "Home",
      path: "/dashboard/home",
      icon: <HomeTwoToneIcon />,
    },
    {
      title: "Organisation Order Management",
      path: "/dashboard/organisation-order-management",
      icon: <ListAltRoundedIcon />,
    },
    {
      title: "Accounts",
      path: "/dashboard/organisation-order-management",
      icon: <ListAltRoundedIcon />,
    },
    {
      title: "Deposits",
      path: "/dashboard/organisation-order-management",
      icon: <ListAltRoundedIcon />,
    },

    {
      title: "Rewards and offers",
      path: "/dashboard/organization",
      icon: <LocalOfferRoundedIcon />,
    },
    {
      title: "Support",
      path: "/dashboard/organization",
      icon: <HelpCenterRoundedIcon />,
    },
    {
      title: "Settings",
      path: "/dashboard/settings",
      icon: <SettingsRoundedIcon />,
    },
    {
      title: "Legal Information",
      path: "/dashboard/legalinformation",
      icon: <SettingsRoundedIcon />,
    },
  ],
};
