// import * as React from "react";
// import Button from "@mui/material/Button";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";
// import { Description } from "@mui/icons-material";
// import appStyle from "../../AppMeta/appStyle";

// export default function CustomConfirmationDialog({
//   dialogTitle = "confirmation",
//   openConfirmationDialog,
//   setOpenConfirmationDialog,
//   description,
//   setIsConfirmed,
// }) {
//   const handleNo = () => {
//     setOpenConfirmationDialog(false);
//     setIsConfirmed(false);
//   };

//   const handleYes = () => {
//     // console.log("Yes");
//     setIsConfirmed(true);
//   };

//   return (
//     <React.Fragment>
//       <Dialog
//         open={openConfirmationDialog}
//         onClose={handleNo}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             {description}
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleNo} sx={appStyle.general.button.redButton}>
//             No
//           </Button>
//           <Button
//             onClick={handleYes}
//             autoFocus
//             sx={appStyle.general.button.blueButton}
//           >
//             Yes
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </React.Fragment>
//   );
// }

/**
 * @author : Janarthanan
 * @Date : 11/11/24
 * @description : Custom Confirmation Dialog Component.
 */

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import appStyle from "../../AppMeta/appStyle";

const CustomConfirmationDialog = ({
  open,
  onClose,
  dialogTitle,
  dialogContent,
  onConfirm,
  disableConfirmBtn = false,
}) => {
  return (
    <Dialog
      open={open}
      // onClose={onClose}
      fullWidth
      maxWidth="xs"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
      <DialogActions>
        <Button
          sx={appStyle.general.button.redButton}
          color="error"
          variant="contained"
          onClick={onClose}
          startIcon={<CloseIcon />}
        >
          Cancel
        </Button>
        <Button
          disabled={disableConfirmBtn}
          sx={appStyle.general.button.blueButton}
          color="primary"
          variant="contained"
          onClick={onConfirm}
          startIcon={<CheckIcon />}
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomConfirmationDialog;
