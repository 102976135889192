/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Reducers for creating, updating and adding roles list
 * @date 26/05/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import { Reducer } from "redux";
import actionType from "../actionTypes";

const initialState = {
  error: false,
  msg: "",
  wfsList: [],
  wfsLineId: [],
  wfsLinesList: [],
  new_wfs_id: null,
  wfsBatchList: [],
};

const wfsDetailsReducer = (state = initialState, action) => {
  // console.log(`action type: ${action.type}`);
  // console.log(action);
  switch (action.type) {
    case actionType.ADD_WFS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        new_wfs_id: action.payload.data[0],
      };
    case actionType.GET_WFS_LIST:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        wfsList: action.payload.data,
      };

    case actionType.ADD_LINES_WFS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        wfsLineId: action.payload.data,
      };
    case actionType.GET_LINES_WFS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        wfsLinesList: action.payload.data,
      };
    case actionType.CREATE_WFS_BATCH:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        // wfsLinesList: action.payload.data,
      };
    case actionType.GET_WFS_BATCH:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        wfsBatchList: action.payload.data,
      };
    default:
      return state;
  }
};

export default wfsDetailsReducer;
