/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

// import { url } from "../apis";
import actionType from "../actionTypes";
//  import * as toastMessage from "../../Assets/Constants/ToastMessages";
//  import useAxios from "../../Hooks/useAxios";
// import useAxios from "../../Axios/useAxios";
import useAxios from "../../Hooks/useAxios";
//  import * as AppMetaData from "./../../Assets/Constants/AppMetaData";
import { toast } from "react-toastify";
 
export const UploadFile = (uploadedFile) => {
  const httpStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
  };
  let file = new FormData();
  console.log(uploadedFile);
  file.append("name", "filename");
  file.append("filename", uploadedFile);
  let error = "";
  let api = useAxios();
  return async (dispatch) => {
    let response = await api.post("/PSCM/file/upload", file).catch((e) => {
      error = e;
      console.log(`Error: ${e}`);
    });
    if (error !== "" && error.message.includes("400")) {
    } else if (response.status === httpStatus.SUCCESS) {
      console.log(
        `response.status: ${response.status}, ${JSON.stringify(response)}`
      );
      dispatch({
        type: actionType.UPLOAD_FILE,
        payload: { data: response.data, statusFlag: true },
      });
      toast.success("Successfully Uploaded", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      console.log("Unhandled Error: " + error);
    }
  };
};

//Get File From the server
export const getFiles = (filename) => {
  console.log("------- (getFiles) getFiles Action--------");

  console.log(filename);
  const url = "https://staging.bookwater.com";
  let fileType;
  if (filename !== "") {
    fileType = filename.split(".")[1]; 
  } else {
    fileType = "png";
  }
  let token = localStorage.getItem("token");
  if (token === "" || token === null) {
    token = sessionStorage.getItem("token");
  }
  if (token !== "") {
    return async (dispatch) => {
      await fetch(`${url}/PSCM/file/${filename}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          //   "Content-Type": "image/jpeg",
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download

          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `file.${fileType}`);
          document.body.appendChild(link);
          link.click();
          // console.log("getFilesAction" + link.href);
          dispatch({
            type: actionType.GET_FILES,
            payload: link.href,
          });
          toast.success("Successfully Downloaded", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        })
        .catch((error) => {
          console.log("getFiles Action Error : " + error);
        });
    };
  } else {
    //  toast.dark(toastMessage.TOKEN_INVALID, {
    //    position: "bottom-right",
    //    autoClose: 5000,
    //    type: "error",
    //  });
  }
};
