/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description API access provider table with list of APIs to choose and provide access
 * @date 26/07/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TablePagination,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { AddAccessForRoles } from "../../../Redux/Actions/rolesAccessAction";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { appColors } from "../../../AppMeta/appColors";
import CustomConfirmationDialog from "../../../Components/CustomComp/CustomConfirmationDialog";
import { tokens } from "../../../AppMeta/theme";
import appStyle from "../../../AppMeta/appStyle";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CustomFilter from "../../../Components/CustomComp/CustomFilter";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "&.head": {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  "&.body": {
    fontSize: 14,
  },
}));

export default function ApiAccessProviderTable({
  listOfApis,
  selectedRole,
  isEditAccessMode,
  setNewsAccessApis,
  setNewAccessHttps,
  pageNumber,
  setPageNumber,
  setPageSize,
  pageSize,
  ascOrDesc,
  setAscOrDesc,
  lastPageNumber,
}) {
  // Required variables declared here
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const [checkedItems, setCheckedItems] = React.useState([]);
  const [selectedApis, setSelectedApis] = React.useState([]);
  const [finalArray, setFinalArray] = React.useState([]);
  const [selectedHttpsMethod, setSelectedHttpsMethod] = React.useState([]);

  const [openDialog, setOpenDialog] = React.useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    React.useState(false);
  const [confirmationDescription, setConfirmationDescription] =
    React.useState("");
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  //  On user selects the APIs list in the table,
  //  this function will set all the selected APIs in an array and also remove if it is deselected
  const handleChange = (event, index, data) => {
    console.log(index);
    console.log(data);
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = event.target.checked;
    console.log(newCheckedItems);

    setCheckedItems(newCheckedItems);
    if (event.target.checked === true) {
      setSelectedApis([
        ...selectedApis,
        { apiName: data.api_name, apiId: index, description: data.description },
      ]);

      // Executes only while editing the roles apis access in edit dialog
      if (isEditAccessMode) {
        setNewsAccessApis([
          ...selectedApis,
          { apiName: data.api_name, apiId: index },
        ]);
      }

      setSelectedHttpsMethod([
        ...selectedHttpsMethod,
        { httpsMethod: data.https_method, httpsId: index },
      ]);
      // Executes only while editing the roles apis access in edit dialog
      if (isEditAccessMode) {
        setNewAccessHttps([
          ...selectedHttpsMethod,
          { httpsMethod: data.https_method, httpsId: index },
        ]);
      }
    } else if (event.target.checked === false) {
      selectedApis.map((selectedData, indexS) => {
        if (selectedData.apiId === index) {
          selectedApis.splice(indexS, 1);
          //   console.log(selectedApis);
          setSelectedApis(selectedApis);
          // Executes only while editing the roles apis access in edit dialog
          if (isEditAccessMode) {
            setNewsAccessApis(selectedApis);
          }
        }
      });
      selectedHttpsMethod.map((selectedData, indexH) => {
        if (selectedData.httpsId === index) {
          selectedHttpsMethod.splice(indexH, 1);
          //   console.log(selectedHttpsMethod);
          setSelectedHttpsMethod(selectedHttpsMethod);
          // Executes only while editing the roles apis access in edit dialog
          if (isEditAccessMode) {
            setNewAccessHttps(selectedHttpsMethod);
          }
        }
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    console.log(selectedApis);
    console.log(selectedHttpsMethod);
  }, [selectedApis, selectedHttpsMethod]);

  // This function call the Add access for role API with reqObj
  const handleGrantAccess = () => {
    console.log(selectedApis);
    const apisArray = [];
    const httpsMethodArray = [];
    selectedApis.map((apis) => {
      apisArray.push(apis.apiName);
    });
    selectedHttpsMethod.map((httpsMethod) => {
      httpsMethodArray.push(httpsMethod.httpsMethod);
    });
    console.log(apisArray);
    console.log(httpsMethodArray);
    const reqObj = {
      role_name: selectedRole,
      api_name: apisArray,
      https_method: httpsMethodArray,
    };
    dispatch(AddAccessForRoles(reqObj));
  };

  const handleClickOpenDialog = () => {
    if (selectedRole !== "") {
      setOpenDialog(true);
    } else {
      handleGrantAccess();
    }
  };
  // Pagenumber will be decremented
  const pageNumberBackward = () => {
    if (pageNumber - 1 > 0) {
      setPageNumber(pageNumber - 1);
      // setdisableBtn(false);
      // setSearchOrderFlag(true);
    }
  };

  // Pagenumber will be incremented
  const pageNumberForward = () => {
    if (listOfApis.length > 0) {
      setPageNumber(pageNumber + 1);
      // setSearchOrderFlag(true);
    }
  };

  const handleConfirm = () => {
    setOpenConfirmationDialog(true);
    setConfirmationDescription("Are you sure you want to provide access?");
    // setIsConfirmed(true);
  };

  React.useEffect(() => {
    if (isConfirmed) {
      handleGrantAccess();
      setIsConfirmed(false);
    }
  }, [isConfirmed]);
  // jsx starts here
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        // width: "90vw",
      }}
    >
      {isEditAccessMode === false && selectedApis.length > 0 && (
        <Box
          sx={{
            padding: "10px",
            position: "fixed",
            right: "1rem",
            bottom: "4rem",
            ...appStyle.general.button.addButtonBox,
          }}
        >
          <Button
            onClick={handleClickOpenDialog}
            variant="contained"
            color="secondary"
            label="Add new role"
            sx={appStyle.general.button.addButton}
            startIcon={<DoneAllIcon />}
          >
            Grant Access
          </Button>
          {/* <CustomButton
            label="Grant Access"
            handleClick={handleClickOpenDialog}
            variant="contained"
          /> */}
        </Box>
      )}

      {/* Pagination and pageSize */}
      {/* <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          gap: "15px",
          padding: "10px",
          marginTop: "10px",
        }}
      >
        <Box>
          <Button
            sx={{
              cursor: "pointer",
            }}
            onClick={pageNumberBackward}
          >
            <ArrowBackIosIcon />
          </Button>
        </Box>
        <Typography
          variant="button"
          display="block"
          gutterBottom
          sx={{
            marginLeft: "10px",
            marginRight: "10px",
            backgroundColor:
              appStyle.general.colors(colors).pageNumberBackground,
            color: "white",
            padding: "5px",
            width: "40px",
            height: "35px",
            borderRadius: "15px",
            textAlign: "center",
          }}
        >
          {pageNumber}
        </Typography>
        <Box>
          <Button
            sx={{
              cursor: "pointer",
            }}
            onClick={pageNumberForward}
            disabled={pageNumber < lastPageNumber ? false : true}
          >
            <ArrowForwardIosIcon />
          </Button>
        </Box>
        <Box>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Page size</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={pageSize}
              label="Page size"
              onChange={(e) => {
                setPageSize(e.target.value);
                // setSearchOrderFlag(true);
              }}
            >
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={500}>500</MenuItem>
              <MenuItem value={1000}>1000</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box> */}
      <Box sx={appStyle.general.table.containerForTable}>
        <CustomFilter />
        <TableContainer
          sx={appStyle.general.table.tableContainer}
          // size="small"
          // component={Paper}
          // sx={{ maxWidth: { xs: 350, lg: "100%" } }}
        >
          <Table aria-label="customized table" size="small" stickyHeader>
            <TableHead
              sx={{
                backgroundColor:
                  appStyle.general.colors(colors).table.tableHeadBackground,
                color: colors.primary[300],
                ...appStyle.general.table.tableHead,
              }}
            >
              <TableRow>
                <StyledTableCell sx={appStyle.general.table.headderRowCell}>
                  Select
                </StyledTableCell>
                <StyledTableCell sx={appStyle.general.table.headderRowCell}>
                  API Name
                </StyledTableCell>
                <StyledTableCell sx={appStyle.general.table.headderRowCell}>
                  HTTP Method
                </StyledTableCell>
                <StyledTableCell sx={appStyle.general.table.headderRowCell}>
                  Description
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: colors.primary[600] }}>
              {(rowsPerPage > 0
                ? listOfApis.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : listOfApis
              ).map((row, index) => (
                <TableRow key={index}>
                  <StyledTableCell sx={appStyle.general.table.bodyRowCell}>
                    <Checkbox
                      checked={checkedItems[index] || false}
                      onChange={(event) => handleChange(event, index, row)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </StyledTableCell>
                  <StyledTableCell sx={appStyle.general.table.bodyRowCell}>
                    {row.api_name}
                  </StyledTableCell>
                  <StyledTableCell sx={appStyle.general.table.bodyRowCell}>
                    {row.https_method}
                  </StyledTableCell>
                  <StyledTableCell sx={appStyle.general.table.bodyRowCell}>
                    {row.description}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={appStyle.general.table.pagination}>
          <TablePagination
            rowsPerPageOptions={[25, 100, 500, 1000]}
            component="div"
            count={listOfApis.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
      {openDialog ? (
        <>
          {" "}
          <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Features Selected to provide for this role{" "}
            </DialogTitle>
            <DialogContent>
              {selectedApis.map((api) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                      padding: "10px",
                    }}
                  >
                    <Chip
                      variant="outlined"
                      color="success"
                      size="large"
                      // icon={icon}
                      sx={{ height: "100px" }}
                      label={
                        <div
                          style={{
                            padding: "10px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <div>
                            <span style={{ fontWeight: "bold" }}>API Name</span>
                            : {api.apiName}
                          </div>

                          <div>
                            <span style={{ fontWeight: "bold" }}>
                              Description
                            </span>
                            : {api.description}
                          </div>
                        </div>
                      }
                      // onDelete={
                      //   data.label === "React" ? undefined : handleDelete(data)
                      // }
                      // Set the desired height
                    />
                  </Box>
                );
              })}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDialog(false)}>Back</Button>
              <Button onClick={handleConfirm} autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        ""
      )}

      {openConfirmationDialog && (
        <CustomConfirmationDialog
          openConfirmationDialog={openConfirmationDialog}
          setOpenConfirmationDialog={setOpenConfirmationDialog}
          description={confirmationDescription}
          setIsConfirmed={setIsConfirmed}
        />
      )}
    </Box>
  );
}
