/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Reducers for creating, updating and adding roles list
 * @date 26/05/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import { Reducer } from "redux";
import actionType from "../actionTypes";

const initialState = {
  error: false,
  msg: "",
  validated: [],
  checkPurchaseOrder: [],
  orderIds: [],
  validatedProcessingPartner: [],
  skuStock: [],
};

const purchaseProductReducer = (state = initialState, action) => {
  // console.log(`action type: ${action.type}`);
  // console.log(action);
  switch (action.type) {
    case actionType.VALIDATE_ORDER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        validated: action.payload.data,
      };
    case actionType.CHECK_PURCHASE_ORDER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        checkPurchaseOrder: action.payload.data,
      };
    case actionType.PLACE_ORDER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        orderIds: action.payload.data,
      };
    case actionType.EDIT_MFT_ORDER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.PO_FILE_UPDATE:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.PO_ACCEPT_REJECT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.PO_ACCEPT_REJECT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.GET_SKU_STOCKS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        skuStock: action.payload.data,
      };
    case actionType.VALIDATE_PROCESSINGPARTNER_ORDER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        validatedProcessingPartner: action.payload.data,
      };
    case actionType.PLACE_ORDER_PROCESSINGPARTNER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.CONFIRM_PROCESSING_PARTNER_ORDER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.EDIT_PROCESSING_PARTNER_ORDER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    default:
      return state;
  }
};

export default purchaseProductReducer;
