import {
  AppBar,
  Box,
  Button,
  Divider,
  Paper,
  useMediaQuery,
} from "@mui/material";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { IconButton, Typography, useTheme, Drawer } from "@mui/material";
import { tokens } from "../../AppMeta/theme";
import { Link, useHistory } from "react-router-dom";
import React from "react";
import { sideBarData } from "./sideBarData";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import bwLogo from "../../Assests/ImagesAndIcons/bwLogo.png";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { GetProfile } from "../../Redux/Actions/profile";
import useScreenSize from "../../Hooks/CustomHooks/useScreenSize";
import appFonts from "../../AppMeta/appFonts";
import appStyle from "../../AppMeta/appStyle";
import { NavLink } from "react-router-dom/cjs/react-router-dom";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const history = useHistory();
  const colors = tokens(theme.palette.mode);
  console.log("colors", colors);
  const handleNavigator = () => {
    history.push(to);
    setSelected(title);
  };

  return (
    <MenuItem
      active={selected === title}
      // style={{
      //   color: appStyle.general.colors(colors).sideBarMenuColor,
      //   background:
      //     selected === title &&
      //     appStyle.general.colors(colors).sideMenuBarActive,
      // }}
      rootStyles={{
        backgroundColor:
          selected === title
            ? appStyle.general.colors(colors).sideMenuBarActive
            : appStyle.general.colors(colors).sideBarMenuColor,
        "&:hover": {
          color: "black",
        },
      }}
      onClick={handleNavigator}
      icon={icon}
    >
      <Typography sx={appStyle.general.sideNavBar.menuText}>{title}</Typography>
    </MenuItem>
  );
};

function SideNavigation() {
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profileData);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const testColors = tokens(theme);
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [selected, setSelected] = React.useState("Home");

  const [dashboard, setDashboard] = React.useState([]);
  const [domain, setDomain] = React.useState("");
  const [isFacility, setIsFacility] = React.useState(false);
  const [orgFacilityName, setOrgFacilityName] = React.useState("");
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useScreenSize();
  const history = useHistory();

  React.useEffect(() => {
    dispatch(GetProfile());
  }, []);

  React.useEffect(() => {
    console.log("profileData");
    console.log(profileData);
    setIsFacility(profileData?.profile?.is_facility);
    setDomain(profileData?.profile?.domain);
    setOrgFacilityName(profileData?.profile?.org_name);
  }, [profileData]);

  React.useEffect(() => {
    // console.log("isFacility", isFacility);
    // console.log("domain", domain);
    // console.log("orgFacilityName", orgFacilityName);
    // if (domain === "BOOKWATER.COM") {
    //   setDashboard(sideBarData.bookWaterDashboard);
    // } else if (isFacility === true) {
    //   setDashboard(sideBarData.facilityDashBoard);
    // } else {
    //   setDashboard(sideBarData.organisationDashboard);
    // }
    setDashboard(sideBarData.bookWaterDashboard);
  }, [isFacility, domain, orgFacilityName]);
  React.useEffect(() => {
    // console.log("isFacility", isFacility);
    // console.log("domain", domain);
    // console.log("orgFacilityName", orgFacilityName);
    // if (domain === "BOOKWATER.COM") {
    //   setDashboard(sideBarData.bookWaterDashboard);
    // } else if (isFacility === true) {
    //   setDashboard(sideBarData.facilityDashBoard);
    // } else {
    //   setDashboard(sideBarData.organisationDashboard);
    // }
    setDashboard(sideBarData.bookWaterDashboard);
  }, []);

  React.useEffect(() => {
    console.log("dashboard");
    console.log(dashboard);
  }, [dashboard]);

  React.useEffect(() => {});

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  React.useEffect(() => {
    console.log("dashboard");
    console.log(dashboard);
  }, [dashboard]);

  return (
    <Box>
      {isDesktop && (
        <Sidebar
          collapsed={isCollapsed}
          backgroundColor={`${colors.primary[500]} !important`}
        >
          <Menu iconShape="square" icon={"transparent !important"}>
            <MenuItem
              onClick={() => setIsCollapsed(!isCollapsed)}
              icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
              style={{ height: isCollapsed ? "4rem" : "8rem" }} // Set height here
            >
              {!isCollapsed && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  ml="15px"
                  // sx={{ height: "200px" }}
                >
                  {!isCollapsed ? (
                    <Box>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        p={2}
                      >
                        {domain === "BOOKWATER.COM" ? (
                          <>
                            <img
                              alt=""
                              src={bwLogo}
                              style={{
                                cursor: "pointer",
                                height: "3rem",
                                width: "3rem",
                              }}
                            />

                            <Box textAlign="center">
                              <Typography
                                variant="title"
                                color={
                                  appStyle.general.colors(colors)
                                    .bookWaterTitleColor
                                }
                                fontWeight="bold"
                                sx={{ m: "10px 0 0 0" }}
                              >
                                {orgFacilityName}
                              </Typography>
                            </Box>
                          </>
                        ) : (
                          <>
                            <Typography
                              variant="h2"
                              color={
                                appStyle.general.colors(colors)
                                  .otherOrganisationTitleColor
                              }
                              fontWeight="bold"
                            >
                              {orgFacilityName}
                            </Typography>
                            <Box>
                              <Button color="secondary">
                                Powered By BookWater
                              </Button>
                            </Box>
                          </>
                        )}
                      </Box>
                    </Box>
                  ) : (
                    <Typography
                      variant="h3"
                      color={colors.grey[100]}
                    ></Typography>
                  )}

                  <IconButton
                    style={{
                      marginTop: "-5rem",
                    }}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                  >
                    <MenuOutlinedIcon />
                  </IconButton>
                </Box>
              )}
            </MenuItem>

            <Box
              paddingLeft={isCollapsed ? undefined : "10%"}
              sx={{
                overflowY: "scroll",
                height: isCollapsed ? "95vh" : "75vh",
                paddingBottom: "40px",
              }}
            >
              {dashboard?.map((item, index) => (
                <Item
                  key={index}
                  title={item.title}
                  to={item.path}
                  icon={item.icon}
                  selected={selected}
                  setSelected={setSelected}
                />
              ))}
            </Box>
          </Menu>
        </Sidebar>
      )}
    </Box>
  );
}

export default SideNavigation;
