import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Toolbar,
  Typography,
  stepConnectorClasses,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/styles";
import React from "react";
import OrderIcon from "@mui/icons-material/ListAlt";
import ChecklistIcon from "@mui/icons-material/ChecklistOutlined";
import PaymentIcon from "@mui/icons-material/Payment";
import PropTypes from "prop-types";

import PaymentStepper from "../../OrganizationBooking/OrganisationOrderManagement/PaymentStepper";
import CheckOutStepper1 from "../../OrganizationBooking/OrganisationOrderManagement/CheckOutStepper1";
import OrderStepper1 from "./OrderStepper1";
import { tokens } from "../../../../AppMeta/theme";
import ConsumerScanner from "./ConsumerScanner";
import { useDispatch, useSelector } from "react-redux";
// import {
//   CartCalculationOrgAction,
//   GetProductsByOrgId,
// } from "../../../../Redux/Actions/organizationBookingAction";
import BookingCardsSku from "../../../../Components/Cards/OrgBookingCards/BookingCardsSku";
import appColors from "../../../../Assests/AppTheme/appColors";
import CheckOutCardSku from "../../../../Components/Cards/OrgBookingCards/CheckOutCardSku";
import { GetProductsByOrgId } from "../../../../Redux/Actions/skuBookingAction";
import OrgOrdersListCard from "../../../../Components/Cards/OrgBookingCards/OrgOrdersListCard";
import DisplayTitle from "../../../../Components/CustomComp/DisplayTitle";

const ColorlibStepIcon = (props) => {
  const { active, completed, className } = props;

  const icons = {
    1: <OrderIcon />,
    2: <ChecklistIcon />,
    3: <PaymentIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
};

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,

  icon: PropTypes.node,
};

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient(180deg, rgba(39,181,242,1) 0%, rgba(39,181,242,1) 77%)",

    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient(180deg, rgba(39,181,242,1) 0%, rgba(39,181,242,1) 77%)",
  }),
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`&.${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient(180deg, rgba(39,181,242,1) 0%, rgba(39,181,242,1) 77%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`&.${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient(180deg, rgba(39,181,242,1) 0%, rgba(39,181,242,1) 77%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const data = {
  user_stock: [
    {
      user_id: "Lkgx_lbEM",
      container_capacity: null,
      container_material: null,
      is_crate: null,
      max_return: "0",
      max_new_to_provide: "5",
      filled: "0",
      empty: "0",
      new: "5",
      ongoing_order_qty: "0",
      delivery_qty: "0",
      physically_damaged: "0",
      qr_barcode_damaged: "0",
      undeposited_qty: "0",
      deposited_qty: "0",
    },
    {
      user_id: "Lkgx_lbEM",
      container_capacity: null,
      container_material: null,
      is_crate: null,
      max_return: "0",
      max_new_to_provide: "10",
      filled: "0",
      empty: "0",
      new: "10",
      ongoing_order_qty: "0",
      delivery_qty: "0",
      physically_damaged: "0",
      qr_barcode_damaged: "0",
      undeposited_qty: "0",
      deposited_qty: "0",
    },
    {
      user_id: "Lkgx_lbEM",
      container_capacity: null,
      container_material: null,
      is_crate: null,
      max_return: "0",
      max_new_to_provide: "10",
      filled: "0",
      empty: "0",
      new: "10",
      ongoing_order_qty: "0",
      delivery_qty: "0",
      physically_damaged: "0",
      qr_barcode_damaged: "0",
      undeposited_qty: "0",
      deposited_qty: "0",
    },
    {
      user_id: "Lkgx_lbEM",
      container_capacity: null,
      container_material: null,
      is_crate: null,
      max_return: "0",
      max_new_to_provide: "5",
      filled: "0",
      empty: "0",
      new: "5",
      ongoing_order_qty: "0",
      delivery_qty: "0",
      physically_damaged: "0",
      qr_barcode_damaged: "0",
      undeposited_qty: "0",
      deposited_qty: "0",
    },
    {
      user_id: "Lkgx_lbEM",
      container_capacity: null,
      container_material: null,
      is_crate: null,
      max_return: "0",
      max_new_to_provide: "10",
      filled: "0",
      empty: "0",
      new: "10",
      ongoing_order_qty: "0",
      delivery_qty: "0",
      physically_damaged: "0",
      qr_barcode_damaged: "0",
      undeposited_qty: "0",
      deposited_qty: "0",
    },
    {
      user_id: "Lkgx_lbEM",
      container_capacity: null,
      container_material: null,
      is_crate: null,
      max_return: "0",
      max_new_to_provide: "10",
      filled: "0",
      empty: "0",
      new: "10",
      ongoing_order_qty: "0",
      delivery_qty: "0",
      physically_damaged: "0",
      qr_barcode_damaged: "0",
      undeposited_qty: "0",
      deposited_qty: "0",
    },
    {
      user_id: "Lkgx_lbEM",
      container_capacity: null,
      container_material: null,
      is_crate: null,
      max_return: "0",
      max_new_to_provide: "5",
      filled: "0",
      empty: "0",
      new: "5",
      ongoing_order_qty: "0",
      delivery_qty: "0",
      physically_damaged: "0",
      qr_barcode_damaged: "0",
      undeposited_qty: "0",
      deposited_qty: "0",
    },
    {
      user_id: "Lkgx_lbEM",
      container_capacity: null,
      container_material: null,
      is_crate: null,
      max_return: "0",
      max_new_to_provide: "10",
      filled: "0",
      empty: "0",
      new: "10",
      ongoing_order_qty: "0",
      delivery_qty: "0",
      physically_damaged: "0",
      qr_barcode_damaged: "0",
      undeposited_qty: "0",
      deposited_qty: "0",
    },
    {
      user_id: "Lkgx_lbEM",
      container_capacity: null,
      container_material: null,
      is_crate: null,
      max_return: "0",
      max_new_to_provide: "10",
      filled: "0",
      empty: "0",
      new: "10",
      ongoing_order_qty: "0",
      delivery_qty: "0",
      physically_damaged: "0",
      qr_barcode_damaged: "0",
      undeposited_qty: "0",
      deposited_qty: "0",
    },
    {
      user_id: "Lkgx_lbEM",
      container_capacity: "750ml-3",
      container_material: "GLASS",
      is_crate: null,
      max_return: "0",
      max_new_to_provide: "-32750839",
      filled: "0",
      empty: "0",
      new: "2",
      ongoing_order_qty: "32750841",
      delivery_qty: "0",
      physically_damaged: "0",
      qr_barcode_damaged: "0",
      undeposited_qty: "0",
      deposited_qty: "0",
    },
    {
      user_id: "Lkgx_lbEM",
      container_capacity: "750ml-3",
      container_material: "GLASS",
      is_crate: null,
      max_return: "0",
      max_new_to_provide: "-32750839",
      filled: "0",
      empty: "0",
      new: "2",
      ongoing_order_qty: "32750841",
      delivery_qty: "0",
      physically_damaged: "0",
      qr_barcode_damaged: "0",
      undeposited_qty: "0",
      deposited_qty: "0",
    },
    {
      user_id: "Lkgx_lbEM",
      container_capacity: "750ml-3",
      container_material: "GLASS",
      is_crate: null,
      max_return: "0",
      max_new_to_provide: "-32750839",
      filled: "0",
      empty: "0",
      new: "2",
      ongoing_order_qty: "32750841",
      delivery_qty: "0",
      physically_damaged: "0",
      qr_barcode_damaged: "0",
      undeposited_qty: "0",
      deposited_qty: "0",
    },
  ],
  products: [
    {
      id: 62,
      name: "1litre Glass bottle",
      image: "",
      is_qr: true,
      price: 1,
      amount: 1,
      refill: {
        mrp: 1,
        sp: 1,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
      sku_qty: [3],
      capacity: "750ml-3",
      hsn_code: "22110",
      material: "GLASS",
      cgst_perc: 6,
      igst_perc: 6,
      life_span: 5,
      sgst_perc: 6,
      created_at: "11:17:29.449964",
      order_type: 100,
      updated_at: "2024-03-08T11:17:29.449964",
      description: "This is a 1litre glass bottle",
      booking_type: 1,
      max_book_qty: 99,
      min_book_qty: 1,
      one_time_sale: {
        mrp: 1,
        sp: 1,
        deposit: 0,
        refund_type: 0,
        refund_amount: 0,
      },
      crate_details: [
        {
          id: 25,
          name: "1litre Glass bottle crate",
          image: "",
          price: 50,
          capacity: 3,
          hsn_code: "5451231",
          cgst_perc: 6,
          igst_perc: 6,
          sgst_perc: 6,
          description: "This is a 1litre glass bottle",
        },
      ],
    },
    {
      id: 62,
      name: "20 Liter water can",
      image: "",
      is_qr: true,
      price: 1,
      amount: 1,
      refill: {
        mrp: 1,
        sp: 1,
        deposit: 0,
        refund_type: 100,
        refund_amount: 0,
      },
      sku_qty: [3],
      capacity: "20_liter",
      hsn_code: "22110",
      material: "PET",
      cgst_perc: 6,
      igst_perc: 6,
      life_span: 5,
      sgst_perc: 6,
      created_at: "11:17:29.449964",
      order_type: 100,
      updated_at: "2024-03-08T11:17:29.449964",
      description: "This is a 1litre glass bottle",
      booking_type: 1,
      max_book_qty: 99,
      min_book_qty: 1,
      one_time_sale: {
        mrp: 1,
        sp: 1,
        deposit: 0,
        refund_type: 0,
        refund_amount: 0,
      },
      crate_details: [
        {
          id: 25,
          name: "1litre Glass bottle crate",
          image: "",
          price: 50,
          capacity: 3,
          hsn_code: "5451231",
          cgst_perc: 6,
          igst_perc: 6,
          sgst_perc: 6,
          description: "This is a 1litre glass bottle",
        },
      ],
    },
  ],
  pincode: "603103",
  state: "TAMIL NADU",
  area: "MEENAMBAKKAM-14",
  district: "CHENNAI",
  country: "INDIA",
  user_id: "Lkgx_lbEM",
};
export function OrganisationOrderManagement(props) {
  const dispatch = useDispatch();
  const SkuProductDetails = useSelector((state) => state.skuBookingDetails);

  const [selectedProduct, setSelectedProduct] = React.useState({});
  const [infoCard, setInfoCard] = React.useState(false);

  const steps = ["Order", "CheckOut", "Payment"];

  const [activeStep, setActiveStep] = React.useState(0);

  const [openScanner, setOpenScanner] = React.useState(false);

  const [productsOrg, setProductsOrg] = React.useState([]);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0: {
        // return <OrderStepper1 productsOrg={productsOrg} />;
        return (
          <Grid
            container
            sx={{
              marginTop: ".5rem",
            }}
          >
            <Grid item xs={12}>
              <BookingCardsSku
                data={data}
                // toggleDrawer={toggleDrawer}
                // setCheckOutUi={setCheckOutUi}
                // SkuProductDetails={SkuProductDetails}
                // currentDate={currentDate}
                // infoCard={infoCard}
                setInfoCard={setInfoCard}
                // selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
              />
            </Grid>
          </Grid>
        );
      }
      case 1:
        return <CheckOutStepper1 />;
      case 2:
        return <PaymentStepper />;
      default:
        return "Unknown stepIndex";
    }
  };

  const handleClickOpenScanner = () => {
    setOpenScanner(!openScanner);
  };

  React.useEffect(() => {
    const orgId = 30;
    dispatch(GetProductsByOrgId(orgId));
  }, []);

  React.useEffect(() => {
    setProductsOrg(data.products);
  }, []);

  React.useEffect(() => {
    console.log("SkuProductDetails: Parent Comp");
    console.log(SkuProductDetails);
  }, [SkuProductDetails]);

  return (
    <>
      {/* <Header /> */}
      {/* <DisplayTitle>Organisation Order Management</DisplayTitle> */}
      <Grid
        container
        sx={{
          marginTop: ".5rem",
        }}
      >
        <Grid item xs={12}>
          <BookingCardsSku
            productsOrg={productsOrg}
            // toggleDrawer={toggleDrawer}
            // setCheckOutUi={setCheckOutUi}
            // SkuProductDetails={SkuProductDetails}
            // currentDate={currentDate}
            // infoCard={infoCard}
            // setInfoCard={setInfoCard}
            // selectedProduct={selectedProduct}
            // setSelectedProduct={setSelectedProduct}
          />
          {/* <OrgOrdersListCard /> */}
        </Grid>
      </Grid>
      k
      {SkuProductDetails?.selectedProduct?.products === undefined ||
      SkuProductDetails?.selectedProduct?.products?.length === 0 ? null : (
        <Box
          sx={{
            background:
              window.innerWidth <= 600 ? appColors.checkOutCard : "transparent",
            bottom: 0,
            left: "40%",
            // transform: "translate(-50%, -50%)",
            height: "4.5rem",
            width: "25rem",
            position: "fixed",
          }}
        >
          <CheckOutCardSku pageName="booking" />
        </Box>
      )}
    </>
  );
}

// const Header = () => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   return (
//     <Box>
//       <Typography
//         color={colors.skyBlueAccent[100]}
//         fontWeight="bold"
//         m="15px"
//         fontSize={{ xs: "1.4rem", md: "2rem" }}
//       >
//         Organisation Order Management
//       </Typography>
//     </Box>
//   );
// };
