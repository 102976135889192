/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description RootReducers will combine all the reducers.
 * @date 26/05/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import { combineReducers } from "redux";
import authenticationReducer from "./authenticationReducer";
import rolesReducer from "./rolesReducer";
import organizationReducer from "./organizationReducer";
import apisDetailsReducer from "./apisDetailsReducer";
import rolesAccessReducer from "./rolesAccessReducer";
import addUsersDomainRolesReducer from "./addUsersDomainRolesReducer";
import wfsDetailsReducer from "./wfsDetailsReducer";
import productsReducer from "./productsReducer";
import fileHandlingReducer from "./fileHandlingReducer";
import manufacturingOrdersReducer from "./manufacuringOrdersReducer";
import iotDeviceReducer from "./iotDevicesReducer";
import generateQrReducer from "./GenerateQrReducer";
import stockManagementReducer from "./stockManagementReducer";
import stockKeepingUnitReducer from "./stockKeepingUnitReducer";
import manufacturerReducer from "./manufacturerReducer";
import productComponentsReducer from "./productComponentsReducer";
import purchaseProductReducer from "./purchaseProductReducer";
import cratesReducer from "./addCratesReducer";
import pincodesAndAreasReducer from "./pincodesAndAreasReducer";
import linkingQrBarcodesReducer from "./linkingQrBarcodesReducer";
import emptyCanOrderReducer from "./addEmptyCanOrderReducer";
import linesReducer from "./addFacilityWFSFillingReducer";
import createBatchReducer from "./addCreateBatchReducer";
import facilityInchargeReducer from "./addFacilityInchargeReducers";
import profileReducer from "./profileReducer";
import recycleOrderReducer from "./recyleOrdersReducers";
import facilityReducers from "./facilityReducers";
import organizationBookingReducer from "./organizationBookingReducer";
import skuBookNowReducer from "./skuBookingReducer";
import CheckOutReducer from "./checkOutReducer";
import scanningReducer from "./scanningReducer";
import userManagementReducer from "./userManagementReducer";
const RootReducer = combineReducers({
  authenticationData: authenticationReducer,
  rolesData: rolesReducer,
  organizationData: organizationReducer,
  apiListData: apisDetailsReducer,
  rolesAccessData: rolesAccessReducer,
  addUserRolesDomainDetails: addUsersDomainRolesReducer,
  wfsDetails: wfsDetailsReducer,
  productData: productsReducer,
  mftOrdersData: manufacturingOrdersReducer,
  filesData: fileHandlingReducer,
  iotDeviceData: iotDeviceReducer,
  generateQrData: generateQrReducer,
  stockData: stockManagementReducer,
  skuData: stockKeepingUnitReducer,
  manufacturerData: manufacturerReducer,
  productComponentsData: productComponentsReducer,
  purchaseProductData: purchaseProductReducer,
  cratesData: cratesReducer,
  pincodesAndAreasData: pincodesAndAreasReducer,
  linkingData: linkingQrBarcodesReducer,
  emptyCanOrderData: emptyCanOrderReducer,
  addFillingLinesFacilities: linesReducer,
  batchData: createBatchReducer,
  facilityData: facilityInchargeReducer,
  profileData: profileReducer,
  recycleData: recycleOrderReducer,
  facilityManagementData: facilityReducers,
  organizationBookingDetails: organizationBookingReducer,
  skuBookingDetails: skuBookNowReducer,
  checkOutDetails: CheckOutReducer,
  scanningDetails: scanningReducer,
  userManagementReducer: userManagementReducer,
});

export default RootReducer;
