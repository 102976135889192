import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Drawer,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
  Tabs,
  Tab,
  AppBar,
  Toolbar,
  useTheme,
  useMediaQuery,
  Container,
  TablePagination,
} from "@mui/material";
import { useState } from "react";
import { appColors } from "../../AppMeta/appColors";
import SearchIcon from "@mui/icons-material/Search";
import {
  AddManuFacturerAction,
  GetManuFacturer,
  UpdateManufacturer,
} from "../../Redux/Actions/ManufacturersAction";
import { useDispatch } from "react-redux";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { tab } from "@testing-library/user-event/dist/tab";
import FactoryIcon from "@mui/icons-material/Factory";
import { tokens } from "../../AppMeta/theme";
import appFonts from "../../AppMeta/appFonts";

import appStyle from "../../AppMeta/appStyle";
import CustomFilter from "../../Components/CustomComp/CustomFilter";

const dummyData = [
  {
    id: "30",
    is_active: true,
    is_deleted: false,
    details: {
      name: "ABC Corporation",
      address: {
        door_number: "101",
        building_name: "Sunrise Towers",
        area_name: "Main Street",
        pincode: "123456",
        state: "California",
        city: "Los Angeles",
        district: "LA County",
      },
      tax_details: {
        GSTIN: "ABC123XYZ",
        PAN: "ABCDE1234F",
      },
      user_details: {
        owner_name: "John Doe",
        owner_phone: "9876543210",
      },
      files: {
        GST: "path/to/gst/file",
      },
    },
    created_at: "2023-12-21T11:12:35.000Z",
    updated_at: "2023-12-21T11:12:35.000Z",
    created_by: "",
    updated_by: "",
  },
  {
    id: "31",
    is_active: true,
    is_deleted: false,
    details: {
      name: "XYZ Industries",
      address: {
        door_number: "202",
        building_name: "Moonlight Residency",
        area_name: "Broadway",
        pincode: "654321",
        state: "New York",
        city: "New York City",
        district: "NY County",
      },
      tax_details: {
        GSTIN: "XYZ789ABC",
        PAN: "XYZAB6789C",
      },
      user_details: {
        owner_name: "Jane Smith",
        owner_phone: "9876123450",
      },
      files: {
        GST: "path/to/gst/file",
      },
    },
    created_at: "2023-12-21T14:32:42.000Z",
    updated_at: "2023-12-21T14:32:42.000Z",
    created_by: "",
    updated_by: "",
  },
  {
    id: "32",
    is_active: true,
    is_deleted: false,
    details: {
      name: "PQR Enterprises",
      address: {
        door_number: "303",
        building_name: "Starlight Apartments",
        area_name: "Main Avenue",
        pincode: "987654",
        state: "Texas",
        city: "Houston",
        district: "Harris County",
      },
      tax_details: {
        GSTIN: "PQR456LMN",
        PAN: "PQRST1234U",
      },
      user_details: {
        owner_name: "Robert Johnson",
        owner_phone: "9876234109",
      },
      files: {
        GST: "path/to/gst/file",
      },
    },
    created_at: "2023-12-21T15:55:53.000Z",
    updated_at: "2023-12-21T15:55:53.000Z",
    created_by: "",
    updated_by: "",
  },
  {
    id: "33",
    is_active: true,
    is_deleted: false,
    details: {
      name: "LMN Limited",
      address: {
        door_number: "404",
        building_name: "Sunset Residency",
        area_name: "Downtown",
        pincode: "987321",
        state: "California",
        city: "San Francisco",
        district: "SF County",
      },
      tax_details: {
        GSTIN: "LMN789XYZ",
        PAN: "LMNCD4567R",
      },
      user_details: {
        owner_name: "Laura Williams",
        owner_phone: "9876456789",
      },
      files: {
        GST: "path/to/gst/file",
      },
    },
    created_at: "2024-01-17T11:55:56.000Z",
    updated_at: "2024-01-17T11:55:56.000Z",
    created_by: "",
    updated_by: "",
  },
];

const ManufacturerTable = ({
  manufacturersList,
  pageNumber,
  setPageNumber,
  pageSize,
  setPageSize,
  lastPageNumber,
  createdAtSort,
  available,
  setAvailable,
  setCreatedAtSort,
  tabProps,
  drawOpenProp,
  setDrawOpenProp,
}) => {
  console.log(manufacturersList);

  const dispatch = useDispatch();
  const theme = useTheme();
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [openDialogAdd, setOpenDialogAdd] = useState(false);

  // const [selectedManufacturer, setSelectedManufacturer] = useState(null);

  // State variables for Add Manufacturer Drawer

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    console.log("selectedManufacturer");
    console.log(selectedManufacturer);
  }, [selectedManufacturer]);

  const [name, setName] = useState("");
  const [doorNumber, setDoorNumber] = useState("");
  const [buildingName, setBuildingName] = useState("");
  const [areaName, setAreaName] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [gstIN, setGSTIN] = useState("");
  const [pan, setPAN] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [ownerPhone, setOwnerPhone] = useState("");
  const [cgst, setCGST] = useState("");
  const [sgst, setSGST] = useState("");
  const [igst, setIGST] = useState("");
  const [hsn, setHSN] = useState("");

  const colors = tokens(theme.palette.mode);

  const [newManufacturer, setNewManufacturer] = useState({
    name: "",
    email: "",
    phone: "",
    area: "",
    pincode: "",
    buildingName: "",
    doorNumber: "",
  });
  const [openMoreDialog, setOpenMoreDialog] = useState(false);
  const [openAddManufacturerDrawer, setOpenAddManufacturerDrawer] =
    useState(drawOpenProp);

  const handleAddManufacturerDrawerClose = () => {
    setOpenAddManufacturerDrawer(false);
  };

  const handleAddManufacturer = () => {
    const reqObj = {
      mft_type: manufacturerType,
      name: name,
      address: {
        door_number: doorNumber,
        building_name: buildingName,
        area_name: areaName,
        pincode: pincode,
        state: state,
        city: city,
        district: district,
      },
      tax_details: {
        CGST: cgst,
        SGST: sgst,
        IGST: igst,
        HSN: hsn,
      },
      user_details: { owner_name: ownerName, owner_phone: ownerPhone },
      files: { GSTIN: "" },
    };

    console.log(reqObj);
    dispatch(AddManuFacturerAction(reqObj));
    setTimeout(() => {
      setOpenAddManufacturerDrawer(false);
      dispatch(GetManuFacturer(pageNumber, pageSize, available, createdAtSort));
    }, 1000);
  };

  const handleAddManufacturerClick = () => {
    setOpenAddManufacturerDrawer(true);
  };

  const handleMoreClick = (manufacturer) => {
    setSelectedManufacturer(manufacturer);
    setOpenMoreDialog(true);
  };

  const handleMoreDialogClose = () => {
    setOpenMoreDialog(false);
    setSelectedManufacturer(null);
  };
  const handleEditClick = (manufacturer) => {
    setSelectedManufacturer(manufacturer);
    setOpenDialogEdit(true);

    // Populate the state variables with the manufacturer details
    setName(manufacturer?.details?.name);
    setCity(manufacturer?.details?.address?.city);
    setDoorNumber(manufacturer?.details?.address?.door_number);
    setBuildingName(manufacturer.details?.address?.building_name);
    setAreaName(manufacturer?.details?.address?.area_name);
    setPincode(manufacturer?.details?.address?.pincode);
    setState(manufacturer?.details?.address?.state);
    setDistrict(manufacturer?.details?.address?.district);
    setGSTIN(manufacturer?.details?.tax_details?.GSTIN);
    setPAN(manufacturer?.details?.tax_details?.PAN);
    setOwnerName(manufacturer?.details?.user_details?.owner_name);
    setOwnerPhone(manufacturer?.details?.user_details?.owner_phone);

    setHSN(manufacturer?.details?.tax_details?.HSN);
  };

  const handleDialogEditClose = () => {
    setOpenDialogEdit(false);
    setSelectedManufacturer(null);
    // Reset state variables when closing the dialog
    setName("");
    setCity("");
    setDoorNumber("");
    setBuildingName("");
    setAreaName("");
    setPincode("");
    setState("");
    setDistrict("");
    setGSTIN("");
    setPAN("");
    setOwnerName("");
    setOwnerPhone("");
    setCGST("");
    setSGST("");
    setIGST("");
    setHSN("");
  };

  const handleSaveChanges = () => {
    console.log("Saved Changes:");
    console.log({
      name,
      city,
      doorNumber,
      buildingName,
      areaName,
      pincode,
      state,
      district,
      gstIN,
      ownerName,
      ownerPhone,
    });
    const reqObj = {
      id: selectedManufacturer.id,
      name: name,
      // address: {
      //   city: city,
      //   door_number: doorNumber,
      //   building_name: buildingName,
      //   area_name: areaName,
      //   pincode: pincode,
      //   state: state,
      //   district: district,
      // },
      tax_details: {
        GSTIN: gstIN,
        PAN: pan,
      },
      is_force_update: true,
      is_deleted: false,
    };
    dispatch(UpdateManufacturer(reqObj));
    setTimeout(() => {
      dispatch(GetManuFacturer(pageNumber, pageSize, available, createdAtSort));
    }, 1000);
    // Implement your logic to save changes here
    setOpenDialogEdit(false);
  };

  const handleDialogClose = () => {
    setOpenDialogAdd(false);
    setNewManufacturer({
      name: "",
      email: "",
      phone: "",
      area: "",
      pincode: "",
    });
  };
  const handleAddClick = () => {
    setOpenDialogAdd(true);
  };

  const handleRowClick = (manufacturer) => {
    console.log(manufacturer);
    setSelectedManufacturer(manufacturer);
  };

  const pageNumberForward = () => {
    if (manufacturersList.length > 0) {
      setPageNumber(pageNumber + 1);
      // setSearchOrderFlag(true);
    }
  };

  const pageNumberBackward = () => {
    if (pageNumber - 1 > 0) {
      setPageNumber(pageNumber - 1);
      // setdisableBtn(false);
      // setSearchOrderFlag(true);
    }
  };

  const [manufacturerType, setManufacturerType] = React.useState(100);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeType = (event) => {
    setManufacturerType(event.target.value);
  };

  const [tabValue, setTabValue] = React.useState(0);
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  React.useEffect(() => {
    setTabValue(tabProps);
  }, [tabProps]);

  React.useEffect(() => {
    setOpenAddManufacturerDrawer(drawOpenProp);
  }, [drawOpenProp]);

  return (
    <>
      <Drawer
        anchor="right"
        open={openAddManufacturerDrawer}
        onClose={handleAddManufacturerDrawerClose}
        PaperProps={{
          style: {
            width: isMobile ? "75%" : "500px",
            padding: "20px",
          },
        }}
      >
        <Box
          sx={{
            padding: "16px",
            width: isMobile ? "90%" : "500px",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              marginBottom: "16px",
              textAlign: "center",
              fontSize: "28px",
              fontWeight: "bold",
            }}
          >
            Add Manufacturer
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Select Manufacturer type
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={manufacturerType}
                onChange={handleChangeType}
              >
                <FormControlLabel
                  value={100}
                  control={<Radio />}
                  label="Supplier / Manufacturer"
                />
                <FormControlLabel
                  value={200}
                  control={<Radio />}
                  label="Processing Partner"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <TextField
            label="Name"
            fullWidth
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            label="Door Number"
            fullWidth
            margin="normal"
            value={doorNumber}
            onChange={(e) => setDoorNumber(e.target.value)}
          />
          <TextField
            label="Building Name"
            fullWidth
            margin="normal"
            value={buildingName}
            onChange={(e) => setBuildingName(e.target.value)}
          />
          <TextField
            label="Area Name"
            fullWidth
            margin="normal"
            value={areaName}
            onChange={(e) => setAreaName(e.target.value)}
          />
          <TextField
            label="Pincode"
            fullWidth
            margin="normal"
            value={pincode}
            onChange={(e) => setPincode(e.target.value)}
          />
          <TextField
            label="State"
            fullWidth
            margin="normal"
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
          <TextField
            label="City"
            fullWidth
            margin="normal"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          <TextField
            label="District"
            fullWidth
            margin="normal"
            value={district}
            onChange={(e) => setDistrict(e.target.value)}
          />
          <TextField
            label="GSTIN"
            fullWidth
            margin="normal"
            value={gstIN}
            onChange={(e) => setGSTIN(e.target.value)}
          />
          <TextField
            label="PAN"
            fullWidth
            margin="normal"
            value={pan}
            onChange={(e) => setPAN(e.target.value)}
          />
          <TextField
            label="Owner Name"
            fullWidth
            margin="normal"
            value={ownerName}
            onChange={(e) => setOwnerName(e.target.value)}
          />
          <TextField
            label="Owner Phone"
            fullWidth
            margin="normal"
            value={ownerPhone}
            onChange={(e) => setOwnerPhone(e.target.value)}
          />
          <TextField
            label="CGST"
            fullWidth
            margin="normal"
            value={cgst}
            onChange={(e) => setCGST(e.target.value)}
          />
          <TextField
            label="SGST"
            fullWidth
            margin="normal"
            value={sgst}
            onChange={(e) => setSGST(e.target.value)}
          />
          <TextField
            label="HSN"
            fullWidth
            margin="normal"
            value={hsn}
            onChange={(e) => setHSN(e.target.value)}
          />
          {/* Add more text fields for other details */}
          <Button
            variant="contained"
            onClick={handleAddManufacturer}
            sx={appStyle.general.button.editButton}
          >
            Add
          </Button>
        </Box>
      </Drawer>

      <Box
        sx={{
          margin: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        {/* Search bar components */}
        {/* <TextField
            label="Search by name"
            variant="outlined"
            margin="normal"
            size="small"
            InputProps={{
              startAdornment: (
                <SearchIcon color="action" style={{ marginRight: "8px" }} />
              ),
            }}
          /> */}
        {/* <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              gap: "15px",
              padding: "10px",
              marginTop: "10px",
            }}
          >
            <Box>
              <Button
                sx={{
                  cursor: "pointer",
                }}
                onClick={pageNumberBackward}
              >
                <ArrowBackIosIcon />
              </Button>
            </Box>
            <Typography
              variant="button"
              display="block"
              gutterBottom
              sx={{
                marginLeft: "10px",
                marginRight: "10px",
                backgroundColor: "#2D81FF",
                color: "white",
                padding: "5px",
                width: "40px",
                height: "35px",
                borderRadius: "15px",
                textAlign: "center",
              }}
            >
              {pageNumber}
            </Typography>
            <Box>
              <Button
                sx={{
                  cursor: "pointer",
                }}
                onClick={pageNumberForward}
                // disabled={pageNumber < lastPageNumber ? false : true}
              >
                <ArrowForwardIosIcon />
              </Button>
            </Box>
            <Box>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">Page size</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={pageSize}
                  label="Page size"
                  onChange={(e) => {
                    setPageSize(e.target.value);
                    // setSearchOrderFlag(true);
                  }}
                >
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                  <MenuItem value={500}>500</MenuItem>
                  <MenuItem value={1000}>1000</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box> */}
        {/* Add more search bar components if needed */}
      </Box>
      {/* <AppBar
        position="relative"
        sx={{
          backgroundColor:
            appStyle.general.colors(colors).secondarySkuTabBar.background,
          color: colors.primary[200],
          ...appStyle.sku.secondLevelTab.appBar,
        }}
      >
        <Toolbar>
          <Tabs
            variant="scrollable"
            value={tabValue}
            onChange={handleChangeTab}
            aria-label="SKU Management Tabs"
            sx={{
              color: colors.primary[400],
              indicatorColor:
                appStyle.general.colors(colors).secondarySkuTabBar
                  .indicatorColor,
            }}
          >
            <Tab
              sx={appStyle.general.tab.secondLevel}
              label="Supplier / Manufacturers"
            />
            <Tab
              sx={appStyle.general.tab.secondLevel}
              label="Processing partner"
            />
          </Tabs>
        </Toolbar>
      </AppBar>
      <Box sx={appStyle.general.button.addButtonBox}>
        <Button
          variant="contained"
          color="secondary"
          sx={appStyle.general.button.addButton}
          startIcon={<FactoryIcon />}
          onClick={handleAddManufacturerClick}
        >
          Add Manufacturer
        </Button>
      </Box> */}

      <Container style={appStyle.general.table.containerForTable}>
        {tabValue === 0 && (
          <>
            <SupplierManufacturer
              handleMoreClick={handleMoreClick}
              handleEditClick={handleEditClick}
              manufacturersList={manufacturersList}
            />
          </>
        )}

        {/* Table for processing partner */}

        {tabValue === 1 && (
          <Box sx={appStyle.general.table.containerForTable}>
            <CustomFilter />
            <TableContainer sx={appStyle.general.table.tableContainer}>
              <Table stickyHeader>
                <TableHead
                  sx={{
                    backgroundColor:
                      appStyle.general.colors(colors).table.tableHeadBackground,
                    color: colors.primary[300],
                    ...appStyle.general.table.tableHead,
                  }}
                >
                  <TableRow>
                    <TableCell sx={appStyle.general.table.headderRowCell}>
                      ID
                    </TableCell>
                    <TableCell sx={appStyle.general.table.headderRowCell}>
                      Name
                    </TableCell>
                    <TableCell sx={appStyle.general.table.headderRowCell}>
                      Address
                    </TableCell>
                    <TableCell sx={appStyle.general.table.headderRowCell}>
                      Pincode
                    </TableCell>
                    <TableCell sx={appStyle.general.table.headderRowCell}>
                      State
                    </TableCell>
                    <TableCell sx={appStyle.general.table.headderRowCell}>
                      Details
                    </TableCell>
                    <TableCell sx={appStyle.general.table.headderRowCell}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ backgroundColor: colors.primary[600] }}>
                  {dummyData?.map((manufacturer) => (
                    <TableRow
                      key={manufacturer.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                      }}
                    >
                      <TableCell sx={appStyle.general.table.bodyRowCell}>
                        {manufacturer.id}
                      </TableCell>
                      <TableCell sx={appStyle.general.table.bodyRowCell}>
                        {manufacturer.details.name}
                      </TableCell>
                      <TableCell sx={appStyle.general.table.bodyRowCell}>
                        {`${manufacturer?.details?.address?.door_number}, ${manufacturer?.details?.address?.building_name}, ${manufacturer?.details?.address?.area_name}, 
                  ${manufacturer?.details?.address?.district}`}
                      </TableCell>
                      <TableCell sx={appStyle.general.table.bodyRowCell}>
                        {manufacturer?.details?.address?.pincode}
                      </TableCell>
                      <TableCell sx={appStyle.general.table.bodyRowCell}>
                        {manufacturer?.details?.address?.state}
                      </TableCell>
                      <TableCell sx={appStyle.general.table.bodyRowCell}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleMoreClick(manufacturer)}
                          sx={appStyle.general.button.editButton}
                        >
                          More
                        </Button>
                      </TableCell>
                      <TableCell sx={appStyle.general.table.bodyRowCell}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleEditClick(manufacturer)}
                          sx={appStyle.general.button.editButton}
                        >
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={appStyle.general.table.pagination}>
              <TablePagination
                rowsPerPageOptions={[25, 100, 500, 1000]}
                component="div"
                count={dummyData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </Box>
        )}
      </Container>

      {/* {
    "id": 1,
    "name":"BKWTR",
    "address": {
        "city":"Chennai",
        "door_number":"2021A",
        "building_name":"Reheja Towers",
        "area_name": "hennai",
        "pincode": "600084",
        "state": "Tamil Nadu",
        "district": "Chennai"
    },
    "tax_details": {
        "GSTIN": "1234",
        "PAN":"FMIPM6114A"
    },
    "is_force_update":true,
    "is_deleted":false
} */}
      <Dialog open={openDialogEdit} onClose={handleDialogEditClose}>
        <DialogTitle>Edit Manufacturer Details</DialogTitle>
        <DialogContent>
          {selectedManufacturer && (
            <Box>
              <TextField
                label="Name"
                value={name}
                fullWidth
                margin="normal"
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                label="City"
                value={city}
                fullWidth
                margin="normal"
                onChange={(e) => setCity(e.target.value)}
              />
              <TextField
                label="Door Number"
                value={doorNumber}
                fullWidth
                margin="normal"
                onChange={(e) => setDoorNumber(e.target.value)}
              />
              <TextField
                label="Building Name"
                value={buildingName}
                fullWidth
                margin="normal"
                onChange={(e) => setBuildingName(e.target.value)}
              />
              <TextField
                label="Area name"
                value={areaName}
                fullWidth
                margin="normal"
                onChange={(e) => setAreaName(e.target.value)}
              />
              <TextField
                label="Pincode"
                value={pincode}
                fullWidth
                margin="normal"
                onChange={(e) => setPincode(e.target.value)}
              />
              <TextField
                label="State"
                value={state}
                fullWidth
                margin="normal"
                onChange={(e) => setState(e.target.value)}
              />
              <TextField
                label="District"
                value={district}
                fullWidth
                margin="normal"
                onChange={(e) => setDistrict(e.target.value)}
              />
              <TextField
                label="GSTIN"
                value={gstIN}
                fullWidth
                margin="normal"
                onChange={(e) => setGSTIN(e.target.value)}
              />
              <TextField
                label="PAN"
                value={pan}
                fullWidth
                margin="normal"
                onChange={(e) => setPAN(e.target.value)}
              />
              <TextField
                label="Owner Name"
                value={ownerName}
                fullWidth
                margin="normal"
                onChange={(e) => setOwnerName(e.target.value)}
              />
              <TextField
                label="Owner Phone"
                value={ownerPhone}
                fullWidth
                margin="normal"
                onChange={(e) => setOwnerPhone(e.target.value)}
              />

              {/* Add more text fields for other details */}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogEditClose}>Cancel</Button>
          <Button onClick={handleSaveChanges} color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* <Dialog open={openDialogAdd} onClose={handleDialogClose}>
        <DialogTitle>Add New Manufacturer</DialogTitle>
        <DialogContent>
          <Box>
            <TextField
              label="Name"
              value={newManufacturer.name}
              onChange={(e) =>
                setNewManufacturer({ ...newManufacturer, name: e.target.value })
              }
              fullWidth
              margin="normal"
            />
            <TextField
              label="Email"
              value={newManufacturer.email}
              onChange={(e) =>
                setNewManufacturer({ ...newManufacturer, name: e.target.value })
              }
              fullWidth
              margin="normal"
            />
            <TextField
              label="Phone"
              value={newManufacturer.phone}
              onChange={(e) =>
                setNewManufacturer({ ...newManufacturer, name: e.target.value })
              }
              fullWidth
              margin="normal"
            />
            <TextField
              label="Area"
              value={newManufacturer.area}
              onChange={(e) =>
                setNewManufacturer({ ...newManufacturer, name: e.target.value })
              }
              fullWidth
              margin="normal"
            />
            <TextField
              label="Pincode"
              value={newManufacturer.pincode}
              onChange={(e) =>
                setNewManufacturer({ ...newManufacturer, name: e.target.value })
              }
              fullWidth
              margin="normal"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleSaveChanges} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog> */}
      <Dialog
        open={openMoreDialog}
        onClose={handleMoreDialogClose}
        maxWidth="md"
      >
        <DialogTitle>More Details</DialogTitle>
        <DialogContent>
          {selectedManufacturer && (
            <Box>
              <Paper
                elevation={3}
                sx={{ padding: "16px", marginBottom: "16px" }}
              >
                <Typography variant="h6">Tax Details</Typography>
                <Typography>
                  GSTIN: {selectedManufacturer?.details?.tax_details?.GSTIN}
                </Typography>
                <Typography>
                  PAN: {selectedManufacturer?.details?.tax_details?.PAN}
                </Typography>
              </Paper>

              <Paper
                elevation={3}
                sx={{ padding: "16px", marginBottom: "16px" }}
              >
                <Typography variant="h6">User Details</Typography>
                <Typography>
                  Owner Name:{" "}
                  {selectedManufacturer?.details?.user_details?.owner_name}
                </Typography>
                <Typography>
                  Owner Phone:{" "}
                  {selectedManufacturer?.details?.user_details?.owner_phone}
                </Typography>
              </Paper>

              <Paper elevation={3} sx={{ padding: "16px" }}>
                <Typography variant="h6">Files</Typography>
                <Typography>
                  GST: {selectedManufacturer?.details?.files?.GST}
                </Typography>
              </Paper>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMoreDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ManufacturerTable;

const SupplierManufacturer = ({
  manufacturersList,
  handleMoreClick,
  handleEditClick,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={appStyle.general.table.containerForTable}>
      <CustomFilter />
      <TableContainer
        sx={appStyle.general.table.tableContainer}
        // component={Paper}
      >
        <Table stickyHeader>
          <TableHead
            sx={{
              backgroundColor:
                appStyle.general.colors(colors).table.tableHeadBackground,
              color: colors.primary[300],
              ...appStyle.general.table.tableHead,
            }}
          >
            <TableRow>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                ID
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Name
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Address
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Pincode
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                State
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Details
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: colors.primary[600] }}>
            {(rowsPerPage > 0
              ? manufacturersList.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : manufacturersList
            ).map((manufacturer) => (
              <TableRow
                key={manufacturer.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                }}
              >
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  {manufacturer.id}
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  {manufacturer.details.name}
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  {`${manufacturer?.details?.address?.door_number}, ${manufacturer?.details?.address?.building_name}, ${manufacturer?.details?.address?.area_name}, 
            ${manufacturer?.details?.address?.district}`}
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  {manufacturer?.details?.address?.pincode}
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  {manufacturer?.details?.address?.state}
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleMoreClick(manufacturer)}
                    sx={appStyle.general.button.editButton}
                    disabled
                  >
                    More
                  </Button>
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleEditClick(manufacturer)}
                    sx={appStyle.general.button.editButton}
                  >
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={appStyle.general.table.pagination}>
        <TablePagination
          rowsPerPageOptions={[25, 100, 500, 1000]}
          component="div"
          count={manufacturersList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
};
