/**
 * @author Bookwater Tech
 * @description Custom hook use Axios
 * @copyright Bookwater tech pvt ltd
 */

import axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";

// const baseURL = "https://link.bookwater.com";
const baseURL = "https://paasstage.bookwater.com";

// const baseURL = "http://localhost:6007";
const useAxios = () => {
  let authTokens = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : null;
  let storageType = true; //true -> Local Storage
  if (authTokens === null) {
    storageType = false;
    authTokens = sessionStorage.getItem("token");
  }
  //   console.log("authTokens: " + authTokens);

  //Create the Axios Instance
  //Note: Every API (GET/PUT/POST) must have Auth Token So Make it as Default.
  const axiosInstance = axios.create({
    baseURL,
    headers: { Authorization: `Bearer ${authTokens}` },
  });
  //It will Execute Before Main Axios for Token verification & Refresh
  axiosInstance.interceptors.request.use(async (req) => {
    console.log(typeof req);
    const user = jwt_decode(authTokens);
    // console.log(
    //   "axiosInstance.interceptors.request User: " + JSON.stringify(user)
    // );
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    if (!isExpired) return req;

    //If Token Expiered, refresh the Token
    console.log(authTokens);
    const token = authTokens;

    const response = await axios.post(`${baseURL}/PSCM/login/keepalive`, {
      headers: { Authorization: `Bearer ${token}` },
      token,
    });

    // For local backend API testing purpose
    // const response = await axios.post(
    //   `https://paasstage.bookwater.com/PSCM/login/keepalive`,
    //   {
    //     headers: { Authorization: `Bearer ${token}` },
    //     token,
    //   }
    // );
    //

    if (response.status === 200) {
      console.log("Success Response in Refresh Token");
      if (storageType) {
        localStorage.setItem("token", response.data.token);
      } else {
        sessionStorage.setItem("token", response.data.token);
      }
      authTokens = response.data.teken;
    } else {
      console.log("Error Response in Refresh Token");
      console.log("End Response: " + JSON.stringify(response));
    }
    req.headers.Authorization = `Bearer ${authTokens}`;
    return req;
  });

  return axiosInstance;
};
export default useAxios;
