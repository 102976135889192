/**
 * @Copyright 2024 BookWater Tech Pvt Ltd
 * @author Janarthanan
 * @description Table template component. props for custom table includes
 *      tableData={userDetailsList}
        tableHead={["Full Name", "Roll Name", "Phone", "Organisation"]}
        tableDataKey={["fullname", "role_name", "phone", "org_name"]}
        filter={true}
        filterSelectOption={[
          { value: "Roll Name", label: "role_name" },
          { value: "Full Name", label: "fullname" },
        ]}
          handleFilterClose ---- Function returns filterdata as key value
        sort={true}
        sortSelectOption={[{ value: "Roll Name", label: "role_name" }]}
        handleSortClose ------ Function returns sortData as key value
        addButton={true}
        handleOnClickAddButton={() => setAddUserDialog(true)}
 * @date 2/11/24
 * @version 0.0.1 - Initial Release
 */

import { Box, Paper, TablePagination } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { isValidElement, useState } from "react";
import appStyle from "../../AppMeta/appStyle";
import CustomFilter from "./CustomFilter";
import CustomAddButton from "./CustomAddButton";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SwapVertIcon from "@mui/icons-material/SwapVert";

function CustomTable({
  tableHead = [],
  tableDataKey = [],
  tableData = [],
  tableRowClickFunction,
  pagination = true,
  filter = false,
  filterSelectOption,
  handleFilterClose,
  sort = false,
  sortSelectOption,
  handleSortClose,
  addButton = false,
  handleOnClickAddButton,
  setPageNumber,
  setPageSize,
}) {
  //declaration for PageNumbers
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(24);

  //functions for Pagination

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageSize(rowsPerPage);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPageNumber(page);
  };

  return (
    <Box sx={{ marginLeft: "1rem", marginRight: "1rem" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {filter && (
          <CustomFilter
            name="filter"
            type="filter"
            startIcon={<FilterAltIcon />}
            selectOption={filterSelectOption}
            handleFilterClose={handleFilterClose}
          />
        )}

        {sort && (
          <CustomFilter
            name="Sort By"
            type="sort"
            startIcon={<SwapVertIcon />}
            selectOption={sortSelectOption}
            handleFilterClose={handleSortClose}
          />
        )}
        {addButton && (
          <CustomAddButton handleOnClickButton={handleOnClickAddButton} />
        )}
      </Box>

      <TableContainer
        component={Paper}
        elevation={3}
        sx={appStyle.general.table.tableContainer}
      >
        <Table aria-label="simple table" stickyHeader>
          <TableHead
            sx={{
              ...appStyle.general.table.tableHead,
            }}
          >
            <TableRow>
              {tableHead?.map((e, i) => {
                return (
                  <TableCell key={i} sx={appStyle.general.table.headderRowCell}>
                    {e}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody
          // sx={{ backgroundColor: colors.primary[600] }}
          >
            {(rowsPerPage > 0
              ? tableData?.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : tableData
            ).map((e, i) => {
              return (
                <TableRow
                  key={i}
                  sx={{
                    // backgroundColor: "white",
                    "&:hover": {
                      backgroundColor: "#95c1e785",
                    },
                  }}
                >
                  {tableDataKey?.map((data, index) => {
                    if (typeof data === "string") {
                      let keys = data.split(".");
                      //Take value from nested object
                      const value = keys.reduce((obj, key) => {
                        if (
                          typeof obj[key] === "object" &&
                          obj[key] !== null &&
                          !Array.isArray(obj[key])
                        ) {
                          return obj[key];
                        }
                        return obj[key];
                      }, e);
                      return (
                        <TableCell
                          key={`${i}${index}`}
                          onClick={() => {
                            tableRowClickFunction(i, e);
                          }}
                          sx={{
                            ...appStyle.general.table.bodyRowCell,
                            cursor: "pointer",
                          }}
                        >
                          {value}
                        </TableCell>
                      );
                    } else if (isValidElement(data)) {
                      console.log("react element");
                      return (
                        <TableCell
                          key={`${i}${index}`}
                          sx={appStyle.general.table.bodyRowCell}
                          onClick={() => data.props.handleClick(i, e)}
                        >
                          {data}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <Box>
          <TablePagination
            rowsPerPageOptions={[24, 100, 500, 1000]}
            component="div"
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </Box>
  );
}

export default CustomTable;
