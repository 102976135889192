import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ComponentListTable from "./ComponentsListTable";
import { useDispatch } from "react-redux";
import { GetComponentsForProduct } from "../../Redux/Actions/ProductComponentsAction";
import FinishedGoodsList from "./FinishedGoodsList";
import FinalProductsList from "./FinalProductsList";
import { toast } from "react-toastify";
import {
  ValidateOrder,
  ValidateProcessingPartnerOrder,
} from "../../Redux/Actions/purchaseProductsAction";
import { tokens } from "../../AppMeta/theme";
import appStyle from "../../AppMeta/appStyle";

function ProductPurchase({
  allProducts,
  manufacturersList,
  activeStep,
  hitValidateApi,
  setSelectedData,
  selectedData,
  allRawMaterials,
  allFinishedGoods,
  orderType,
  setOrderType,
  rawMaterialsStock,
  // productType,
  // setProductType,
  setActiveStep,
  productTypeValue,
  setProductTypeValue,
}) {
  const dispatch = useDispatch();
  console.log("allProducts");

  console.log(allProducts);
  const [selectedProduct, setSelectedProduct] = React.useState("");

  const [requiredOutcomeQuantity, setRequiredOutComeQuantity] =
    React.useState("");
  const [processingFee, setProcessingFee] = React.useState("");
  const [selectedManufacturer, setSelectedManufacturer] = React.useState("");
  const [productType, setProductType] = React.useState(productTypeValue);

  React.useEffect(() => {
    if (orderType === 1) {
      setProductType(0);
    }
  }, [orderType]);

  const handleChangeReqOutcome = (event) => {
    setRequiredOutComeQuantity(event.target.value);
  };

  const handleChangeProcessingFee = (event) => {
    setProcessingFee(event.target.value);
  };

  React.useEffect(() => {
    console.log("selectedData");
    console.log(selectedData);
    if (orderType === 1) {
      if (selectedData.length) {
        const reqObj = {
          order_type: 400,
          mft_id: parseInt(selectedManufacturer),
          order: {
            final_product: selectedProduct,
            quantity_outcome: parseInt(requiredOutcomeQuantity),
            processing_fee: parseInt(processingFee),
            raw_materials: selectedData,
          },
        };
        console.log("reqobj");
        console.log(reqObj);
        dispatch(ValidateProcessingPartnerOrder(reqObj));
        setTimeout(() => {
          toast.success("Successfully added to cart", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }, 1000);
      }
    }
  }, [selectedData]);

  React.useEffect(() => {
    setProductType(productTypeValue);
  }, [productTypeValue]);

  const handleChangeManufacturer = (event) => {
    setSelectedManufacturer(event.target.value);
  };

  const handleChangeRequiredProduct = (event) => {
    setSelectedProduct(event.target.value);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "center",
        // justifyContent: "center",
        gap: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-around",
          gap: "10px",
        }}
      >
        {/* <Card sx={{ background: colors.primary[400] }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Typography sx={appStyle.general.card.title}>
                Select Order Type
              </Typography>
              <RadioGroup
                aria-label="order-type"
                name="order-type"
                variant="soft"
                value={orderType}
                onChange={(e) => setOrderType(e.target.value)}
              >
                <FormControlLabel
                  value={100}
                  control={<Radio />}
                  label="Purchase Products"
                />
                <FormControlLabel
                  value={200}
                  control={<Radio />}
                  label="Processing partner"
                />
              </RadioGroup>
            </Box>
          </CardContent>
        </Card> */}
        {/* <Card sx={{ background: colors.primary[400] }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Typography sx={appStyle.general.card.title}>
                Select Product Type
              </Typography>
              <RadioGroup
                aria-label="product-type"
                name="product-type"
                value={productType}
                onChange={(e) => setProductType(e.target.value)}
              >
                <FormControlLabel
                  value={300}
                  control={<Radio />}
                  label="Final products"
                  color="secondary"
                />
                <FormControlLabel
                  value={100}
                  control={<Radio />}
                  color="secondary"
                  label="Raw materials"
                  disabled={orderType === 1 ? true : false}
                />
                <FormControlLabel
                  value={200}
                  control={<Radio />}
                  label="Finished goods"
                  disabled={orderType === 1 ? true : false}
                />
              </RadioGroup>
            </Box>
          </CardContent>
        </Card> */}
        {/* {orderType === 1 && (
          <Paper elevation={23}>
            <Box
              sx={{
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <FormControl sx={{ minWidth: "300px" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Required Final Product
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedProduct}
                  label="Select Required Final Product"
                  onChange={handleChangeRequiredProduct}
                >
                  {allProducts?.map((product) => {
                    return (
                      <MenuItem value={product.product_details.id}>
                        {product.product_details.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: "300px" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Manufacturer
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedManufacturer}
                  label="Select Manufacturer"
                  onChange={handleChangeManufacturer}
                >
                  {manufacturersList?.map((manufacturer) => {
                    return (
                      <MenuItem value={manufacturer.id}>
                        {manufacturer.details.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Box>
                <TextField
                  label="Required Outcome Quantity"
                  size="small"
                  type="number"
                  value={requiredOutcomeQuantity}
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={handleChangeReqOutcome}
                />
              </Box>
              <Box>
                <TextField
                  label="Processing fee / Quantity"
                  size="small"
                  type="number"
                  value={processingFee}
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={handleChangeProcessingFee}
                />
              </Box>
            </Box>
          </Paper>
        )} */}
      </Box>
      <Box>
        {productType === 1 && (
          <ComponentListTable
            manufacturersList={manufacturersList}
            selectedProduct={selectedProduct}
            activeStep={activeStep}
            hitValidateApi={hitValidateApi}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            allRawMaterials={allRawMaterials}
            orderType={orderType}
            rawMaterialsStock={rawMaterialsStock}
            productType={productType}
          />
        )}

        {productType === 2 && (
          <FinishedGoodsList
            manufacturersList={manufacturersList}
            selectedProduct={selectedProduct}
            activeStep={activeStep}
            hitValidateApi={hitValidateApi}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            allFinishedGoods={allFinishedGoods}
            productType={productType}
          />
        )}
        {productType === 0 && (
          <FinalProductsList
            manufacturersList={manufacturersList}
            selectedProduct={selectedProduct}
            activeStep={activeStep}
            hitValidateApi={hitValidateApi}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            allProducts={allProducts}
            productType={productType}
            setActiveStep={setActiveStep}
          />
        )}
      </Box>
    </Box>
  );
}

export default ProductPurchase;
