/**
 * @Copyright 2024 BookWater Tech Pvt Ltd
 * @author Janarthanan
 * @description Stepper for add role contains AddRole as main component, createrRoleForm, AccessMenuOption, add features as sub components
 * @date 2/11/24
 * @version 0.0.1 - Initial Release
 */

import {
  FormControlLabel,
  Typography,
  Box,
  Card,
  CardContent,
  Checkbox,
  Divider,
  Grid,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomStepper from "../../../Components/CustomComp/CustomStepper";
import appFonts from "../../../AppMeta/appFonts";
import appConstants from "../../../AppMeta/appConstants";
import SelectTextField from "../../../Components/CustomComp/SelectTextField";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../../Redux/Actions/actionHandler";
import userManagementApis from "../../../Redux/Actions/userManagement";
import CustomTextFieldN from "../../../Components/CustomComp/CustomTextFieldN";

//Create Role is first step of stepper
function CreateRoleForm({
  roleName,
  setRoleName,
  roleNameError,
  setRoleNameError,
  department,
  setDepartment,
  departmentError,
  setDepartmentError,
  subDepartment,
  setSubDepartment,
  subDepartmentError,
  setSubDepartmentError,
  description,
  setDescription,
  descriptionError,
  setDescriptionError,
  setIsNextStepEnable,
}) {
  useEffect(() => {
    if (roleName !== "" && description !== "" && department !== "") {
      setIsNextStepEnable((prev) => {
        prev[0] = true;
        return prev;
      });
    } else {
      setIsNextStepEnable((prev) => {
        prev[0] = false;
        return prev;
      });
    }
  }, [roleName, description, department]);
  return (
    <Paper elevation={3}>
      <Card>
        <Box sx={{ textAlign: "center", paddingY: "0.5rem" }}>
          <Typography my={1} variant={appFonts.cardTitle}>
            Create Role
          </Typography>
        </Box>
        <Divider />
        <Grid container columnSpacing={2} rowSpacing={1} px={3} pt={2} pb={4}>
          {/* <Grid container columnSpacing={2} rowSpacing={1}> */}
          <Grid item xs={12} sm={4}>
            <CustomTextFieldN
              size="small"
              label="Role*"
              placeholder="Role"
              variant={"standard"}
              type={"text"}
              fldType={"text4"}
              value={roleName}
              setValue={setRoleName}
              valueError={roleNameError}
              setValueError={setRoleNameError}
              // txtFldStyle={styles.customTextFieldN}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CustomTextFieldN
              size="small"
              label="Department*"
              placeholder="Department"
              variant={"standard"}
              type={"text"}
              fldType={"text"}
              value={department}
              setValue={setDepartment}
              valueError={departmentError}
              setValueError={setDepartmentError}
              // txtFldStyle={styles.customTextFieldN}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CustomTextFieldN
              size="small"
              label="Sub Department*"
              placeholder="Sub Department"
              variant={"standard"}
              type={"text"}
              fldType={"text"}
              value={subDepartment}
              setValue={setSubDepartment}
              valueError={subDepartmentError}
              setValueError={setSubDepartmentError}
              // txtFldStyle={styles.customTextFieldN}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomTextFieldN
              size="small"
              label="Description*"
              placeholder="Description"
              variant={"standard"}
              type={"text"}
              fldType={"text"}
              value={description}
              setValue={setDescription}
              valueError={descriptionError}
              setValueError={setDescriptionError}
              isMultiline={true}
              minRows={1}
              maxRows={4}
              txtFldStyle={{
                my: 2,
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </Paper>
  );
}

///Access menu is second step for stepper
const MenusCard = ({
  //  menuOptions,
  selectedMenus,
  setSelectedMenus,
}) => {
  const menuOptions = appConstants.accessMenuOption;
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (name === "PROFILE") {
      // Prevent unchecking "PROFILE"
      if (checked) {
        setSelectedMenus((prevItems) => [...prevItems, name]);
      }
    } else {
      // Handle other menu items
      if (checked) {
        setSelectedMenus((prevItems) => [...prevItems, name]);
      } else {
        setSelectedMenus((prevItems) =>
          prevItems.filter((item) => item !== name)
        );
      }
    }
  };

  return (
    <Paper elevation={3}>
      <Card sx={{ padding: "1rem" }}>
        <Box sx={{ textAlign: "center", paddingY: "0.5rem" }}>
          <Typography my={1} variant={appFonts.cardTitle}>
            Access Menu Options
          </Typography>
        </Box>
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            {menuOptions?.map((menu, index) => (
              <Grid item xs={12} sm={6} md={6} key={index}>
                <Box display="flex" alignItems="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedMenus.includes(menu)}
                        onChange={handleCheckboxChange}
                        name={menu}
                        disabled={
                          menu === "PROFILE" && selectedMenus.includes(menu)
                        }
                      />
                    }
                    label={menu}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
};

///Access menu is third step for stepper

const FeaturesCard = ({
  menu_options,
  featuresList,
  selectedMenuOption,
  setSelectedMenuOption,
  selectedMenuOptionFeaturesList,
  selectedFeatures,
  setSelectedFeatures,
}) => {
  const uniqueMenuList = [
    ...new Set(featuresList?.map((menu) => menu.page_name)),
  ];
  const menuOptionsList = uniqueMenuList?.map((pageName) => ({
    value: pageName,
    label: pageName,
  }));

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const id = parseInt(name, 10);

    // Find the current feature based on its ID
    const currentFeature = selectedMenuOptionFeaturesList.find((feature) =>
      feature.details.some((detail) => detail.id === id)
    );

    if (currentFeature?.feature_name === "Default") {
      // Allow only selection for "Default" feature
      if (checked) {
        setSelectedFeatures((prevItems) => [...prevItems, id]);
      }
    } else {
      // Handle selection and unselection for other features
      if (checked) {
        setSelectedFeatures((prevItems) => [...prevItems, id]);
      } else {
        setSelectedFeatures((prevItems) =>
          prevItems?.filter((item) => item !== id)
        );
      }
    }
  };

  useEffect(() => {
    console.log(
      "selectedMenuOptionFeaturesList",
      selectedMenuOptionFeaturesList
    );
  }, [selectedMenuOptionFeaturesList]);

  return (
    <Box sx={{ padding: "1rem" }}>
      <Box sx={{ textAlign: "center", paddingY: "0.5rem" }}>
        <Typography variant={appFonts.cardTitle}>
          Provide Feature Access
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <SelectTextField
          size={220}
          label={"Select Menu"}
          variant={"standard"}
          optionsList={menuOptionsList}
          selectedValue={selectedMenuOption}
          setSelectedValue={setSelectedMenuOption}
          // sltFldStyle={styles.selectTextField}
        />
      </Box>

      <Grid container spacing={3} mt={2}>
        {selectedMenuOptionFeaturesList?.map((feature, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Paper elevation={2}>
              <Card key={index}>
                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    my={1}
                    textAlign={"center"}
                    variant={appFonts.cardTitle}
                  >
                    {feature.feature_name}
                  </Typography>
                </Box>
                <Divider />
                <CardContent>
                  <Grid
                    container
                    spacing={2}
                    sx={{ height: "250px", overflowY: "scroll" }}
                  >
                    {feature.details?.map((detail, index1) => (
                      <Grid item xs={12} key={index1}>
                        <Box display="flex" alignItems="center">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedFeatures.includes(detail.id)}
                                onChange={handleCheckboxChange}
                                name={detail.id.toString()}
                                disabled={
                                  feature.feature_name === "Default" &&
                                  selectedFeatures.includes(detail.id)
                                }
                              />
                            }
                            label={detail.description}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

//Exports role creation steps and form
function AddRole({ handleOnClickAddButton }) {
  const { featuresList } = useSelector(
    (state) => state.userManagementReducer,
    shallowEqual
  );

  const { menu_options } = useSelector(
    (state) => state.profileData?.metaData,
    shallowEqual
  );
  //States for first stepper
  const [roleName, setRoleName] = useState("");
  const [roleNameError, setRoleNameError] = useState("");
  const [department, setDepartment] = useState("");
  const [departmentError, setDepartmentError] = useState("");
  const [subDepartment, setSubDepartment] = useState("");
  const [subDepartmentError, setSubDepartmentError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  //States for second stepper
  const [checked, setChecked] = React.useState([]);
  //States for third component
  const [featuredAccess, setFeaturedAccess] = useState([]);

  const [selectedMenus, setSelectedMenus] = useState([]);

  const dispatch = useDispatch();

  // const menu_options = [];

  const [menuOptionsList, setMenuOptionsList] = useState([]);

  const [selectedMenuOption, setSelectedMenuOption] =
    useState("USER MANAGEMENT");
  const [selectedMenuOptionFeaturesList, setSelectedMenuOptionFeaturesList] =
    useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);

  useEffect(
    () => {
      //Dispatch function to get feature list

      dispatch(
        HandleApiActions({
          ...userManagementApis.get_features_list,
          show_toast: false,
        })
      );

      //Deals with menu option

      const filteredMenuOptionsList = menu_options?.filter(
        (el) => el !== "DEFAULT"
      );
      setMenuOptionsList(filteredMenuOptionsList);

      // const itemsToCheck = ["PROFILE", "SETTINGS", "DASHBOARD"];
      const itemsToCheck = ["PROFILE"];
      // const itemsToCheck = [];
      setSelectedMenus((prevSelectedMenus) => {
        const newSelectedMenus = [...prevSelectedMenus];
        itemsToCheck.forEach((item) => {
          if (
            menu_options?.includes(item) &&
            !newSelectedMenus.includes(item)
          ) {
            newSelectedMenus.push(item);
          }
        });
        return newSelectedMenus;
      });
    },
    []
    // menu_options
  );

  useEffect(() => {
    const filteredFeaturesList = featuresList?.filter(
      (el) => el.page_name == selectedMenuOption
    );
    setSelectedMenuOptionFeaturesList(filteredFeaturesList);

    const filteredFeaturesIdList = featuresList?.filter(
      // (el) => el.feature_name === "Default" || el.feature_name === "Profile"
      (el) => el.feature_name === "Default"
    );

    const ids = filteredFeaturesIdList.flatMap(
      (el) => el.details?.map((detail) => detail.id) || []
    );

    // Add only new ids to selectedFeatures
    setSelectedFeatures((prevFeatures) => {
      const newIds = ids.filter((id) => !prevFeatures.includes(id));
      return [...prevFeatures, ...newIds];
    });
  }, [selectedMenuOption]);

  const handleGetRolesAndAccessList = () => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_roles_list,
        params: {
          page_number: 1,
          page_size: 100,
          sort_column: "created_at",
          sort_order: "ASC",
        },
        show_toast: false,
      })
    );
  };

  const handleRoleAndAccessFinish = () => {
    let currentTime = new Date().toISOString();

    dispatch(
      HandleApiActions({
        ...userManagementApis.add_role,
        params: {
          role_name: roleName,
          department,
          // subDepartment,
          description,
          current_time: currentTime,
          access_to_pages: selectedMenus,
          access_to_features: selectedFeatures,
        },
        show_toast: true,
      })
    ).then(() => {
      handleGetRolesAndAccessList();
    });
    // setIsAddRole(false);
  };

  // const isFormValid = () => {
  //   return (
  //     roleName?.trim() !== "" &&
  //     !roleNameError &&
  //     department?.trim() !== "" &&
  //     !departmentError &&
  //     description?.trim() !== "" &&
  //     !descriptionError
  //   );
  // };

  //Steper steps
  const steps = ["Create Role", "Menu Access", "Features Access"];

  //Used to activate next button in stepper
  const [isNextStepEnable, setIsNextStepEnable] = useState([true, true, true]);

  const stepComponents = [
    <CreateRoleForm
      roleName={roleName}
      setRoleName={setRoleName}
      roleNameError={roleNameError}
      setRoleNameError={setRoleNameError}
      department={department}
      setDepartment={setDepartment}
      departmentError={departmentError}
      setDepartmentError={setDepartmentError}
      subDepartment={subDepartment}
      setSubDepartment={setSubDepartment}
      SubDepartmentError={subDepartmentError}
      setSubDepartmentError={setSubDepartmentError}
      description={description}
      setDescription={setDescription}
      descriptionError={descriptionError}
      setDescriptionError={setDescriptionError}
      setIsNextStepEnable={setIsNextStepEnable}
    />,
    <MenusCard
      menuOptions={menuOptionsList || []}
      selectedMenus={selectedMenus}
      setSelectedMenus={setSelectedMenus}
    />,
    <FeaturesCard
      menuOptions={menu_options || []}
      featuresList={featuresList || []}
      selectedMenuOption={selectedMenuOption}
      setSelectedMenuOption={setSelectedMenuOption}
      selectedMenuOptionFeaturesList={selectedMenuOptionFeaturesList}
      selectedFeatures={selectedFeatures}
      setSelectedFeatures={setSelectedFeatures}
    />,
  ];

  return (
    <Box>
      <CustomStepper
        steps={steps}
        stepComponents={stepComponents}
        onFinish={handleRoleAndAccessFinish}
        isNextStepEnable={isNextStepEnable}
        handleOnClickCancelButton={handleOnClickAddButton}
      />
    </Box>
  );
}

export default AddRole;
