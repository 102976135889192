/**
 * @Copyright 2024 BookWater Tech Pvt Ltd
 * @author Janarthanan
 * @description Drawer to edit roles
 * @date 5/11/24
 * @version 0.0.1 - Initial Release
 */
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  TextField,
  Box,
  Button,
  Card,
  Typography,
  Divider,
  CardContent,
  Grid,
  FormControlLabel,
  Checkbox,
  DialogContentText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import CustomConfirmationDialog from "../../../Components/CustomComp/CustomConfirmationDialog";
import appConstants from "../../../AppMeta/appConstants";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../AppMeta/theme";
import appStyle from "../../../AppMeta/appStyle";
import customConsole from "../../../config/customConsole";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { HandleApiActions } from "../../../Redux/Actions/actionHandler";
import SelectTextField from "../../../Components/CustomComp/SelectTextField";
import userManagementApis from "../../../Redux/Actions/userManagement";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import DeleteIcon from "@mui/icons-material/Delete";
const EditRole = ({
  open,
  onClose,
  selectedRow,
  handleGetRolesAndAccessList,
}) => {
  console.log("selectedRow", selectedRow);

  const styles = {
    urcMainContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
    },
    urcTxtMainContainer: {
      display: "flex",
      alignItems: "center",
      p: 1,
      boxShadow: "3",
    },
    urcTxtContainer: {
      flexGrow: 1,
      textAlign: "center",
    },
    urctabsContainer: {
      display: "flex",
      justifyContent: "center",
      border: "1px solid lightgrey",
    },
    urcSelectedTabContainer: {
      flexGrow: 1,
      overflow: "auto",
    },
    urcCard: {
      marginTop: "8px",
      border: "1px solid lightgrey",
      boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.2)",
    },
    urcCardLS: {
      marginTop: "25px",
      border: "1px solid lightgrey",
      boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.2)",
    },
    mCard: {
      // marginBottom: "10px",
      border: "1px solid lightgrey",
      boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.2)",
    },
    mCardContent: {
      height: "450px",
      overflowY: "auto",
    },
    fCardContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
    fAccordion: {
      marginTop: "5px",
      marginBottom: "15px",
      border: "1px solid lightgrey",
      boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.2)",
    },
    customTextField: {
      backgroundColor: "white",
      mt: 2,
    },
    selectTextField: {
      mb: 1,
      width: "220px",
      backgroundColor: "white",
    },
    lsSelectTextFieldN: {
      // width: "220px",
      width: "100%",
      mt: 2,
      mb: 1,
    },
    customTextFieldN: {
      // width: "220px",
      width: "100%",
      mt: 2,
      mb: 1,
    },
    customBtnACContainer: {
      display: "flex",
      justifyContent: "flex-end",
      mt: 2,
    },
    lsSelectTextFieldNA: {
      // width: "220px",
      width: "100%",
      my: 1,
    },
    customTextFieldNA: {
      // width: "220px",
      width: "100%",
      my: 1,
    },
    closeIcon: {
      color: "red",
    },
    tabs: {
      "&.Mui-selected": {
        fontWeight: 600,

        color: "black",
      },
    },
    toggleBtnContainer: {
      width: "100%",
      mt: 2,
    },
    toggleBtnGroup: {
      borderRadius: "20px",
      backgroundColor: "lightgrey",
    },
  };

  // State for active tab
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();
  const colors = tokens(theme);

  // State for Role Details
  const [roleName, setRoleName] = useState(selectedRow?.role_name);
  const [department, setDepartment] = useState(selectedRow?.department);
  const [subDepartment, setSubDepartment] = useState(
    selectedRow?.subDepartment
  );
  const [description, setDescription] = useState(selectedRow?.description);
  const [pageAccess, setPageAccess] = React.useState(
    Object.values(appConstants.accessMenuOption)
  );
  const [feactureAccess, setFeactureAccess] = useState(
    Object.values(selectedRow?.access_to_features)
  );
  const [isRoleActive, setIsRoleActive] = useState(
    selectedRow?.is_active || false
  );
  const [pendingRoleStatus, setPendingRoleStatus] = useState(isRoleActive);

  //Delete role
  const [openRoleDeleteDialog, setOpenRoleDeleteDialog] = useState(false);
  //Update or edit roles
  const [openRoleStatusDialog, setOpenRoleStatusDialog] = useState(false);

  const menu_options = useSelector(
    (state) => state.profileData?.metaData,
    shallowEqual
  );
  const menu = useSelector((state) => state.profileData, shallowEqual);
  console.log("menu_options", menu);
  const { featuresList } = useSelector(
    (state) => state.userManagementReducer,
    shallowEqual
  );

  const {
    role_id,
    role_name,
    department: dept,
    sub_department,
    legal_chapters,
    description: desp,
    access_to_pages,
    access_to_features,
    is_active,
  } = selectedRow;

  const [selectedMenuOption, setSelectedMenuOption] =
    useState("USER MANAGEMENT");
  const [selectedMenus, setSelectedMenus] = useState([]);
  console.log("selectedMenus", selectedMenus);
  const [menuOptionsLists, setMenuOptionsList] = useState([]);
  console.log("menuOptionsLists", menuOptionsLists);

  let access_to_features_ids = access_to_features?.map((id) => Number(id));
  const [selectedMenuOptionFeaturesList, setSelectedMenuOptionFeaturesList] =
    useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState(
    access_to_features_ids || []
  );
  console.log("selectedFeatures", selectedFeatures);
  const dispatch = useDispatch();
  console.log("feactureAccess", feactureAccess);

  useEffect(() => {
    const filteredFeaturesList = featuresList?.filter(
      (el) => el.page_name == selectedMenuOption
    );
    setSelectedMenuOptionFeaturesList(filteredFeaturesList);
  }, [selectedMenuOption]);

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const filteredMenuOptionsList = menu_options?.filter(
      (el) => el !== "DEFAULT"
    );
    setMenuOptionsList(filteredMenuOptionsList);
  }, [menu_options]);

  const { rolesList } = useSelector(
    (state) => state.userManagementReducer,
    shallowEqual
  );

  // Handle form field change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "roleName") setRoleName(value);
    if (name === "department") setDepartment(value);
    if (name === "description") setDescription(value);
    if (name === "subDepartment") setSubDepartment(value);
  };

  // Handle dialog close
  const handleClose = () => {
    onClose();
  };

  const handleCheckedPageAccess = (event) => {
    const { name, checked } = event.target;
    console.log("name", name);
    console.log("checked", checked);
    if (checked) {
      setPageAccess((prev) => [...prev, name]);
    } else {
      setPageAccess((prev) => prev.filter((item) => item !== name));
    }
  };

  const handleCheckboxChangeFeactureAccess = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setFeactureAccess((prev) => [...prev, name]);
    } else {
      setFeactureAccess((prev) => prev.filter((item) => item !== name));
    }
  };
  customConsole("selectedFeatures: " + selectedFeatures);
  const uniqueMenuList = [
    ...new Set(featuresList?.map((menu) => menu.page_name)),
  ];
  const menuOptionsList = uniqueMenuList?.map((pageName) => ({
    value: pageName,
    label: pageName,
  }));

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [currentFeatureId, setCurrentFeatureId] = useState(null);
  const [actionMessage, setActionMessage] = useState("");

  const handleCheckboxChange = (event, detail) => {
    const { name, checked } = event.target;
    const id = parseInt(name, 10);

    if (checked) {
      setSelectedFeatures((prevItems) => {
        if (!prevItems.includes(id)) {
          return [...prevItems, id];
        }
        return prevItems;
      });

      if (detail?.description) {
        setActionMessage(`Select: "${detail?.description}"`);
      }
    } else {
      setSelectedFeatures((prevItems) =>
        prevItems?.filter((item) => item !== id)
      );

      if (detail?.description) {
        setActionMessage(`Deselect: "${detail?.description}"`);
      }
    }

    setCurrentFeatureId(id);
    setConfirmDialogOpen(true);
  };

  customConsole("selectedMenus: " + selectedMenus);
  // const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [currentMenu, setCurrentMenu] = useState(null);
  // const [actionMessage, setActionMessage] = useState("");

  const handleCheckboxChangeMenu = (event) => {
    const { name, checked } = event.target;

    if (checked) {
      setSelectedMenus((prevItems) => [...prevItems, name]);
      setActionMessage(`Select: "${name}"`);
    } else {
      setSelectedMenus((prevItems) =>
        prevItems?.filter((item) => item !== name)
      );
      setActionMessage(`Deselect: "${name}"`);
    }

    setCurrentMenu(name);
    setConfirmDialogOpen(true);
  };

  const handleUpdate = () => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.update_role,
        params: {
          // role_name: roleName,
          role_id: parseInt(role_id),
          // department,
          sub_department: subDepartment,
          description,
          // current_time: currentTime,
          // access_to_pages: selectedMenus,
          // access_to_features: selectedFeatures,
        },
        show_toast: true,
      })
    ).then(() => {
      // handleGetRolesAndAccessList();
    });
  };
  //state change when clicking enable disable button
  const handleRoleStatusChange = (newStatus) => {
    setPendingRoleStatus(newStatus);
    setOpenRoleStatusDialog(true);
  };

  //update enable or disable to API
  const handleRoleStatusClick = ({ current_time, is_active, role_id }) => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.change_role_status,
        params: { current_time, is_active, role_id },
        show_toast: true,
      })
    ).then(() => {
      handleGetRolesAndAccessList();
    });
  };

  //Enable disable role
  const handleRoleDialogClose = (confirmed) => {
    let currentTime = new Date().toISOString();

    if (confirmed) {
      setIsRoleActive(pendingRoleStatus);

      handleRoleStatusClick({
        current_time: currentTime,
        is_active: pendingRoleStatus,
        role_id,
      });
    }
    setOpenRoleStatusDialog(false);
  };

  // Handles the click event to delete role
  const handleDeleteRoleClick = () => {
    setOpenRoleDeleteDialog(true);
  };

  const handleConfirmRoleDelete = () => {
    let currentTime = new Date().toISOString();

    dispatch(
      HandleApiActions({
        ...userManagementApis.delete_role,
        params: { current_time: currentTime, role_id },
        show_toast: true,
      })
    ).then(() => {
      handleGetRolesAndAccessList();
      setOpenRoleDeleteDialog(false);
      handleClose();
    });

    // toggleDrawer(false)();
  };

  const handleCancelRoleDelete = () => {
    setOpenRoleDeleteDialog(false);
  };
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle textAlign={"center"}>Edit Role</DialogTitle>
      <DialogContent>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          variant="scrollable"
          allowScrollButtonsMobile
          sx={{
            "& .MuiTab-root": {
              color: colors.primary[100], // Color of non-active tabs
            },
            "& .Mui-selected": {
              color:
                appStyle.general.colors(colors).secondarySkuTabBar
                  .indicatorColor, // Color of the active tab
            },
            "& .MuiTabs-indicator": {
              backgroundColor:
                appStyle.general.colors(colors).secondarySkuTabBar
                  .indicatorColor, // Color of the indicator (underline)
            },
          }}
        >
          <Tab label="Role Details" />
          <Tab label="Menu Access" />
          <Tab label="Features Access" />
        </Tabs>

        {/* Role Details Tab */}
        {activeTab === 0 && (
          <Box>
            <Box sx={{ padding: 2 }}>
              <TextField
                label="Role Name"
                name="roleName"
                value={roleName}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Department"
                name="department"
                value={department}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Sub Department"
                name="subDepartment"
                value={subDepartment}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Description"
                name="description"
                value={description}
                onChange={handleInputChange}
                fullWidth
                multiline
                rows={4}
                margin="normal"
              />
            </Box>
            <Box>
              <Typography>Role status:</Typography>
              <CustomButton
                size="small"
                variant="contained"
                btnName="Enable"
                endIcon={isRoleActive ? <CheckBoxIcon /> : null}
                handleClick={() => handleRoleStatusChange(true)}
                disabled={isRoleActive ? true : false}
              />
              <CustomButton
                size="small"
                variant="contained"
                color="error"
                btnName="Disable"
                disabled={isRoleActive ? false : true}
                handleOnClick={() => handleRoleStatusChange(false)}
                endIcon={isRoleActive ? null : <CheckBoxIcon />}
              />
              <CustomButton
                size="small"
                variant="contained"
                color="error"
                btnName="Delete"
                handleOnClick={handleDeleteRoleClick}
                startIcon={<DeleteIcon />}
              />
            </Box>
            <CustomConfirmationDialog
              open={openRoleDeleteDialog}
              onClose={handleCancelRoleDelete}
              dialogTitle={"Confirm Delete"}
              dialogContent={
                <DialogContentText>
                  Are you sure you want to delete this role?
                </DialogContentText>
              }
              onConfirm={handleConfirmRoleDelete}
            />

            <CustomConfirmationDialog
              open={openRoleStatusDialog}
              onClose={() => handleRoleDialogClose(false)}
              dialogTitle={"Confirm Status Change"}
              dialogContent={
                <DialogContentText>
                  Are you sure you want to change the role status to{" "}
                  {pendingRoleStatus ? "Enable" : "Disable"}?
                </DialogContentText>
              }
              onConfirm={() => handleRoleDialogClose(true)}
            />
          </Box>
        )}

        {/* Menu Access Tab */}
        {activeTab === 1 && (
          <Box>
            <Card color="secondary">
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  {/* {Object.values(appConstants.accessMenuOption)?.map(
                    (menu, index) => (
                      <Grid item xs={12} key={index}>
                        <Box display="flex" alignItems="center">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={pageAccess.includes(menu)}
                                onChange={handleCheckedPageAccess}
                                name={menu}

                                // disabled={
                                //   menu === "PROFILE" &&
                                //   selectedMenus.includes(menu)
                                // }
                              />
                            }
                            label={menu}
                          />
                        </Box>
                      </Grid>
                    )
                  )} */}

                  {menuOptionsLists?.map((menu, index) => (
                    <Grid item xs={12} key={index}>
                      <Box display="flex" alignItems="center">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedMenus.includes(menu)}
                              onChange={handleCheckboxChangeMenu}
                              name={menu}
                              disabled={
                                menu === "PROFILE" &&
                                selectedMenus.includes(menu)
                              }
                            />
                          }
                          label={menu}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Box>
        )}

        {/* Features Access Tab */}
        {activeTab === 2 && (
          <Box>
            <Card color="secondary">
              <Divider />
              <CardContent>
                {/* <Grid container spacing={2}> */}
                <Box>
                  <Box sx={styles.fCardContainer}>
                    <SelectTextField
                      size={"small"}
                      label={"Select Menu"}
                      variant={"standard"}
                      optionsList={menuOptionsList}
                      selectedValue={selectedMenuOption}
                      setSelectedValue={setSelectedMenuOption}
                      sltFldStyle={styles.selectTextField}
                    />
                  </Box>
                  <Box>
                    {selectedMenuOptionFeaturesList?.map((feature, index) => (
                      <Accordion key={index} style={styles.fAccordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography
                            mt={1}
                            textAlign={"center"}
                            variant="body1"
                            gutterBottom
                          >
                            {feature.feature_name}
                          </Typography>
                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails>
                          <Grid container spacing={2}>
                            {feature.details?.map((detail, index1) => (
                              <Grid item xs={12} key={index1}>
                                <Box display="flex" alignItems="center">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={selectedFeatures.includes(
                                          detail.id
                                        )}
                                        onChange={(e) =>
                                          handleCheckboxChange(e, detail)
                                        }
                                        name={detail.id.toString()}
                                        disabled={
                                          feature.feature_name === "Default" &&
                                          selectedFeatures.includes(detail.id)
                                        }
                                      />
                                    }
                                    label={detail.description}
                                  />
                                </Box>
                              </Grid>
                            ))}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Box>
                </Box>
                {/* </Grid> */}
              </CardContent>
            </Card>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleUpdate} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditRole;
