import { Box } from "@mui/material";
import React from "react";
import HomeOptions from "./HomeOptions";
// import HomeNewsAndUpdates from "./HomeNewsAndUpdates";
import HomeBrandTrustList from "./HomeBrandTrustList";
import HomeProductFeatures from "./HomeProductFeatures";
import UserProfile from "./UserProfile";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function Home() {
  return (
    <Box>
      <UserProfile />
      {/* <HomeNewsAndUpdates />
      <HomeOptions />
      <HomeProductFeatures />
      <HomeBrandTrustList /> */}
    </Box>
  );
}

export default Home;
