/**
 * @Copyright 2024 BookWater Tech Pvt Ltd
 * @author Janarthanan
 * @description Custom stepper component, contains props such as
 *      steps=[Array of title]
        stepComponents={[array of component for each steps]}
        onFinish={onFinish}
        isNextStepEnable={[array of boolean]}
 * @date 2/11/24
 * @version 0.0.1 - Initial Release
 */

import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import appFonts from "../../AppMeta/appFonts";
import appStyle from "../../AppMeta/appStyle";
import { Paper } from "@mui/material";

function CustomStepper({
  steps,
  stepComponents,
  onFinish,
  isNextStepEnable,
  handleOnClickCancelButton,
}) {
  const [activeStep, setActiveStep] = React.useState(0);
  console.log("activeStep", activeStep);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return false;
  };

  const isStepSkipped = (step) => {
    return false;
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper elevation={3} sx={{ m: 1, padding: 1, pt: 3 }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const isCompleted = index < activeStep;
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography key={index} variant={appFonts.title}>
                  Optional
                </Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step
                key={label}
                completed={isCompleted}
                {...stepProps}
                sx={{
                  // width: { xs: "100%", sm: "calc(95% + 32px)" },
                  "& .MuiStepConnector-root": {
                    "& .MuiStepConnector-line": {
                      borderColor: "darkgrey",
                    },
                  },
                  "& .MuiStepLabel-root:hover": {
                    cursor: "pointer",
                    color: "blue",
                  },
                }}
              >
                <StepLabel
                  {...labelProps}
                  sx={{
                    color: isCompleted ? "#1e8449" : "#e74c3c", // Green if completed, else red

                    "& .MuiStepLabel-label": {
                      fontSize: "15px",
                      fontWeight: index === activeStep ? "bold" : "normal",
                      color:
                        index === activeStep
                          ? "black" // Blue for the active step
                          : isCompleted
                          ? "#1e8449" // Green for completed steps
                          : "#e74c3c", // Red for uncompleted steps
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep !== steps.length && (
          <React.Fragment>
            <Box sx={{ padding: "1rem" }}>{stepComponents[activeStep]}</Box>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={appStyle.general.button.blueButton}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              {isStepOptional(activeStep) && (
                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                  Skip
                </Button>
              )}
              <Button
                variant="contained"
                onClick={handleOnClickCancelButton}
                sx={appStyle.general.button.redButton}
              >
                Cancel
              </Button>
              <Button
                onClick={
                  activeStep !== steps.length - 1 ? handleNext : onFinish
                }
                sx={appStyle.general.button.blueButton}
                disabled={!isNextStepEnable[activeStep]}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Paper>
    </Box>
  );
}

export default CustomStepper;
