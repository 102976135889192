import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";

import {
  Card,
  CardContent,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from "@mui/material";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import { useDispatch } from "react-redux";
import { AddLinesFacility } from "../../../Redux/Actions/addFacilityWFSFillingAction";
import { all } from "axios";

export function AddLinesFacilty({ allFacilities }) {
  const dispatch = useDispatch();
  //Datas
  // const data = [
  //   {
  //     line_id: "PDW83WFS2",
  //     iot_device_number: "",
  //     is_active: true,
  //     created_at: "2023-10-28T08:58:43.000Z",
  //   },
  //   {
  //     line_id: "PDW83WFS3",
  //     iot_device_number: "",
  //     is_active: true,
  //     created_at: "2023-10-31T06:03:07.000Z",
  //   },
  //   {
  //     line_id: "PDW83WFS1",
  //     iot_device_number: "TN01BW0055",
  //     is_active: true,
  //     created_at: "2023-10-28T08:58:27.000Z",
  //   },
  // ];

  //1.useState Hooks
  const [selectFacility, setSelectFacility] = React.useState("");

  //3.Functions
  const handleClickAddLines = () => {
    const reqObj = {
      facility_id: parseInt(selectFacility),
    };
    console.log("ReqObj");
    console.log(reqObj);
    dispatch(AddLinesFacility(reqObj));

    //setFlagPrint(true);
  };

  // React.useEffect(() => {
  //   if (flagPrint) {
  //     console.log(selectFacility);
  //     console.log(lineId);
  //     let reqObj = {
  //       facility: selectFacility,

  //     };
  //     console.log(reqObj);

  //   }
  // }, [selectFacility, lineId, flagPrint]);

  const handleFacilityChange = (event) => {
    const selectedFacility = event.target.value;
    setSelectFacility(selectedFacility);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        <Paper elevation={23}>
          <Box sx={{ padding: "10px", minWidth: 150 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Facility
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Facility"
                value={selectFacility}
                onChange={(e) => {
                  setSelectFacility(e.target.value);
                }}
              >
                {/* {allFacilities?.map((facility) => {
                  return (
                    <MenuItem value={facility.id}>{facility.org_name}</MenuItem>
                  );
                })} */}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ padding: "10px" }}>
            <CustomButton
              label="Add Lines"
              color="secondary"
              variant="contained"
              handleClick={handleClickAddLines}
            />
          </Box>
        </Paper>
      </Box>
    </>
  );
}
