/**
 * @Copyright 2024 BookWater Tech Pvt Ltd
 * @author Janarthanan
 * @description Role Manager main Page
 * @date 2/11/24
 * @version 0.0.1 - Initial Release
 */
import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import CustomTable from "../../../Components/CustomComp/CustomTable";
import CustomFilter from "../../../Components/CustomComp/CustomFilter";
import CustomAddButton from "../../../Components/CustomComp/CustomAddButton";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AddRole from "./AddRole";
import appStyle from "../../../AppMeta/appStyle";
import EditRole from "./EditRole";

function RoleManager({
  listOfRoles,
  pageNumber,
  setPageNumber,
  pageSize,
  setPageSize,
  isActive,
  setIsActive,
  ascOrDesc,
  setAscOrDesc,
  sortColumn,
  setSortColumn,
  setFilterColumn,
  setFilterSearchText,
  handleGetRolesAndAccessList,
}) {
  console.log("first");
  console.log("Role List", listOfRoles);

  //Table row set details
  const tableHead = ["ID", "Role", "Description", "Created At"];
  const tableDataKey = ["id", "role_name", "description", "created_at"];

  const [addRolePage, setAddRolePage] = useState(false);

  const [openEditRolesDialog, setOpenEditRolesDialog] = useState(false);
  console.log("openEditRolesDialog", openEditRolesDialog);
  const [selectedRow, setSelectedRow] = useState(null);

  ///Select option array
  const filterSelectOption = [
    {
      value: "role_name",
      label: "Role",
    },

    {
      value: "department",
      label: "Department",
    },
    {
      value: "is_active",
      label: "Role Status",
    },
  ];

  const sortSelectOption = [
    { value: "role_name", label: "Role" },
    { value: "department", label: "Department" },
    { value: "created_at", label: "Created At" },
    { value: "updated_at", label: "Updated At" },
  ];

  //Table top button functions
  const handleFilterClose = (data) => {
    setFilterColumn(data.value);
    setFilterSearchText(data.searchText);
  };
  const handleSortClose = (data) => {
    setSortColumn(data.value);
    setAscOrDesc(data.searchText);
  };
  const handleOnClickAddButton = () => {
    setAddRolePage(!addRolePage);
  };

  const handleTableRowClick = (index) => {
    // console.log("first", listOfRoles[index]);
    setOpenEditRolesDialog(true);
    setSelectedRow(listOfRoles[index]);
    // setOpenEditRolesDialog(true);
  };

  ///Table title list
  const filter = (
    <CustomFilter
      name="filter"
      type="filter"
      startIcon={<FilterAltIcon />}
      selectOption={filterSelectOption}
      handleFilterClose={handleSortClose}
    />
  );
  const sortBy = (
    <CustomFilter
      name="Sort By"
      type="sort"
      startIcon={<FilterAltIcon />}
      selectOption={sortSelectOption}
      handleFilterClose={handleFilterClose}
    />
  );

  const addButton = (
    <CustomAddButton handleOnClickButton={handleOnClickAddButton} />
  );

  const tableTitleButton = [filter, sortBy, addButton];
  return (
    <Box>
      {!addRolePage ? (
        <CustomTable
          tableHead={tableHead}
          tableData={listOfRoles}
          tableDataKey={tableDataKey}
          tableTitleButton={tableTitleButton}
          tableRowClickFunction={handleTableRowClick}
          filter={true}
          filterSelectOption={filterSelectOption}
          handleFilterClose={handleFilterClose}
          sort={true}
          sortSelectOption={sortSelectOption}
          handleSortClose={handleSortClose}
          addButton={true}
          handleOnClickAddButton={handleOnClickAddButton}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
        />
      ) : (
        <Box>
          <AddRole handleOnClickAddButton={handleOnClickAddButton} />
        </Box>
      )}

      {/* ////Dialoug to edit selected row in the table */}
      {openEditRolesDialog && (
        <EditRole
          open={openEditRolesDialog}
          onClose={() => {
            setOpenEditRolesDialog(false);
          }}
          selectedRow={selectedRow}
          setOpenEditRolesDialog={setOpenEditRolesDialog}
          pageNumber={pageNumber}
          pageSize={pageSize}
          isActive={isActive}
          ascOrDesc={ascOrDesc}
          handleGetRolesAndAccessList={handleGetRolesAndAccessList}
          // setIsUpdate={setIsUpdate}
          // setOpenConfirmationDialog={setOpenConfirmationDialog}
          // isConfirmed={isConfirmed}
          // isUpdate={isUpdate}
          // setConfirmationDescription={setConfirmationDescription}
          // setRoleUpdateBtn={setRoleUpdateBtn}
          // setIsConfirmed={setIsConfirmed}
        />
      )}
    </Box>
  );
}

export default RoleManager;
