import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import ConsumerScanner from "../Orders/OrganizationBooking/OrganisationOrderManagement/ConsumerScanner";
import { tokens } from "../../AppMeta/theme";
import DisplayTitle from "../../Components/CustomComp/DisplayTitle";

function UserProfile() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openScanner, setOpenScanner] = React.useState(false);
  const handleClickOpenScanner = () => {
    setOpenScanner(!openScanner);
  };
  return (
    <>
      {/* <Header name={"Home"} /> */}
      {/* <Box display="flex" justifyContent="space-evenly">
        <Box
          sx={{
            width: "60px",
            boxShadow: 2,
            height: "40px",
            background: colors.blueAccent[500],
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            // onClick={() => setOpenBookNow(true)}
          >
            {" "}
            Book Now
          </Button>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClickOpenScanner}
          >
            Scan QR
          </Button>
        </Box>
        {openScanner && <ConsumerScanner />}
      </Box> */}
    </>
  );
}

export default UserProfile;

// const Header = ({ name }) => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   return (
//     <Box>
//       <DisplayTitle>{name}</DisplayTitle>
//     </Box>
//   );
// };
