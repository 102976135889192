import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { UpdateCrates } from "../../Redux/Actions/addCratesAction";
import appStyle from "../../AppMeta/appStyle";

function EditDialogCrates({ open, onClose, item }) {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [cgst, setCgst] = useState("");
  const [sgst, setSgst] = useState("");
  const [igst, setIgst] = useState("");
  const [capacity, setCapacity] = useState("");
  const [price, setPrice] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [itemCgst, setItemCgst] = useState("");
  const [itemSgst, setItemSgst] = useState("");
  const [error, setError] = React.useState("");
  const [minBookQty, setMinBookQty] = React.useState("");
  const [maxBookQty, setMaxBookQty] = React.useState("");
  const [itemIgst, setItemIgst] = useState("");
  const [error1, setError1] = React.useState("");
  const [error2, setError2] = React.useState("");
  const [error3, setError3] = React.useState("");
  const [error4, setError4] = React.useState("");

  React.useEffect(() => {
    console.log(item);
    if (item) {
      setName(item.name);
      setCapacity(item.capacity);
      setPrice(item.price);
      setHsnCode(item.hsn_code);
      setCgst(item.cgst_perc);
      setSgst(item.sgst_perc);
      setIgst(item.igst_perc);
      setDescription(item.description);
      setImage(item.image);
    }
  }, [item]);

  const handleItemCgstChange = (event) => {
    setItemCgst(event.target.value);
    if (event.target.value !== setItemSgst(event.target.value)) {
      setError("Values do not match");
    } else {
      setError("");
    }
    const newValue = event.target.value;
    if (!isNumeric(newValue) || parseInt(newValue, 10) > 99) {
      setError("Value must not be greater than two digit number");
    } else {
      setItemCgst(newValue);
      setError("");
    }
    if (newValue < 0) {
      setError("Negative values are not allowed");
    } else {
      setItemCgst(newValue);
      setError("");
    }
  };

  const isNumeric = (value) => {
    return /^\d{0,2}$/.test(value);
  };

  const disableSubmit = () => {
    return (
      error !== "" || itemCgst === "" || itemSgst === "" || itemIgst === ""
    );
  };

  const handlePriceChange = (event) => {
    const newValue = event.target.value;
    if (newValue < 0) {
      setError2("Negative values are not allowed");
    } else {
      setPrice(newValue);
      setError2("");
    }
  };

  const handleHSNChange = (event) => {
    const newValue = event.target.value;
    if (newValue < 0) {
      setError3("Negative values are not allowed");
    } else {
      setHsnCode(newValue);
      setError3("");
    }
  };

  const handleItemSgstChange = (event) => {
    setItemSgst(event.target.value);
    if (event.target.value !== setItemCgst(event.target.value)) {
      setError("Values do not match");
    } else {
      setError("");
    }
    const newValue = event.target.value;
    if (!isNumeric(newValue) || parseInt(newValue, 10) > 99) {
      setError("Value must be a number less than or equal to 99");
    } else {
      setItemSgst(newValue);
      setError("");
    }
    if (newValue < 0) {
      setError("Negative values are not allowed");
    } else {
      setItemCgst(newValue);
      setError("");
    }
  };

  const handleItemIgstChange = (event) => {
    setItemIgst(event.target.value);
    if (event.target.value !== setItemIgst(event.target.value)) {
      setError4("Values do not match");
    } else {
      setError4("");
    }
    const newValue = event.target.value;
    if (!isNumeric(newValue) || parseInt(newValue, 10) > 99) {
      setError4("Value must be a number less than or equal to 99");
    } else {
      setItemIgst(newValue);
      setError4("");
    }
    if (newValue < 0) {
      setError4("Negative values are not allowed");
    } else {
      setItemIgst(newValue);
      setError4("");
    }
  };

  const handleUpdate = () => {
    const reqObj = {
      id: item.id,
      name: name,
      new_name: name,
      capacity: capacity,
      description: description,
      price: price,
      image: image,
      hsn_code: hsnCode,
      cgst_perc: cgst,
      sgst_perc: sgst,
      igst_perc: igst,
      is_active: true,
      is_deleted: false,
    };
    dispatch(UpdateCrates(reqObj));
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit Item</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            margin="dense"
            id="capacity"
            label="Capacity"
            type="number"
            fullWidth
            variant="standard"
            value={capacity}
            onChange={(e) => setCapacity(e.target.value)}
          />
          <TextField
            margin="dense"
            id="price"
            label="Price"
            type="number"
            fullWidth
            variant="standard"
            value={price}
            error={error2 !== ""}
            helperText={error2}
            onChange={handlePriceChange}
          />
          <TextField
            margin="dense"
            id="hsn_code"
            label="HSN Code"
            type="text"
            fullWidth
            variant="standard"
            value={hsnCode}
            error={error3 !== ""}
            helperText={error3}
            onChange={handleHSNChange}
          />
          <TextField
            margin="dense"
            id="cgst_perc"
            label="CGST (%)"
            fullWidth
            variant="standard"
            value={itemCgst}
            onChange={handleItemCgstChange}
            error={error !== ""}
            helperText={error}
          />
          <TextField
            margin="dense"
            id="sgst_perc"
            label="SGST (%)"
            type="number"
            fullWidth
            variant="standard"
            value={itemSgst}
            onChange={handleItemSgstChange}
            error={error !== ""}
            helperText={error}
          />
          <TextField
            margin="dense"
            id="igst_perc"
            label="IGST (%)"
            type="number"
            fullWidth
            variant="standard"
            value={itemIgst}
            onChange={handleItemIgstChange}
            error={error4 !== ""}
            helperText={error4}
          />
          <TextField
            margin="dense"
            id="description"
            label="Description"
            type="text"
            fullWidth
            value={description}
            variant="standard"
            onChange={(e) => setDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            color="primary"
            sx={appStyle.general.button.redButton}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={disableSubmit()}
            onClick={handleUpdate}
            color="primary"
            sx={appStyle.general.button.blueButton}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditDialogCrates;
