/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Roles management main component with new creating roles
 * @date 26/05/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CustomTypography from "../../../Components/CustomComp/CustomTypography";
import CustomInputTextField from "../../../Components/CustomComp/CustomInputTextField";
import CustomButton from "../../../Components/CustomComp/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { CreateRole, GetRoles } from "../../../Redux/Actions/rolesAction";
import RolesListTable from "./RolesListTable";
import ApiList from "../ApiList/ApiList";
import { HandleApiActions } from "../../../Redux/Actions/actionHandler";
import ApiManagement from "../ApiList/ApiManagement";
import { Dialog } from "@mui/material";
import CustomConfirmationDialog from "../../../Components/CustomComp/CustomConfirmationDialog";
import appStyle from "../../../AppMeta/appStyle";
import RoleManager from "./RoleManager";
import userManagementApis from "../../../Redux/Actions/userManagement";
import { GetAppmeta } from "../../../Redux/Actions/profile";

const styles = {
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    padding: "20px",
  },
};

function RolesManagement({ drawOpenProp, setDrawOpenProp }) {
  const dispatch = useDispatch();
  // const rolesData = useSelector((state) => state.rolesData);
  const rolesData = useSelector(
    (state) => state.userManagementReducer.rolesList
  );
  const featureList = useSelector((state) => state.userManagementReducer);
  const apisData = useSelector((state) => state.apiListData);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(25);
  const [isActive, setIsActive] = React.useState(true);
  const [filterColumn, setFilterColumn] = React.useState("");
  const [filterSearchText, setFilterSearchText] = React.useState("");
  const [sortColumn, setSortColumn] = React.useState("created_at");
  const [ascOrDesc, setAscOrDesc] = React.useState("ASC");
  const [roleName, setRoleName] = React.useState("");
  const [roleDescription, setRoleDescription] = React.useState("");
  const [listOfRoles, setListOfRoles] = React.useState([]);
  const [listOfApis, setListOfApis] = React.useState([]);
  // Update roles variables
  const [roleUpdateBtn, setRoleUpdateBtn] = React.useState(false);

  const [addNewRoleDialog, setAddNewRoleDialog] = React.useState(drawOpenProp);
  const [lastPageNumber, setlastPageNumber] = React.useState(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    React.useState(false);
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const [isAdd, setIsAdd] = React.useState(false);
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [confirmationDescription, setConfirmationDescription] =
    React.useState("");
  React.useEffect(() => {
    console.log("listOfRoles", listOfRoles);
  }, [listOfRoles]);
  React.useEffect(() => {
    console.log("rolesData", rolesData);
    if (rolesData.error === false) {
      setlastPageNumber(rolesData.rolesList.last_page_number);
      if (rolesData.rolesList?.data?.length > 0) {
        setListOfRoles(rolesData);
      }
    }
  }, [rolesData]);

  React.useEffect(() => {
    console.log(apisData);
    if (apisData.error === false) {
      if (apisData.apisList?.data?.length > 0) {
        setListOfApis(apisData.apisList.data);
      }
    }
  }, [apisData]);

  React.useEffect(() => {
    console.log("featureList", featureList);
  }, [featureList]);

  React.useEffect(() => {
    // dispatch(GetRoles(pageNumber, pageSize, isActive, ascOrDesc));
    handleGetRolesAndAccessList();
  }, [pageNumber, pageSize, sortColumn, ascOrDesc, filterSearchText]);

  const handleGetRolesAndAccessList = () => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_roles_list,
        params: {
          page_number: pageNumber,
          page_size: pageSize,
          sort_column: sortColumn,
          sort_order: ascOrDesc,
          ...(filterSearchText && { [filterColumn]: filterSearchText }),
        },
        show_toast: false,
      })

      // HandleApiActions({
      //   ...userManagementApis.get_features_list,
      //   params: {
      //     page_number: pageNumber,
      //     page_size: pageSize,
      //     sort_column: sortColumn,
      //     sort_order: ascOrDesc,
      //     ...(filterSearchText && { [filterColumn]: filterSearchText }),
      //   },
      //   show_toast: false,
      // })
    );
    dispatch(GetAppmeta());
  };

  const handleChangeRoleName = (event) => {
    const newValue = event.target.value;
    setRoleName(newValue);
    console.log(newValue);
  };

  const handleChangeRoleDescription = (event) => {
    const newValue = event.target.value;
    setRoleDescription(newValue);
    console.log(newValue);
  };

  const handleAddRoleClick = () => {
    console.log("Add role");
    setIsAdd(true);
    setConfirmationDescription("Are you sure you want to add this role?");
    // dispatch(CreateRole(`${roleName}`, roleDescription));
    // setAddNewRoleDialog(false);
    // setTimeout(() => {
    //   dispatch(GetRoles(pageNumber, pageSize));
    // }, 1000);
    setOpenConfirmationDialog(true);
  };

  React.useEffect(() => {
    if (isConfirmed) {
      if (isAdd) {
        dispatch(CreateRole(`${roleName}`, roleDescription));
        setAddNewRoleDialog(false);
        setOpenConfirmationDialog(false);
        setIsAdd(false);
        setTimeout(() => {
          dispatch(GetRoles(pageNumber, pageSize));
        }, 1000);
      }
    }
  }, [isConfirmed]);

  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_features_list,
        show_toast: false,
      })
    );
  }, []);

  const handleClose = () => {
    setAddNewRoleDialog(false);
    setDrawOpenProp(false);
  };

  React.useEffect(() => {
    setAddNewRoleDialog(drawOpenProp);
  }, [drawOpenProp]);

  return (
    <>
      <Dialog open={addNewRoleDialog} onClose={handleClose}>
        <Box sx={styles.mainDiv}>
          <CustomTypography
            content="Roles Management"
            fontSize="18px"
            fontWeight="bold"
          />
          <CustomInputTextField
            placeholder="Role Name"
            size="large"
            value={roleName}
            setValue={setRoleName}
            handleChange={handleChangeRoleName}
          />
          <CustomInputTextField
            placeholder="Role Description"
            size="large"
            value={roleDescription}
            setValue={setRoleDescription}
            handleChange={handleChangeRoleDescription}
          />
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <CustomButton
              label="Cancel"
              size="medium"
              variant="text"
              color="error"
              handleClick={handleClose}
            />
            <CustomButton
              label="Add role"
              rounded={true}
              size="medium"
              variant="contained"
              handleClick={handleAddRoleClick}
            />
          </Box>
        </Box>
      </Dialog>

      <>
        {rolesData?.length > 0 ? (
          <>
            {/* <
            RolesListTable
              listOfRoles={listOfRoles}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              setPageSize={setPageSize}
              pageSize={pageSize}
              // setAddNewRoleDialog={setAddNewRoleDialog}
              lastPageNumber={lastPageNumber}
              isActive={isActive}
              setIsActive={setIsActive}
              ascOrDesc={ascOrDesc}
              setAscOrDesc={setAscOrDesc}
              setIsUpdate={setIsUpdate}
              isUpdate={isUpdate}
              setOpenConfirmationDialog={setOpenConfirmationDialog}
              isConfirmed={isConfirmed}
              setConfirmationDescription={setConfirmationDescription}
              setIsConfirmed={setIsConfirmed}
            /> */}
            <RoleManager
              listOfRoles={rolesData}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              pageSize={pageSize}
              setPageSize={setPageSize}
              isActive={isActive}
              setIsActive={setIsActive}
              ascOrDesc={ascOrDesc}
              setAscOrDesc={setAscOrDesc}
              setSortColumn={setSortColumn}
              setFilterColumn={setFilterColumn}
              setFilterSearchText={setFilterSearchText}
              handleGetRolesAndAccessList={handleGetRolesAndAccessList}
            />
          </>
        ) : (
          ""
        )}
      </>
      {openConfirmationDialog && (
        <CustomConfirmationDialog
          openConfirmationDialog={openConfirmationDialog}
          setOpenConfirmationDialog={setOpenConfirmationDialog}
          description={confirmationDescription}
          setIsConfirmed={setIsConfirmed}
        />
      )}
    </>
  );
}

export default RolesManagement;
