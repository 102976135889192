import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import appStyle from "../../AppMeta/appStyle";
import { Box } from "@mui/material";

function CustomFilter({
  name,
  startIcon,
  selectOption = [],
  handleFilterClose,
  type,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectValue, setSelectValue] = React.useState(selectOption[0]?.value);
  const [filterSearchValue, setFilterSearchValue] = React.useState("");
  const [sortOrder, setSortOrder] = React.useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    let filterData = {};

    filterData = {
      value: selectValue,
      searchText: type === "filter" ? filterSearchValue : sortOrder,
    };

    handleFilterClose !== undefined && handleFilterClose(filterData);
    setAnchorEl(null);
  };
  const handleSelectChange = (event) => {
    setSelectValue(event.target.value);
  };

  // const handleFilterSearchChange = (event) => {
  //   setFilterSearchValue(event.target.value);
  // };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  return (
    <>
      <Button
        id="basic-button"
        onClick={handleClick}
        variant="contained"
        startIcon={startIcon}
        sx={appStyle.general.button.blueButton}
      >
        {name}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        onKeyDown={(e) => {
          // Prevent the default behavior of focusing next/prev menu items on specific keys
          if (
            e.key === "s" ||
            e.key === "S" ||
            e.key === "F" ||
            e.key === "f"
          ) {
            setFilterSearchValue((prev) => prev + e.key);
            e.stopPropagation(); // Prevents focus from shifting
          }
        }}
      >
        <MenuItem>
          <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
            <InputLabel id="demo-customized-select-label">
              Select Option
            </InputLabel>
            <Select value={selectValue} onChange={handleSelectChange}>
              {selectOption.map((e, i) => (
                <MenuItem value={e.value} key={i}>
                  {e.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </MenuItem>

        {/* Code for filter */}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {type === "filter" && (
            <TextField
              id="filterSearch"
              label={selectOption[selectValue]?.option}
              variant="standard"
              value={filterSearchValue}
              onChange={(e) => {
                console.log(e.target.value); // Log value for debugging
                setFilterSearchValue(e.target.value);
              }}
              sx={{ width: "80px" }}
            />
          )}
        </Box>

        {/* code for sort */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {type === "sort" && (
            <ToggleButtonGroup
              value={selectOption.value}
              exclusive
              onChange={handleSortChange}
              aria-label="text alignment"
            >
              <ToggleButton value="ASC" aria-label="left aligned">
                ASC
              </ToggleButton>
              <ToggleButton value="DESC" aria-label="left aligned">
                DESC
              </ToggleButton>
            </ToggleButtonGroup>
          )}
        </Box>
        <MenuItem>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={appStyle.general.button.blueButton}
          >
            {type === "filter" ? "Filter" : type === "sort" ? "sort" : "Filter"}
          </Button>
        </MenuItem>
      </Menu>
    </>
  );
}

export default CustomFilter;
