import React from "react";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import appStyle from "../../AppMeta/appStyle";

function AddButton({ handleOnClickButton }) {
  return (
    <Box>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleOnClickButton}
        sx={appStyle.general.button.blueButton}
      >
        Add
      </Button>
    </Box>
  );
}

export default AddButton;
