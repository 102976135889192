import React, { createContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Box, useTheme, TablePagination } from "@mui/material";
import { useSelector } from "react-redux";
import { appColors } from "../../AppMeta/appColors";
import CustomDialogMoreDetailsWaterFilling from "../../Components/CustomComp/CustomDialogMoreDetailsWaterFilling";
import { EditDialogBatch } from "./EditDialogBatch";
import {
  GetCreateBatch,
  StartBatch,
} from "../../Redux/Actions/adddCreateBatchAction";
import { useDispatch } from "react-redux";
import { CancelDialogBatch } from "./CancelDialogBatch";
import CustomConfirmationDialog from "../../Components/CustomComp/CustomConfirmationDialog";
import CustomScannerDialog from "../../Components/CustomComp/CustomScannerDialog";
import { ScannerDialog } from "./ScannerDialog";
import { tokens } from "../../AppMeta/theme";
import BadgeIcon from "@mui/icons-material/Badge";
import appStyle from "../../AppMeta/appStyle";
import appFonts from "../../AppMeta/appFonts";
import CustomFilter from "../../Components/CustomComp/CustomFilter";

export const UserContext = createContext();

export default function BatchDetailsTable({ batchList }) {
  const data = [
    {
      facility_batch_master_id: 1,
      batch_id: "WFSB00002",
      quantity: 2,
      facility_id: 1,
      is_batch_started: true,
      is_batch_stopped: true,
      batch_start_time: "2024-03-05T05:01:47.808Z",
      batch_stop_time: "2024-03-05T05:01:47.808Z",
      updated_by: "Lkgx_lbEM",
      updated_at: "2024-03-05T05:01:47.808Z",
      created_at: "2024-03-05T05:01:47.808Z",
      container_capacity: "20 Liter",
      container_material: "PET NEW",
      line_id: "PDW1WFS4",
      linked_container_qty: 0,
      is_settled: false,
      reason: "",
      created_by: "Lkgx_lbEM",
      updated_user_details: {
        fullname: "tests updates",
        phone: "9003806524",
        email: "test@bookwater.com",
        is_active: true,
        is_deleted: false,
      },
      created_user_details: {
        fullname: "tests updates",
        phone: "9003806524",
        email: "test@bookwater.com",
        is_active: true,
        is_deleted: false,
      },
    },
    {
      facility_batch_master_id: 10,
      batch_id: "WFSB00003",
      quantity: 5,
      facility_id: 1,
      is_batch_started: true,
      is_batch_stopped: true,
      batch_start_time: "2024-03-05T07:28:24.000Z",
      batch_stop_time: "2024-03-05T07:28:24.000Z",
      updated_by: "Lkgx_lbEM",
      updated_at: "2024-03-05T14:05:59.000Z",
      created_at: "2024-03-05T07:28:24.000Z",
      container_capacity: "20 Liter",
      container_material: "PET NEW",
      line_id: "PDW1WFS4",
      linked_container_qty: 0,
      is_settled: false,
      reason: "Placed by mistake",
      created_by: "Lkgx_lbEM",
      updated_user_details: {
        fullname: "tests updates",
        phone: "9003806524",
        email: "test@bookwater.com",
        is_active: true,
        is_deleted: false,
      },
      created_user_details: {
        fullname: "tests updates",
        phone: "9003806524",
        email: "test@bookwater.com",
        is_active: true,
        is_deleted: false,
      },
    },
  ];

  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const wfsDetails = useSelector((state) => state.wfsDetails);
  const batchDetails = useSelector((state) => state.batchData);
  console.log(wfsDetails);
  console.log(batchList);

  //1.useState
  const [openCustomDialog, setOpenCustomDialog] = React.useState(false);
  const [moreDetails, setMoreDetails] = React.useState("");
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState(null);
  const [cancelDialogOpen, setCancelDialogOpen] = React.useState(false);
  const [batchData, setBatchData] = React.useState([]);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    React.useState(false);
  const [openLinkDialog, setOpenLinkDialog] = React.useState(false);
  const [currentScanned, setCurrentScanned] = React.useState(
    JSON.parse(localStorage.getItem("current_scanned_codes"))
  );
  const [validData, setValidData] = React.useState([]);
  const [invalidData, setInvalidData] = React.useState([]);
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const [selectedBatchId, setSelectedBatchId] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0);
  //2.useEffect Hook
  React.useEffect(() => {
    if (currentScanned?.length) {
      localStorage.setItem(
        "current_scanned_codes",
        JSON.stringify(currentScanned)
      );
    }
    // const current_scanned_stored = JSON.parse(localStorage.getItem("current_scanned_codes")) || []
  }, [currentScanned]);
  React.useEffect(() => {
    // dispatch(GetCreateBatch()).then(() => {
    //   console.log("Batch Data");
    //   console.log(batchDetails);
    // });
    dispatch(GetCreateBatch());
  }, []);

  React.useEffect(() => {
    console.log("batchDetails");
    console.log(batchDetails);
    setBatchData(batchDetails?.batchData);
  }, [batchDetails]);
  React.useEffect(() => {
    if (isConfirmed && selectedBatchId !== "") {
      const reqObj = {
        batch_id: selectedBatchId,
      };
      console.log("ReqObj");
      console.log(reqObj);
      dispatch(StartBatch(reqObj));
      setOpenConfirmationDialog(false);
    }
  }, [isConfirmed, selectedBatchId]);

  //Pagination for table
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => {
    setEditDialogOpen(false);
    setCancelDialogOpen(false);
    //setCurrentItem(null); // Clear the current item when dialog is closed
  };
  const handleEditClick = (details) => {
    console.log("Details");
    console.log(details);
    setEditDialogOpen(true);
    setCurrentItem(details);
  };

  const handleCancelClick = (details) => {
    console.log("Cancel");
    console.log(cancelDialogOpen);
    setCancelDialogOpen(true);
    setCurrentItem(details);
  };

  const handleShowMore = (details) => {
    console.log("details");
    console.log(details);
    setOpenCustomDialog(true);
    setMoreDetails(details);
  };
  const handleStartBatch = (batch) => {
    console.log("batch");
    console.log(batch);
    setSelectedBatchId(batch.batch_id);
    setOpenConfirmationDialog(true);
  };

  const handleStopBatch = () => {
    setOpenConfirmationDialog(true);
  };
  const handleLinking = () => {
    setOpenLinkDialog(true);
  };
  return (
    <Box sx={appStyle.general.table.containerForTable}>
      <CustomFilter />
      <TableContainer sx={appStyle.general.table.tableContainer}>
        <Table stickyHeader>
          <TableHead
            sx={{
              backgroundColor:
                appStyle.general.colors(colors).table.tableHeadBackground,
              color: colors.primary[300],
              ...appStyle.general.table.tableHead,
            }}
          >
            <TableRow>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Batch ID
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Batch Quantity
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Is Batch Started
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Is Batch Stopped
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Container Capacity
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Container Material
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Link
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Batch Status
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                More Details
              </TableCell>
              <TableCell sx={appStyle.general.table.headderRowCell}>
                Edit
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: colors.primary[600] }}>
            {(rowsPerPage > 0
              ? batchData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : batchData
            ).map((batch) => (
              <TableRow key={batch.facility_batch_master_id}>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  {batch.batch_id}
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  {batch.quantity}
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  {batch.is_batch_started ? "Yes" : "No"}
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  {batch.is_batch_stopped ? "Yes" : "No"}
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  {batch.container_capacity}
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  {batch.container_material}
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  <Box>
                    <Button
                      size={appFonts.tableButtonSize}
                      color={appFonts.tableSecondaryButtonColor}
                      variant={appFonts.tableSecondaryButtonVarient}
                      value={openLinkDialog}
                      onClick={handleLinking}
                      sx={appStyle.general.button.blueButton}
                    >
                      LINK
                    </Button>
                  </Box>
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  <Box sx={appStyle.general.button.editAndCancelButton.box}>
                    {batch.is_batch_started === false && (
                      <Button
                        color={appFonts.tablePrimaryButtonColor}
                        variant={appFonts.tablePrimaryButtonVarient}
                        size={appFonts.tableButtonSize}
                        onClick={() => handleStartBatch(batch)}
                        sx={appStyle.general.button.blueButton}
                      >
                        START
                      </Button>
                    )}

                    {batch.is_batch_stopped === false && (
                      <Button
                        color="error"
                        variant={appFonts.tableSecondaryButtonVarient}
                        size={appFonts.tableButtonSize}
                        onClick={() => handleStopBatch(batch)}
                        sx={appStyle.general.button.redButton}
                      >
                        STOP
                      </Button>
                    )}
                  </Box>
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  <Button
                    color={appFonts.tableSecondaryButtonColor}
                    variant={appFonts.tableSecondaryButtonVarient}
                    size={appFonts.tableButtonSize}
                    onClick={() => {
                      handleShowMore(batch);
                    }}
                    sx={appStyle.general.button.editButton}
                  >
                    More...
                  </Button>
                </TableCell>
                <TableCell sx={appStyle.general.table.bodyRowCell}>
                  <Box sx={appStyle.general.button.editAndCancelButton.box}>
                    <Button
                      color={appFonts.tablePrimaryButtonColor}
                      variant={appFonts.tablePrimaryButtonVarient}
                      size={appFonts.tableButtonSize}
                      onClick={() => handleEditClick(batch)}
                      sx={appStyle.general.button.editButton}
                    >
                      Edit
                    </Button>
                    <Button
                      color="error"
                      variant={appFonts.tableSecondaryButtonVarient}
                      size={appFonts.tableButtonSize}
                      onClick={handleCancelClick}
                      sx={appStyle.general.button.redButton}
                    >
                      Cancel
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={appStyle.general.table.pagination}>
        <TablePagination
          rowsPerPageOptions={[25, 100, 500, 1000]}
          component="div"
          count={batchData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      {openCustomDialog && (
        <CustomDialogMoreDetailsWaterFilling
          openCustomDialog={openCustomDialog}
          setOpenCustomDialog={setOpenCustomDialog}
          title="More details"
          moreDetails={moreDetails}
        />
      )}
      {editDialogOpen && (
        <EditDialogBatch
          open={editDialogOpen}
          setEditDialogOpen={setEditDialogOpen}
          onClose={handleClose}
          item={currentItem}
        />
      )}
      {cancelDialogOpen && (
        <CancelDialogBatch
          open={cancelDialogOpen}
          onClose={handleClose}
          setCancelDialogOpen={setCancelDialogOpen}
          item={currentItem}
        />
      )}
      {openConfirmationDialog && (
        <CustomConfirmationDialog
          openConfirmationDialog={openConfirmationDialog}
          setOpenConfirmationDialog={setOpenConfirmationDialog}
          description={"Are you sure want to START batch?"}
          setIsConfirmed={setIsConfirmed}
        />
      )}

      {openConfirmationDialog && (
        <CustomConfirmationDialog
          openConfirmationDialog={openConfirmationDialog}
          setOpenConfirmationDialog={setOpenConfirmationDialog}
          description={"Are you sure want to STOP batch?"}
          setIsConfirmed={setIsConfirmed}
        />
      )}
      {openLinkDialog && (
        <ScannerDialog
          open={openLinkDialog}
          setOpenLinkDialog={setOpenLinkDialog}
          currentScanned={currentScanned}
          setCurrentScanned={setCurrentScanned}
          validData={validData}
          invalidData={invalidData}
          setInvalidData={setInvalidData}
          setValidData={setValidData}
        />
      )}
    </Box>
  );
}
