import React from "react";
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  IconButton,
} from "@mui/material";
import WalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { CreateOrderOrg } from "../../../Redux/Actions/skuBookingAction";

const PaymentCard = () => {
  const dispatch = useDispatch();
  const SkuSelectedProducts = useSelector((state) => state.skuBookingDetails);

  const priceBreakUp = useSelector(
    (state) => state.checkOutDetails.checkOutDetails
  );

  React.useEffect(() => {
    // console.log("SkuSelectedProducts in payment card");
    // console.log(SkuSelectedProducts.selectedProduct);
  }, [SkuSelectedProducts.selectedProduct]);

  const handlePlaceOrder = () => {
    const reqObj = SkuSelectedProducts.selectedProduct;
    dispatch(CreateOrderOrg(reqObj));
  };
  return (
    <Card sx={{ maxWidth: 345, margin: "auto", mt: 5, borderRadius: 3 }}>
      <CardContent>
        <Typography
          variant="h6"
          component="div"
          sx={{
            textAlign: "center",
            color: "white",
            bgcolor: "primary.main",
            p: 1,
            borderRadius: 1,
          }}
        >
          🙌 You saved ₹11 for this Order 🙌 🙌 Free Delivery Unlocked 🙌
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <WalletIcon sx={{ color: "primary.main", fontSize: 40 }} />
            <Typography variant="h6" component="div" sx={{ ml: 2 }}>
              WATER WALLET
            </Typography>
          </Box>
          <IconButton>
            <ExpandMoreIcon />
          </IconButton>
        </Box>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 3 }}
          onClick={handlePlaceOrder}
        >
          ₹ Pay{" "}
          {priceBreakUp?.final_price === undefined
            ? 0
            : priceBreakUp?.final_price}
        </Button>
      </CardContent>
    </Card>
  );
};

export default PaymentCard;
