/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description Authentication component to login and signUp
 * @date 26/05/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries

import React, { useState } from "react";
import "./authentication.css";
// import { FaFacebook } from 'react-icons/fa';
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logIn } from "../../Redux/Actions/authenticationAction";
import { Box, Button } from "@mui/material";
import ForgotPasswordDialog from "./ForgotPasswordDialog";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.bookwater.com/">
        Bookwater
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const AuthenticationPage = () => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const [redirectToDashboard, setRedirectToDashboard] = useState(false);
  const [isLogin, setIsLogin] = React.useState(false);
  const [loginBtn, setLoginBtn] = React.useState(false);
  const [forgotPassword, setForgotPassword] = React.useState(false);
  const [verificationCodeSent, setVerificationCodeSent] = React.useState(false);

  const dispatch = useDispatch();
  const authenticationDetails = useSelector(
    (state) => state.authenticationData
  );

  //   const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    // <Redirect to="/dashboard" />;
    // Perform sign-up or login logic based on isSignUp state
    if (isSignUp) {
      // Perform sign-up
      console.log("Sign-up:", email, password);
      setRedirectToDashboard(true);
    } else {
      // Perform login
      console.log("Login:", email, password);
      setLoginBtn(true);
      const reqObj = {
        email_phone: email,
        password: password,
      };
      dispatch(logIn(reqObj));
    }
    // Reset form fields
    // setEmail("");
    // setPassword("");
    setRedirectToDashboard(true);
  };

  React.useEffect(() => {
    console.log(authenticationDetails);
    if (authenticationDetails.verifyError === false) {
      setVerificationCodeSent(true);
    } else {
      setVerificationCodeSent(false);
    }
    if (authenticationDetails.changePasswordError === false) {
      setForgotPassword(false);
    }
    if (
      authenticationDetails.error === false &&
      loginBtn === true &&
      forgotPassword === false
    ) {
      setIsLogin(true);
      setLoginBtn(false);
      localStorage.setItem(
        "roleId",
        JSON.stringify(authenticationDetails.role_id)
      );
      // authenticationDetails.error === true;
    }
  }, [authenticationDetails]);

  if (isLogin) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <div className="container">
      <div className="form-container">
        <div className="logo-container">
          {/* <img src={BwIcon} alt="BookWater Logo" className="logo" /> */}
          <h1 className="logo-text">BookWater</h1>
        </div>
        <form className="form" onSubmit={handleSubmit}>
          <TextField
            className="input"
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {/* <input
            className="input"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          /> */}
          <div className="password-input">
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
          </div>

          {/* <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl> */}
          <button className="button" type="submit">
            {isSignUp ? "Sign Up" : "Log In"}
          </button>
        </form>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="switch-container">
            {isSignUp ? "Already have an account?" : "Don't have an account?"}

            <button
              className="switch-button"
              onClick={() => setIsSignUp(!isSignUp)}
            >
              {isSignUp ? "Log In" : "Sign Up"}
            </button>
          </div>
          <p>Or</p>
          <Box>
            <button
              className="switch-button"
              onClick={() => setForgotPassword(true)}
            >
              Forgot Password?
            </button>
          </Box>
          {forgotPassword && (
            <ForgotPasswordDialog
              forgotPassword={forgotPassword}
              setForgotPassword={setForgotPassword}
              verificationCodeSent={verificationCodeSent}
              setVerificationCodeSent={setVerificationCodeSent}
            />
          )}
        </Box>

        <Copyright sx={{ mt: 8, mb: 4 }} />
      </div>
    </div>
  );
};

export default AuthenticationPage;
